import React, { useState } from "react";
import siteLogo from "../../../assets/images/logo2.png";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { AdminSignin } from "../../../services/adminService/AdminLoginService";
import { useSelector } from "react-redux";

const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
};
const AdminLogin = () => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const navigate = useNavigate();
  const settings = useSelector((state) => state?.settings?.data);

  const [loaded, setLoaded] = useState(false);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      await AdminSignin({
        email: values?.email,
        password: values?.password,
      }).then((res) => {
        setLoaded(false);
        if (res?.data?.status === 200) {
          localStorage.setItem("auth_token", res?.data?.accessToken);
          localStorage.setItem("refreshToken", res?.data?.refreshToken);
          localStorage.setItem("role", "ADMIN");
          setTimeout(function () {
            navigate("/admin/dashboard");
          }, 1000);
          toast.info(res.data.message);
        } else {
          toast.info(res.data.message);
        }
      });
    } catch (error) {
      setLoaded(false);
      toast.error(error);
    }
  };
  const handleClick = () => {
    navigate("/forgotPassword", {
      state: "admin",
    });
  };

  return (
    <div className="w-full min-h-screen bg-slate-50 flex items-center justify-center py-3 px-5">
      <div className="w-full max-w-md xl:max-w-md bg-white py-10 shadow-md rounded-2xl border border-orange-700 ">
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src={settings?.favicon?.url || siteLogo}
              alt="Your Company"
            />
            <h2 className="mt-1 uppercase text-center text-xl font-bold leading-9 tracking-tight text-orange-700">
              Administrator Login
            </h2>
            <h2 className="mt-1 text-center text-base font-semibold leading-9 tracking-tight text-black">
              Please Use Your Credentials to Login
            </h2>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-2">
                <Input
                  label={"Email"}
                  inputPlaceholder={"Enter your email"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  value={values.email}
                  inputName={"email"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>

              <div>
                <div className="mt-2">
                  <Input
                    label={"Password"}
                    inputPlaceholder={"Enter your password"}
                    labelClasses={"!text-xs"}
                    inputType={"password"}
                    value={values.password}
                    inputName={"password"}
                    onChange={handleChange}
                    {...formValidate(errors, "password")}
                  />
                </div>
              </div>

              <div className="mt-2">
                <Button
                  loading={loaded}
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonLabel={"Log in"}
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-light fa-right-to-bracket"}
                  buttonClasses={
                    "w-full !text-white !bg-orange-500 !hover:bg-white !hover:text-orange-500 focus-visible:outline justify-center rounded-md border border-orange-500"
                  }
                />
              </div>
              <div className="flex justify-end">
                <Button
                  buttonClasses={
                    "!justify-right bg-transparent !text-slate-500 border-none"
                  }
                  buttonHasLink={false}
                  buttonType={"button"}
                  buttonLabel={"Forgot Password?"}
                  buttonFunction={handleClick}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
