import { getDepartmentList } from "../../../../services/frontService/DepartmentService";
import EmpImage1 from "../../../../assets/images/member-03.webp";
import DeparmentLoader from "../../../loaders/DeparmentLoader";
import { useCallback, useEffect, useState } from "react";
import NoDataFound from "../../../common/NodataFound";
import Pagination from "../../../common/Pagination";
import Button from "../../../form/Button";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function DepartmentListItem({
  list,
  filters,
  setFilters = () => {},
  setList = () => {},
  setDepModal = () => {},
  setDeleteModal = () => {},
  
}) {
  const navigate = useNavigate();
  const departmentList = list?.data?.map((dep) => ({
    _id: dep?._id,
    empCount: dep?.departmentEmpCount,
    name: dep?.name,
    image: EmpImage1,
    departmentEmpList: dep?.departmentEmpList,
    borderColor: dep?.theme?.headerColor || "#71b483",
  }));

  return (
    <>
      <div className="w-full grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-2 py-2 px-1 gap-4">
        {list?.loading ? (
          <DeparmentLoader count={12} />
        ) : (
          <>
            {list?.data && Array.isArray(list?.data) && list.data.length === 0 ? (
              <div className="col-span-4">
                <NoDataFound title="No departments found" />
              </div>
            ) : (
              departmentList?.map((item) => (
                <div
                  key={item?._id}
                  className={`relative flex flex-col justify-between col-span-1 p-2 px-3 border rounded-lg shadow hover:shadow-md cursor-pointer border-t-4`}
                  style={{ borderTopColor: item?.borderColor }}
                  onClick={() =>
                    navigate("/employees", {
                      state: { departmentIds: [item?._id] },
                    })
                  }>
                  <div className="flex items-center justify-between">
                    <div className="text-xs md:text-base 2xl:text-lg text-gray-800 font-medium uppercase break-all">
                      {item?.name?.substring(0, 20) + (item?.name?.length > 20 ? "....." : "")}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-8">
                    <div
                      className="flex items-center justify-end gap-1"
                      data-tooltip-id={`position-employee-${item._id}`}>
                      <i className="fa-regular fa-user text-sm" />
                      <p className="font-medium text-slate-500">{item?.empCount}</p>
                      <Tooltip
                        id={`position-employee-${item._id}`}
                        place="right"
                        className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                        render={() => {
                          if (item?.departmentEmpList?.length === 0) {
                            return null;
                          }

                          const maxVisible = 5;
                          const employeesToShow = item?.departmentEmpList?.slice(0, maxVisible);
                          const hasMore = item?.departmentEmpList?.length > maxVisible;

                          return (
                            <div>
                              {employeesToShow?.map((emp) => (
                                <div key={emp._id} className="text-white">
                                  {emp?.firstName + " " + emp?.lastName}
                                </div>
                              ))}
                              {hasMore && (
                                <div className="text-blue-500 cursor-pointer flex justify-end">
                                  <span>{item?.empCount - maxVisible} more...</span>
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="flex space-x-2">
                      <div data-tooltip-id="delete-departments" onClick={(e) => e.stopPropagation()}>
                        <Button
                          buttonIcon={"fa-fw fa-regular fa-trash-can text-sm font-medium"}
                          buttonIconPosition={"left"}
                          buttonClasses={
                            "w-6 h-6 !border !bg-slate-50 !border-red-600 !text-red-600 hover:!bg-red-100 !rounded-full flex items-center justify-center"
                          }
                          buttonFunction={() => {
                            setDeleteModal((pre) => ({
                              ...pre,
                              isOpen: true,
                              ids: [item?._id],
                            }));
                          }}
                        />
                        <Tooltip
                          id="delete-departments"
                          place="bottom"
                          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                          render={() => <div className="text-white">Click to delete departments.</div>}
                        />
                      </div>
                      <div data-tooltip-id="edit-departments" onClick={(e) => e.stopPropagation()}>
                        <Button
                          buttonIcon={"fa-fw fa-regular fa-pen-to-square text-sm font-medium"}
                          buttonIconPosition={"left"}
                          buttonClasses={
                            "w-6 h-6 !border !bg-slate-50 !border-black !text-black hover:!bg-blue-100 !rounded-full flex items-center justify-center"
                          }
                          buttonFunction={() => {
                            setDepModal({ data: item, isOpen: true });
                          }}
                        />
                        <Tooltip
                          id="edit-departments"
                          place="bottom"
                          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                          render={() => <div className="text-white">Click to edit departments.</div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </div>
      {list?.pageCount > 1 && (
        <div className="realtive mt-auto">
          <Pagination
            paginationClasses={"mt-4 justify-center"}
            onPageChange={(val) => {
              setFilters((pre) => ({
                ...pre,
                offset: pre?.limit * val?.selected,
                currentPage: val?.selected,
              }));
            }}
            pageCount={list?.pageCount}
            currentPage={filters?.currentPage}
          />
        </div>
      )}
    </>
  );
}
