export const status_filter = [
  { value: 1, name: "Active", type: "checkbox" },
  { value: 2, name: "In Active", type: "checkbox" },
];
export const featured_filter = [
  { value: 1, name: "Featured", type: "checkbox" },
  { value: 0, name: "Non-featured", type: "checkbox" },
];
export const trending_filter = [
  { value: 1, name: "Trending", type: "checkbox" },
  { value: 0, name: "Non-trending", type: "checkbox" },
];
export const approved_filter = [
  { value: true, name: "Approved", type: "checkbox" },
  { value: false, name: "Non-approved", type: "checkbox" },
];
export const member_filter = [
  { value: true, name: "Member", type: "checkbox" },
  { value: false, name: "Not-member", type: "checkbox" },
];
export const email_filter = [
  { value: 1, name: "Verified", type: "checkbox" },
  { value: 2, name: "Not-verified", type: "checkbox" },
];
export const attr_filter = [
  { value: true, name: "Value", type: "checkbox" },
  { value: false, name: "Attribute", type: "checkbox" },
];
export const yes_no_filter = [
  { value: 1, name: "Yes", type: "checkbox" },
  { value: 0, name: "No", type: "checkbox" },
];
export const invitation_filter = [
  { value: 1, name: "Yes", type: "checkbox" },
  { value: 2, name: "No", type: "checkbox" },
];
export const role_filter = [
  { value: "COMPANY-ADMINISTRATOR", name: "Administrator", type: "checkbox" },
  // { value: "COMPANY-MANAGER", name: "Evaluator", type: "checkbox" },
  { value: "COMPANY-EMPLOYEE", name: "Employee", type: "checkbox" },
];
export const order_status_filter = [
  { value: "processing", name: "Processing", type: "checkbox" },
  { value: "shipped", name: "Shipped", type: "checkbox" },
  { value: "delivered", name: "Delivered", type: "checkbox" },
  { value: "cancelled", name: "Cancelled", type: "checkbox" },
];
export const payment_status_filter = [
  { value: "pending", name: "Pending", type: "checkbox" },
  { value: "success", name: "Success", type: "checkbox" },
  { value: "failed", name: "Failed", type: "checkbox" },
];
export const payment_method_filter = [
  { value: "online", name: "Online", type: "checkbox" },
  { value: "cash", name: "Cash", type: "checkbox" },
];

export const eventTypes = {
  birthDay: {
    icon: "fa-regular fa-cake-candles",
    color: "text-red-500 bg-red-50",
    hover: "hover:bg-red-100",
    border: "border-red-300",
    selected: "!bg-red-500 !text-white",
  },
  marriage: {
    icon: "fa-regular fa-ring-diamond",
    color: "text-blue-500 bg-blue-50",
    hover: "hover:bg-blue-100",
    border: "border-blue-300",
    selected: "!bg-blue-500 !text-white",
  },
  holiday: {
    icon: "fa-regular fa-umbrella-beach",
    color: "text-green-500 bg-green-50",
    hover: "hover:bg-green-100",
    border: "border-green-300",
    selected: "!bg-green-500 !text-white",
  },
  leave: {
    icon: "fa-regular fa-house-person-leave",
    color: "text-yellow-500 bg-yellow-50",
    hover: "hover:bg-yellow-100",
    border: "border-yellow-300",
    selected: "!bg-yellow-500 !text-white",
  },
  announcement: {
    icon: "fa-regular fa-bullhorn",
    color: "text-purple-500 bg-purple-50",
    hover: "hover:bg-purple-100",
    border: "border-purple-300",
    selected: "!bg-purple-500 !text-white",
  },
};

export const nestedDep = [
  {
    _id: "6721e849f8d87791ef96217b",
    name: "Marketing department",
    code: "marketing-department",
    parentId: null,
    color: "#FF5733",
    children: [
      {
        _id: "674580f05df35d559e1a34c6",
        name: "asdfvgbn",
        code: "asdfvgbn",
        parentId: "6721e849f8d87791ef96217b",
        color: "#FF7043",
        children: [
          {
            _id: "child-level3-1",
            name: "Sub-Department 1",
            code: "sub-department-1",
            parentId: "674580f05df35d559e1a34c6",
            color: "#FF8A65",
            children: [
              {
                _id: "child-level4-1",
                name: "Team 1",
                code: "team-1",
                parentId: "child-level3-1",
                color: "#FFB74D",
                children: [],
              },
            ],
          },
          {
            _id: "child-level3-2",
            name: "Sub-Department 2",
            code: "sub-department-2",
            parentId: "674580f05df35d559e1a34c6",
            color: "#FFCC80",
            children: [],
          },
        ],
      },
      {
        _id: "6721e82df8d87791ef96216b",
        name: "Design Departmentsss",
        code: "design-department",
        parentId: "6721e849f8d87791ef96217b",
        color: "#3F51B5",
        children: [
          {
            _id: "child-level3-3",
            name: "Graphic Design",
            code: "graphic-design",
            parentId: "6721e82df8d87791ef96216b",
            color: "#7986CB",
            children: [
              {
                _id: "child-level4-2",
                name: "UI/UX Team",
                code: "ui-ux-team",
                parentId: "child-level3-3",
                color: "#9FA8DA",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: "6721e854f8d87791ef962183",
    name: "Analysis Departments",
    code: "analysis-department",
    parentId: null,
    color: "#4CAF50",
    children: [
      {
        _id: "672b41d0147584d589b175c5",
        name: "Procurement",
        code: "procurement",
        parentId: "6721e854f8d87791ef962183",
        color: "#66BB6A",
        children: [
          {
            _id: "child-level3-4",
            name: "Vendor Management",
            code: "vendor-management",
            parentId: "672b41d0147584d589b175c5",
            color: "#81C784",
            children: [],
          },
        ],
      },
      {
        _id: "672b4180147584d589b17562",
        name: "Finance and Accounting",
        code: "finance-and-accounting",
        parentId: "6721e854f8d87791ef962183",
        color: "#388E3C",
        children: [
          {
            _id: "child-level3-5",
            name: "Auditing",
            code: "auditing",
            parentId: "672b4180147584d589b17562",
            color: "#43A047",
            children: [
              {
                _id: "child-level4-3",
                name: "Internal Audit Team",
                code: "internal-audit-team",
                parentId: "child-level3-5",
                color: "#66BB6A",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    _id: "6745753ac4cf1092fec4df68",
    name: "Research and Development",
    code: "research-and-development",
    parentId: null,
    color: "#00BCD4",
    children: [
      {
        _id: "672b41e8147584d589b175d7",
        name: "Training and Development",
        code: "training-and-development",
        parentId: "6745753ac4cf1092fec4df68",
        color: "#26C6DA",
        children: [
          {
            _id: "child-level3-6",
            name: "Employee Training",
            code: "employee-training",
            parentId: "672b41e8147584d589b175d7",
            color: "#4DD0E1",
            children: [
              {
                _id: "child-level4-4",
                name: "Technical Training",
                code: "technical-training",
                parentId: "child-level3-6",
                color: "#80DEEA",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
