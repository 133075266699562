import React, { useState } from "react";
import Input from "../../components/form/Input";
import { useNavigate } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { formValidate } from "../../helpers/formValidate";
import Button from "../../components/form/Button";
import { toast } from "react-toastify";
import OtpInput from "../../components/form/OtpInput";
import { forgotPassword } from "../../services/ProfileService";
import { Validation } from "../../helpers/Validation";

const ChangePassword = ({
  location,
  email,
  showResendButton,
  timer,
  otpSubmit = () => {},
}) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [password, setPassword] = useState("");
  const validation = {
    newPassword: { required: true, message: "Please Input Your New Password!" },
    confirmPassword: {
      required: password ? true : false,
      message: "Please Input Your Confirm Password!",
    },
  };
  const { values, handleChange, handleSubmit, errors } = useForm(
    {},
    validation
  );
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("At least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("At least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("At least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("At least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("At least one special character (e.g., @, #, $, etc.).");
    }
    if (errors.length > 0) {
      errors.unshift("Password requirements:");
    }

    return errors;
  };
  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      if (values.newPassword) {
        const newPasswordErrors = validatePassword(values.newPassword);
        if (newPasswordErrors.length > 0) {
          toast.error(newPasswordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }

      if (
        values.newPassword &&
        !Validation(
          "confirmPassword",
          values.confirmPassword,
          values.newPassword
        )
      ) {
        toast.error("Confirm password and password don't match");
        setLoaded(false);
        return;
      }
      setLoaded(true);
      await forgotPassword({
        email: email,
        otp: values?.otp,
        password: values?.newPassword,
        confirmPassword: values?.confirmPassword,
      }).then((res) => {
        if (res?.status === 201) {
          toast.success(res?.data?.message);
          navigate(location?.state === "admin" ? `/admin/signin` : `/signin`);
          setLoaded(false);
        } else {
          toast.error(res?.data?.message);
          setLoaded(false);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div>
          <div>
            <div className="flex items-center space-x-2">
              <Input
                label={"Enter OTP"}
                labelClasses={"!text-xs"}
                inputType={"number"}
                inputPlaceholder={"Enter OTP"}
                value={values.otp}
                inputName={"otp"}
                onChange={(e) => handleChange(e)}
                {...formValidate(errors, "otp")}
                className="flex-1"
              />

              {!showResendButton && (
                <Button
                  buttonClasses="w-auto !text-black !border-transparent !bg-white focus-visible:outline justify-center rounded-md mt-4"
                  buttonHasLink={false}
                  buttonType="button"
                  buttonLabel="Resend"
                  buttonFunction={otpSubmit}
                  buttonIconPosition="left"
                  buttonIcon={"fa-regular fa-arrows-rotate"}
                  isDisable={showResendButton}
                />
              )}
            </div>

            {showResendButton && timer > 0 && (
              <div className="mt-2 text-center text-red-700">
                Resend Code in {timer} seconds
              </div>
            )}
          </div>

          <div className="mt-2">
            <Input
              label={"New Password"}
              labelClasses={"!text-xs"}
              inputType={"password"}
              inputPlaceholder={"Enter your new password"}
              value={values.newPassword}
              inputName={"newPassword"}
              onChange={(e) => {
                handleChange(e);
                setPassword(e.target.value);
              }}
              {...formValidate(errors, "newPassword")}
            />
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between"></div>
          <div className="mt-2">
            <Input
              label={"Confirm Password"}
              labelClasses={"!text-xs"}
              inputType={"password"}
              inputPlaceholder={"Enter your confirm password"}
              value={values.confirmPassword}
              inputName={"confirmPassword"}
              onChange={(e) => {
                handleChange(e);
              }}
              {...formValidate(errors, "confirmPassword")}
            />
          </div>
        </div>

        <div className="mt-2">
          <Button
            buttonClasses={
              "w-full text-white  hover:bg-orange-500-two focus-visible:outline  justify-center rounded-md"
            }
            buttonHasLink={false}
            buttonType={"submit"}
            buttonLabel={"Change Password"}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
