import { useState, useCallback, useEffect } from "react";
import { getEmployeeList } from "../../../services/adminService/EmployeeService";
import Celebrations from "../../../components/sections/front/home/Celebrations";
import Avaiability from "../../../components/sections/front/home/Availability";
import SearchWithDropdown from "../../../components/form/SearchWithDropdown";
import LatestNews from "../../../components/sections/front/home/LatestNews";
import ForYou from "../../../components/sections/front/home/ForYou";
import Image from "../../../components/elements/Image";
import { useDebounce } from "../../../helpers/useDebounce";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getEventList } from "../../../services/frontService/EventService";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { setTitle } from "../../../helpers/MetaTag";

dayjs.extend(utc);

export default function Home() {
  setTitle("ORG Chart | Home");
  const profile = useSelector((state) => state?.profile?.data);
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const debKeyword = useDebounce(keyword, 300);

  const useDataFetcher = (serviceFunction, params = {}) => {
    const [data, setData] = useState({ loading: false, data: [] });

    const fetchData = useCallback(() => {
      setData({ loading: true, data: [] });

      serviceFunction(params)
        .then((res) => {
          setData({
            loading: false,
            data: res?.status === 200 ? res?.docs : [],
          });
        })
        .catch(() => {
          setData({ loading: false, data: [] });
        });
    }, [params, serviceFunction]);

    return [data, fetchData];
  };

  const [eventList, loadEventList] = useDataFetcher(getEventList, {
    limit: 6,
    startDate: dayjs().add(1, "day").utc().startOf("day").toISOString(),
  });
  const [birthDayList, loadBirthDayList] = useDataFetcher(getEventList, {
    type: "birthDay",
    sortQuery: "startDate",
  });
  const [announcementList, loadAnnounementList] = useDataFetcher(getEventList, {
    type: "announcement",
    limit: 1,
  });
  const [todayEventList, loadTodayEventList] = useDataFetcher(getEventList, {
    startDate: dayjs(new Date()).utc().startOf("day").toISOString(),
    endDate: dayjs(new Date()).utc().startOf("day").toISOString(),
  });
  const [tomorrowEventList, loadTomorrowEventList] = useDataFetcher(
    getEventList,
    {
      startDate: dayjs().add(1, "day").utc().startOf("day").toISOString(),
      endDate: dayjs().add(1, "day").utc().startOf("day").toISOString(),
    }
  );

  useEffect(() => {
    loadEventList();
    loadTodayEventList();
    loadBirthDayList();
    loadAnnounementList();
    loadTomorrowEventList();
  }, []);

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: debKeyword, limit: 10 })
      .then((res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      })
      .catch(() => {
        setEmpList({ loading: false, data: [] });
      });
  }, [debKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  const handleChange = (val) => {
    navigate(`/employees/${val?._id}`);
  };
  return (
    <div className="relative w-full flex flex-col bg-white">
      <div className="relative w-full h-48">
        <Image
          effect={"blur"}
          src={profile?.companyDetails?.bannerImage?.url}
          alt="Header Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col justify-center px-12 py-10 space-y-3">
          <h1 className="text-2xl md:text-4xl font-bold text-white">
            Welcome back, {profile?.firstName}
          </h1>
          <div className="mt-4">
            <SearchWithDropdown
              placeholder={`Search ${profile?.companyDetails?.companyName}`}
              loading={empList?.loading}
              keyword={keyword}
              setKeyword={setKeyword}
              onChange={handleChange}
              divClasses={"!rounded-xl !opacity-95"}
              dropdownClass={"!rounded-xl"}
              dropdownData={empList?.data?.map((item) => ({
                name: item?.firstName + " " + item?.lastName,
                subItem: item?.positionDetails?.title || "No position",
                image: item?.image?.url,
                _id: item?._id,
                type: "user",
              }))}
            />
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-2 xl:gap-2 2xl:gap-6 py-8 px-4 md:px-12 h-full">
        <ForYou profile={profile} eventList={eventList} />
        {/* <div className="col-span-12 md:col-span-8 lg:col-span-9 flex flex-col gap-3">
          <LatestNews announcementList={announcementList} />
          <Celebrations birthDayList={birthDayList} />
          <Avaiability
            todayEventList={todayEventList}
            tomorrowEventList={tomorrowEventList}
          />
        </div> */}
      </div>
    </div>
  );
}
