import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const dashboardSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    getCount: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getCount } = dashboardSlice?.actions;
export default dashboardSlice?.reducer;


