import React, { useCallback, useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import { verticalStepPathFunc } from "../../../components/constant/Dfs";
import { getChartShare } from "../../../services/frontService/ChartService";
import { useDisableZoom } from "../../../helpers";
import RenderRectSvgNode from "../../../components/constant/SvgNode";
import useWindowDimensions from "./../../../helpers/useWindowDimensions";
import { classNames } from "../../../helpers/classNames";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";

export default function ShareChart() {
  useDisableZoom(true);
  const { width, height } = useWindowDimensions();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyParam = params.get("key");

  const treeRef = useRef(null);
  const [tree, setTree] = useState({});
  const [isLoading, setLoading] = useState(false);

  const [translate, setTranslate] = useState({});
  const [zoom, setZoom] = useState(0.7);

  const [isSidepanelOpen, setSidePanelOpen] = useState({
    isOpen: false,
  });

  const loadSocialChart = useCallback(() => {
    setLoading(true);
    if (keyParam) {
      getChartShare(keyParam).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setTree(res?.docs?.length > 0 && res?.docs[0]);
        }
      });
    }
  }, []);

  useEffect(() => {
    setTranslate({ x: width / 2, y: 120 });
  }, [width, height]);

  useEffect(() => loadSocialChart(), [loadSocialChart]);

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 5));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };
  const handleReset = () => {
    setZoom(0.7);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="absolute top-0 left-0 right-0 z-20 p-2 flex space-x-2 justify-end">
        <div data-tooltip-id="zoom-in">
          <Button
            buttonFunction={handleZoomIn}
            buttonIcon={"fa-regular fa-plus"}
            buttonIconPosition={"left"}
            buttonClasses={
              "!bg-white !border-orange-500 border !text-orange-500 "
            }
          />
          <Tooltip
            id="zoom-in"
            place="bottom"
            className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
            render={() => (
              <div className="text-white">Click to zoom in the chart.</div>
            )}
          />
        </div>
        <div data-tooltip-id="reset">
          <Button
            buttonFunction={handleReset}
            buttonIcon={"fa-sharp fa-solid fa-rotate-left"}
            buttonIconPosition={"left"}
            buttonClasses={
              "!bg-white !border-orange-500 border !text-orange-500 "
            }
          />
          <Tooltip
            id="reset"
            place="bottom"
            className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
            render={() => (
              <div className="text-white">Click to reset the chart.</div>
            )}
          />
        </div>
        <div data-tooltip-id="zoom-out">
          <Button
            buttonFunction={handleZoomOut}
            buttonIcon={"fa-regular fa-minus"}
            buttonIconPosition={"left"}
            buttonClasses={
              "!bg-white !border-orange-500 border !text-orange-500 "
            }
          />
          <Tooltip
            id="zoom-out"
            place="bottom"
            className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
            render={() => (
              <div className="text-white">Click to zoom out the chart.</div>
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          "relative z-10 flex w-full transition-all duration-200 ease-in-out h-full bg-slate-100"
        )}
      >
        <div className="w-full  h-[calc(100vh-3.5rem)] overflow-auto scrollbar">
          <div className="flex flex-row  w-full h-full relative">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 z-10">
                <div className="container">
                  <div className="level-1 rectangle animate-pulse"></div>
                  <ol className="level-2-wrapper">
                    <li>
                      <div className="level-2 rectangle animate-pulse"></div>
                    </li>
                    <li>
                      <div className="level-2 rectangle animate-pulse"></div>
                    </li>
                  </ol>
                </div>
              </div>
            )}
            <div className="w-full h-full" ref={treeRef}>
              <Tree
                ref={treeRef}
                data={tree}
                zoomable={false}
                orientation="vertical"
                pathFunc={verticalStepPathFunc}
                zoom={zoom}
                scaleExtent={{ min: 0, max: 5 }}
                translate={translate}
                nodeSize={{ x: 300, y: 180 }}
                // enableLegacyTransitions={true}
                transitionDuration={1000}
                centeringTransitionDuration={1000}
                separation={{ siblings: 1, nonSiblings: 1.2 }}
                renderCustomNodeElement={(nodeInfo) => (
                  <RenderRectSvgNode
                    nodeInfo={nodeInfo}
                    isSidepanelOpen={isSidepanelOpen}
                    setSidePanelOpen={setSidePanelOpen}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
