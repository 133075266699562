import React from "react";
import Button from "../form/Button";
import Modal from "../elements/Modal";


const ConfirmModal = ({
  isOpen = false,
  onClose = () => {},
  onSaveHandler = () => {}, 
  title = "Confirm",
  modalBodyData = "",
  loading = false,
}) => {
   

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      title={title}
      size="md"
      titleIcon={"fa-sharp fa-regular fa-circle-exclamation"}
      modaltitleClasses={"!text-slate-200 text-[15px]"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <div className="text-center mb-2 text-slate-500">
        {modalBodyData}
      </div>
      <div className="">
      <div className="flex gap-2 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"No"}
          buttonFunction={() => onClose()}
          buttonClasses="!py-2 !pl-2 !pr-4 border border-orange-500 !text-orange-500 rounded-lg !bg-transparent !h-auto"
        />
        <Button
          buttonFunction={onSaveHandler}
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-check"}
          isDisable={loading}
          isLoaderDisabled={true}
          buttonLabel={"Yes"}
            buttonClasses={"!py-2 !pl-2 !pr-4 rounded-lg text-white !h-auto bg-red-500 hover:bg-red-600"}
        />
      </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
