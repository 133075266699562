import React, { useMemo } from "react";
import SidebarBlock from "./SidebarBlock";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSsoLogin } from "../../Context/SsoLoginContext";
import { clippingParents } from "@popperjs/core";
import Button from "../../components/form/Button";

const Sidebar = ({ isMenuOpen, setMenuOpen = () => {} }) => {
  const location = useLocation();
  const profile = useSelector((state) => state?.profile?.data);
  const { enabledConfirmationPopup, setEnabledConfirmationPopup, dropdownData, selectedWebsite, setSelectedWebsite } =
    useSsoLogin();

  const pageData1 = useMemo(() => {
    let value = [];
    const managerRole = profile?.role?.find((item) => item?.code === "COMPANY-MANAGER");
    const administratorRole = profile?.role?.find((item) => item?.code === "COMPANY-ADMINISTRATOR");
    if (managerRole?.code === "COMPANY-MANAGER") {
      value = [
        
        {
          _id: 1,
          name: "Org Chart",
          icon: "fa-light fa-sitemap",
          link: "/",
          isActive: false,
          type: "link",
        },
        // {
        //   _id: 2,
        //   name: "Company Settings",
        //   icon: "fa-light fa-buildings",
        //   link: "/employees",
        //   isActive: false,
        //   type: "link",
        // },
        // {
        //   _id: 5,
        //   name: "Calendar",
        //   icon: "fa-light fa-calendar-lines",
        //   link: "/calendar",
        //   isActive: false,
        //   type: "link",
        // },
        // {
        //   _id:6,
        //   name: "SSO Login",
        //   icon: "fa-light fa-folder-tree",
        //   link: "/",
        //   isActive: false,
        //   type: "link",
        //   children:
        //     dropdownData &&
        //     Array?.isArray(dropdownData) &&
        //     dropdownData?.length > 0 &&
        //     dropdownData?.map((it) => ({
        //       _id: it?._id,
        //       buttonData: it,
        //       type: "button",
        //       click: () => {
        //         console.log(it?.name);
        //         setEnabledConfirmationPopup(true);
        //       },
        //     })),
        // },
      ];
    } else if (administratorRole?.code === "COMPANY-ADMINISTRATOR") {
      value = [
        // {
        //   _id: 1,
        //   name: "Home",
        //   icon: "fa-light fa-house",
        //   link: "/home",
        //   isActive: false,
        //   type: "link",
        // },
        {
          _id: 2,
          name: "Org Chart",
          icon: "fa-light fa-sitemap",
          link: "/",
          isActive: false,
          type: "link",
        },
        // {
        //   _id: 3,
        //   name: "Employees",
        //   icon: "fa-light fa-user-tie",
        //   link: "/employees",
        //   isActive: false,
        //   type: "link",
        // },
        // {
        //   _id: 4,
        //   icon: "fa-light fa-flag",
        //   name: "Departments",
        //   link: "/departments",
        //   isActive: false,
        //   type: "link",
        // },
        // {
        //   _id: 5,
        //   name: "Positions",
        //   icon: "fa-light fa-users",
        //   link: "/positions",
        //   isActive: false,
        //   type: "link",
        // },

        {
          _id: 6,
          name: "Company Settings",
          icon: "fa-light fa-buildings",
          link: "/employees",
          isActive: false,
          type: "link",
        },

        // {
        //   _id: 4,
        //   name: "Calendar",
        //   icon: "fa-light fa-calendar-lines",
        //   link: "/calendar",
        //   isActive: false,
        //   type: "link",
        // },
        // {
        //   _id: 12,
        //   name: "SSO Login",
        //   icon: "fa-solid fa-arrow-right-to-arc",
        //   link: "/ssologin",
        //   isActive: false,
        //   type: "link",
        //   children:
        //     dropdownData &&
        //     Array?.isArray(dropdownData) &&
        //     dropdownData?.length > 0 &&
        //     dropdownData?.map((it) => ({
        //       _id: it?._id,
        //       buttonData: it,
        //       type: "button",
        //       click: () => {
        //         console.log(it?.name);
        //         setEnabledConfirmationPopup(true);
        //         setSelectedWebsite(it);
        //       },
        //     })),
        // },
      ];
    }
    return value;
  }, [profile?.role]);

  const pageData2 = [
    {
      _id: 1,
      name: "Onboarding",
      icon: "fa-light fa-gauge-high",
      link: "/w/1/projects",
    },
    {
      _id: 2,
      name: "Reports",
      icon: "fa-light fa-chart-line-up-down",
      link: "/w/1/clients",
    },
  ];

  // const optionPageData = [
  //   {
  //     _id: 1,
  //     name: "Settings",
  //     icon: "fa-light fa-cog",
  //     link: "/w/1/projects",
  //   },
  //   {
  //     _id: 2,
  //     name: "Help",
  //     icon: "fa-light fa-question-circle",
  //     link: "/w/1/clients",
  //   },
  // ];

  return (
    <>
      <aside className="w-full bg-slate-50 fixed left-0 top-0 xl:relative h-screen max-h-[calc(100vh-4rem)] py-0 px-4 overflow-auto scrollbar flex border-r">
        <div className="w-full divide-y divide-slate-200 flex flex-col">
          <SidebarBlock
            title=""
            data={pageData1}
            isMenuOpen={isMenuOpen}
            selected={(data) => {
              if (
                location.pathname === "/employees" ||
                location.pathname === "/departments" ||
                location.pathname === "/filledpositions" || 
                location?.pathname === "/vacantpositions"
              ) {
                return data?.name === "Company Settings";
              }
              return location.pathname === data?.link;
            }}
          />

          {/* <SidebarBlock
            title="Analysis"
            data={pageData2}
            selected={(data) => {
              return location.pathname === data?.link;
            }}
          /> */}
          {/* <div className="mt-auto">
            <SidebarBlock
              title="Options"
              data={optionPageData}
              selected={(data) => {
                return location.pathname === data?.link;
              }}
            />
          </div> */}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
