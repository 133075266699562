import { apiRequest } from "../../util/apiUtils";

export const getDepartmentList = async (params) => {
  try {
    const res = await apiRequest("get", `/department`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getDepartmentTree = async (params) => {
  try {
    const res = await apiRequest("get", `/department/tree`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const departMentAdd = async (payload) => {
  try {
    const res = await apiRequest("post", `/department/add`, {
      body: payload,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const departMentEdit = async (params) => {
  try {
    const res = await apiRequest("PATCH", `/department/edit/${params?.id}`, {
      body: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteDepartment = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `/department/delete`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const orderingFolder = async (params) => {
  try {
    const res = await apiRequest("post", "department/drag-to", {
      body: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getHexColor = async (params) => {
  try {
    const res = await apiRequest("get", `/color`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
