import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import { employeeAdd, employeeEdit } from "../../../services/frontService/EmployeeService";
import ImageUploader from "../../form/ImageUploader";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import Radio from "../../form/Radio";
import { useSelector } from "react-redux";
import { employeeModal } from "../../../redux/slice/employeeSlice";
import PositionManage from "./PositionManage";
import MultiselectDropdown from "../../form/MultiselectDropdown";
import { getDepartmentList } from "../../../services/frontService/DepartmentService";
import { useDebounce } from "../../../helpers/useDebounce";
import DepartmentMange from "./DepartmentMange";
import { getPositionList } from "../../../services/frontService/PositionService";
import PhoneNumberFormat from "../../form/PhoneNumberFormat";
import ConfirmModal from "../../common/ConfirmModal";

export default function EmployeeManage({
  setList,
  loadDeplist = () => {},
  loadPoslist = () => {},
  setEmpAddtype = () => {},
  loadEmpList = () => {},
  loadCount = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("COMPANY-EMPLOYEE");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [depKeyword, setDepKeyword] = useState("");
  const [posKeyWord, setPosKeyWord] = useState("");
  const empModal = useSelector((state) => state.empModal.data);

  const data = empModal?.data && empModal?.data;

  const validation = {
    firstName: { required: true, message: "Please enter  first name!" },
    lastName: { required: true, message: "Please enter  last name!" },
    email: { required: true, message: "Please enter  email !" },
    positionId: {
      required: userType !== "COMPANY-ADMINISTRATOR" && !selectedPosition?._id ? true : false,
      message: "Please choose a job title !",
    },
    departmentId: {
      required: userType !== "COMPANY-ADMINISTRATOR" && selectedDepartments?.length <= 0 ? true : false,
      message: "Please choose a department !",
    },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  const deptKeyword = useDebounce(depKeyword, 600);
  const posiKeyWord = useDebounce(posKeyWord, 600);
  const [depList, setDepList] = useState({ loading: false, data: [] });
  const [posList, setPosList] = useState({ loading: false, data: [] });
  const [department, setDepartment] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDepModal, setDepModal] = useState({
    data: {},
    isOpen: false,
  });
  const loadDepartmentList = useCallback(() => {
    setDepList({ loading: true, data: [] });
    const payload = {
      limit: 10000,
      keyword: deptKeyword,
    };
    getDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        const selectedIds = data?.departmentDetails?.map((item) => item?._id) || [];
        setDepList({ loading: false, data: res.docs });
        setDepartment(
          res?.docs?.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: selectedIds.includes(_id),
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [deptKeyword, data]);

  useEffect(() => {
    loadDepartmentList();
  }, [loadDepartmentList]);

  const loadPositionList = useCallback(() => {
    setPosList({ loading: true, data: [] });

    const payload = {
      limit: 300,
      keyword: posiKeyWord,
    };

    getPositionList(payload).then((res) => {
      if (res?.status === 200) {
        setPosList({ loading: false, data: res.docs });
      } else {
        setPosList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [posiKeyWord]);

  useEffect(() => loadPositionList(), [loadPositionList]);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
      });
      setSelectedPosition(
        {
          _id: data?.positionDetails?._id,
          name: data?.positionDetails?.title,
        } || {}
      );
      setPhone(data?.phone || "");
      setImage(data?.image?.url);
      const rolePriority = ["COMPANY-ADMINISTRATOR", "COMPANY-MANAGER", "COMPANY-EMPLOYEE"];
      const highestPriorityRole = data?.roleDetails?.reduce(
        (prev, curr) => {
          return rolePriority.indexOf(curr.code) < rolePriority.indexOf(prev.code) ? curr : prev;
        },
        { code: "COMPANY-EMPLOYEE" }
      );
      setUserType(highestPriorityRole?.code === "COMPANY-MANAGER" ? "COMPANY-EMPLOYEE" : highestPriorityRole?.code);
    }
  }, [data]);

  const onSubmit = async (values) => {
    setLoading(true);
    const departments = {};

    if (selectedDepartments && Array.isArray(selectedDepartments) && selectedDepartments.length > 0) {
      selectedDepartments.forEach((item, i) => {
        departments[`departmentId[${i}]`] = item;
      });
    }
    try {
      if (phone?.length > 12) {
        toast.error("Phone number cannot exceed 12 characters.");
        setLoading(false);
        return;
      }
      if (values.positionId === "other") {
        toast.error("Select a valid job title");
        setLoading(false);
        return;
      }
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        positionId: selectedPosition?._id,
        userType,
        phone: phone || "",
        image: image ? image : "null",
        ...departments,
      };
      const response = data?._id
        ? await employeeEdit({
            ...payload,
            id: data?._id,
          })
        : await employeeAdd(payload);
      setLoading(false);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);

        setEmpAddtype({ isOpen: false });
        dispatch(employeeModal({ data: {}, isOpen: false }));
        loadEmpList();
        loadCount();
        // setList((prev) => ({
        //   ...prev,
        //   data:
        //     response?.status === 200
        //       ? prev.data.map((item) =>
        //           item?._id === response?.data?._id ? response?.data : item
        //         )
        //       : [response?.data, ...prev.data],
        // }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount: response?.status === 201 ? pre?.employeeCount + 1 : pre?.employeeCount,
          }))
        );
      } else {
        toast.error(response?.data?.message || response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const handleSelectedItems = (val) => {
    setSelectedDepartments(val);
  };
  const handleOtherClick = () => {
    setDepModal({ data: {}, isOpen: true });
  };
  const radioOptions = [
    { label: "Administrator", value: "COMPANY-ADMINISTRATOR" },
    // { label: "Evaluator", value: "COMPANY-MANAGER" },
    { label: "Employee", value: "COMPANY-EMPLOYEE" },
  ];

  const [isPositionModal, setPositionModal] = useState({
    data: {},
    isOpen: false,
  });

  useEffect(() => {
    if (selectedPosition?._id === "other") {
      setPositionModal({ data: {}, isOpen: true });
    }
  }, [selectedPosition?._id]);

  useEffect(() => {
    if (selectedPosition?._id === "other") {
      if (data?._id) {
        setFieldsValue({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
        });
      }
      setSelectedPosition({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition?._id]);

  const onClose = () => {
    setIsOpen(false);
    setUserType("COMPANY-EMPLOYEE");
  };

  const onSaveHandler = () => {
    setIsOpen(false);
    setUserType("COMPANY-ADMINISTRATOR");
  };

  return (
    <Modal
      title={data?._id ? "Edit Employee" : "Add Employee"}
      size="xl4"
      open={empModal?.isOpen}
      onClose={() => {
        dispatch(employeeModal({ data: {}, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}>
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-12 gap-4 p-6 lg:pl-0 bg-white rounded-lg shadow-md">
        <div className="image bg-white col-span-12 lg:col-span-4 border-0 lg:border-r border-slate-200 h-auto">
          <h2 className="text-lg font-semibold text-slate-700 text-center border-b border-slate-200 mb-2">
            Employee Image
          </h2>
          <div className="relative">
            <ImageUploader
              label=""
              image={image}
              altImage={noEmpImg}
              isMultiple={false}
              setImage={setImage}
              isCrop={true}
              buttonLabelClasses="!text-xs"
              buttonIcon="fa-upload text-xs"
              labelClasses="text-lg font-bold"
              buttonClasses="!px-1 py-2 bg-blue-500 text-white rounded-lg hover:bg-orange-600 w-36 mx-auto"
              inputClasses="w-40 h-40 hover:bg-slate-200 rounded-full border-2 border-gray-300 overflow-hidden"
              buttonLabel={data?.image ? "Change image" : "Upload image"}
            />
          </div>
        </div>

        <div className="input bg-white col-span-12 lg:col-span-8">
          <div className="md:grid lg:grid-cols-2 gap-3 space-y-3 md:space-y-0">
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"First Name"}
                inputPlaceholder={"Enter first name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.firstName}
                isValidate={true}
                inputName={"firstName"}
                onChange={handleChange}
                charLimit={100}
                {...formValidate(errors, "firstName")}
              />
            </div>
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"Last Name"}
                charLimit={100}
                inputPlaceholder={"Enter last name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.lastName}
                isValidate={true}
                inputName={"lastName"}
                onChange={handleChange}
                {...formValidate(errors, "lastName")}
              />
            </div>
            <div className="relative ">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                inputGroupPosition={"left"}
                label={"Email"}
                inputPlaceholder={"Enter email"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"email"}
                value={values.email}
                inputName={"email"}
                isValidate={true}
                onChange={handleChange}
                {...formValidate(errors, "email")}
              />
            </div>
            <div className="relative">
              <PhoneNumberFormat
                label="Phone"
                value={phone}
                isInputGroup={true}
                inputGroupIcon={"fa-solid fa-phone"}
                inputGroupPosition={"left"}
                setPhone={setPhone}
                inputPlaceholder={"(xxx) xxx-xxxx"}
                charLimit={12}
              />
            </div>
            <div className="relative">
              <SelectDropdownSearch
                label={"Job Title"}
                placeholder={"Choose a Job Title"}
                isSearch={true}
                xPlacement={"bottom"}
                buttonArrowIcon={"fa-solid fa-chevron-down"}
                tabTextColor={"text-slate-700"}
                loading={posList?.loading}
                value={selectedPosition?._id}
                onChange={handleChange}
                isValidate={true}
                dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                {...formValidate(errors, "positionId")}
                dropdownData={[
                  ...(Array.isArray(posList?.data)
                    ? posList?.data?.map(({ _id, title }) => ({
                        name: title,
                        _id: _id,
                      }))
                    : []),
                ]}
                onSearch={(val) => {
                  // setKeywords((pre) => ({ ...pre, posKeyword: val }));
                  setPosKeyWord(val);
                }}
                isOther={true}
                selectedItem={selectedPosition}
                setSelectedItem={setSelectedPosition}
              />
            </div>

            <div className="relative">
              <MultiselectDropdown
                label={"Departments"}
                buttonLabel={"Select Departments"}
                xPlacement={"bottom"}
                isCheckbox={true}
                isSearch={true}
                loading={depList?.loading}
                dropdownData={department}
                setDropdownData={setDepartment}
                value={selectedDepartments}
                buttonArrowIconClasses={"!text-sm !text-slate-500 !right-3"}
                buttonLabelClasses={"!font-medium !text-gray-600"}
                buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                selectedItems={handleSelectedItems}
                isValidate={true}
                {...formValidate(errors, "departmentId")}
                onSearch={(e) =>
                  // setKeywords((pre) => ({
                  //   ...pre,
                  //   deptKeyword: e,
                  // }))
                  {
                    setDepKeyword(e);
                  }
                }
                isOther={true}
                handleOtherClick={handleOtherClick}
              />
            </div>

            <div className="relative">
              <h2 className="text-sm font-medium text-slate-600">User Role</h2>
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                {radioOptions.map((option) => (
                  <Radio
                    key={option?.value}
                    radioLabel={option?.label}
                    radioValue={option?.value}
                    onChange={(e) => {
                    const newValue = e.target.value;
                    setUserType(newValue);
                    if (newValue === "COMPANY-ADMINISTRATOR") {
                      setIsOpen(true);
                    }
                  }}
                    isChecked={userType === option?.value}
                    radioName="userRole"
                    divClass="!overflow-visible"
                  />
                ))}
              </div>
            </div>
            <div className="relative flex justify-end items-end">
              <Button
                loading={loading}
                buttonLabel={data?._id ? (loading ? "Saving..." : "Save") : loading ? "Adding..." : "Add"}
                isDisable={loading ? true : false}
                buttonIcon={data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"}
                buttonIconPosition={"left"}
                buttonType="submit"
                buttonClasses="flex-shrink-0"
              />
            </div>
          </div>
        </div>
      </form>
      <PositionManage
        loadPosList={loadPositionList}
        isPositionModal={isPositionModal}
        setPositionModal={setPositionModal}
        setSelectedItem={setSelectedPosition}
      />
      <DepartmentMange
        isDepModal={isDepModal}
        setDepModal={setDepModal}
        setSelectedDepartments={setSelectedDepartments}
        setDepartment={setDepartment}
      />
        <ConfirmModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        onClose={onClose}
        onSaveHandler={onSaveHandler}
        modalBodyData={
          "Administrators have permission to make edits and changes to the OrgChart. Are you sure you want to make this user an administrator?"
        }
      />
    </Modal>
  );
}
