import { configureStore } from "@reduxjs/toolkit";
import settingsSlice from "./slice/settingsSlice";
import profileSlice from "./slice/profileSlice,";
import authSlice from "./slice/authSlice";
import employeeSlice from "./slice/employeeSlice";
import dashboardSlice from "./slice/dashboardSlice";


export const store = configureStore({
  reducer: {
    profile: profileSlice,
    settings: settingsSlice,
    auth: authSlice,
    empModal: employeeSlice,
    dashboardData : dashboardSlice,
  },
});
