import React, { useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import Input from "../../form/Input";
import Button from "../../form/Button";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";

export default function CopyUrl({ copyUrlModal, setCopyUrlModal = () => {} }) {
  const { values, handleChange,setFieldsValue } = useForm({});
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if (copyUrlModal?.data) {
      setFieldsValue({
        title: `https://orgchartednow.com/share/chart?key=${copyUrlModal?.data?.token}`,
      });
    } else {
      setFieldsValue({
        title: "", 
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyUrlModal?.data]);


  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(values.title); 
      setLoading(true)
    } catch (error) {
      toast.error("Failed to copy URL. Please try again.");
    }
  };

  return (
    <Modal
      title={"Copy Url"}
      size="lg"
      open={copyUrlModal?.isOpen}
      onClose={() => {
        setCopyUrlModal((prev) => ({ isOpen: false , data : {}}));
        setLoading(false);
      }}
      modaltitleClasses={"!text-slate-200 text-[15px]"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!pb-8 !rounded-b-lg"}
    >
      <form>
        <div className="relative flex items-center space-x-2 col-span-12">
          <Input
            isInputGroup={false}
            inputGroupIcon={"fa-light fa-users text-slate-400"}
            inputGroupPosition={"left"}
            label={"Url"}
            inputPlaceholder={"Paste url"}
            inputClasses={"pl-2 flex-grow"}
            labelClasses={"!text-sm !font-medium"}
            inputType={"title"}
            value={values.title}
            onChange={handleChange}
            inputName={"title"}
            isValidate={false}
            isDisabled={true}
          />

          <Button
            buttonLabel={loading ? "Copied" : "Copy"}
            buttonIconPosition={"left"}
            buttonIcon={loading ? "fa-regular fa-check" : "fa-regular fa-copy"}
            buttonType="button" 
            buttonClasses={
              "!py-2 !mt-5 !px-2 rounded-lg !text-white !h-auto bg-orange-500 hover:bg-orange-600"
            }
            buttonFunction={handleCopy} 
          />
        </div>
      </form>
    </Modal>
  );
}
