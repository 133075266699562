import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import { Tooltip } from "react-tooltip";
import { getExcelData } from "../../services/frontService/ChartService";
import * as XLSX from "xlsx";

const ExportDropdown = ({
  data,
  xPlacement,
  downloadTreeAsPDF = () => {},
  exportPartialTree = () => {},
  dropdownContainer,
  userId,
  _id,
  ...props
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  const downloadXlsx = () => {
    const payload = {
      isAssigned: true,
      chart: true,
    };

    if (userId) {
      payload.specificCardId = userId;
    }

    getExcelData(payload).then((res) => {
      if (res?.status === 200) {
        const employees = res?.docs;
        const filteredData = employees
        ?.map((employee,index) => {
          if (!employee?.userDetails) return null; 
          
          return {
            "LAST NAME": employee?.userDetails?.lastName || "",
            "FIRST NAME": employee?.userDetails?.firstName || "",
            POSITION: employee?.userDetails?.positionDetails
              ? employee?.userDetails?.positionDetails?.title
              : "",
            EMAIL: employee?.userDetails?.email || "",
            ROLE: (() => {
              if (
                employee?.userDetails?.roleDetails?.code === "COMPANY-MANAGER"
              ) {
                return "EVALUATOR";
              }
              if (
                employee?.userDetails?.roleDetails?.code ===
                "COMPANY-ADMINISTRATOR"
              ) {
                return "ADMINISTRATOR";
              }
              return "EMPLOYEE";
            })(),
            DEPARTMENTS: employee?.userDetails?.departmentDetails
              ? employee?.userDetails?.departmentDetails
                  ?.map((department) => department?.name)
                  .join(", ")
              : "",
              "REPORTS TO": index === 0 ? "N/A" : employee?.parentDetails?.parentUserDetails?.email || "N/A",
            "REPORTS TO CARD NUMBER": employee?.parentDetails?.cardNumber || "",
          };
        })
        .filter((item) => item !== null);

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Employees");

        XLSX.writeFile(wb, "Chart.xlsx");
      }
    });
  };

  const downloadCSV = () => {
    const payload = {
      isAssigned: true,
      chart: true,
    };

    if (userId) {
      payload.specificCardId = userId;
    }

    getExcelData(payload).then((res) => {
      if (res?.status === 200) {
        const employees = res?.docs;

        const filteredData = employees?.map((employee,index) => {
          if (!employee?.userDetails) return {};

          return {
            "LAST NAME": employee?.userDetails?.lastName || "",
            "FIRST NAME": employee?.userDetails?.firstName || "",
            POSITION: employee?.userDetails?.positionDetails
              ? employee?.userDetails?.positionDetails?.title
              : "",
            EMAIL: employee?.userDetails?.email || "",
            ROLE: (() => {
              if (
                employee?.userDetails?.roleDetails?.code === "COMPANY-MANAGER"
              ) {
                return "EVALUATOR";
              }
              if (
                employee?.userDetails?.roleDetails?.code ===
                "COMPANY-ADMINISTRATOR"
              ) {
                return "ADMINISTRATOR";
              }
              return "EMPLOYEE";
            })(),
            DEPARTMENTS: employee?.userDetails?.departmentDetails
              ? employee?.userDetails?.departmentDetails
                  .map((department) => department?.name)
                  .join(", ")
              : "",
              "REPORTS TO": index === 0 ? "N/A" : employee?.parentDetails?.parentUserDetails?.email || "N/A",
            "REPORTS TO CARD NUMBER": employee?.parentDetails?.cardNumber || "",
          };
        });
        const validData = filteredData.filter(
          (row) => Object.keys(row).length > 0
        );

        const ws = XLSX.utils.json_to_sheet(validData);

        const csv = XLSX.utils.sheet_to_csv(ws);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Chart.csv";
        link.click();
      }
    });
  };

  const dropdownData = [
    {
      _id: 1,
      name: "Export CSV",
      icon: "fa-solid fa-file-csv",
      onclick: () => {
        downloadCSV();
      },
      standout: false,
    },
    {
      _id: 2,
      name: "Export XLSX",
      icon: "fa-solid fa-file-xls",
      onclick: () => {
        downloadXlsx();
      },
      standout: false,
    },
    {
      _id: 3,
      name: "Export PDF",
      icon: "fa-solid fa-file-pdf",
      onclick: (val) => {
        if (userId) {
          exportPartialTree();
        } else {
          downloadTreeAsPDF();
        }
      },
      standout: false,
    },
  ];

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div
                className="relative w-full h-10 flex items-center justify-between border border-orange-500 px-3 rounded-md bg-white"
                data-tooltip-id={`export-chart-all-${_id}`}
              >
                <div className="text-xs md:text-sm font-medium text-orange-500 flex items-center">
                  <i className={classNames("fa-fw", data?.icon, "mr-1")}></i>{" "}
                  {data?.name && data?.name}
                </div>
                <div className="text-sm hidden md:block ml-1 text-orange-500">
                  <i
                    className={classNames(
                      "fa-regular fa-fw fa-chevron-down transition-all duration-200"
                    )}
                  ></i>
                  <Tooltip
                    id={`export-chart-all-${_id}`}
                    place="bottom"
                    className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                    render={() => (
                      <div className="text-white">
                        Click to export CSV, XLSX & PDF.
                      </div>
                    )}
                  />
                </div>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              className="z-50"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                className={classNames(
                  "absolute border border-slate-200 w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                  dropdownContainer
                )}
              >
                <div className="py-1 max-h-80 overflow-auto scroll-smooth scrollbar">
                  {dropdownData?.map((item) => (
                    <Menu.Item key={item._id}>
                      {({ active }) =>
                        item?.link ? (
                          <Link
                            to={item?.link}
                            className={classNames(
                              "group flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                              active
                                ? "bg-orange-100 text-orange-500"
                                : "text-slate-500",
                              item.standout
                                ? "!text-opplio-red hover:!bg-opplio-red/10"
                                : "",
                              item.class
                            )}
                          >
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <span>{item.name}</span>
                          </Link>
                        ) : (
                          <div
                            onClick={item?.onclick}
                            className={classNames(
                              "group cursor-pointer flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                              active
                                ? "bg-orange-100 text-orange-500"
                                : "text-slate-500",

                              item.class
                            )}
                          >
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <span>{item.name}</span>
                          </div>
                        )
                      }
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default ExportDropdown;
