import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import SyncDataPopup from "../components/popup/SyncDataPopup";
import { getUserList, syncGenarateToken } from "../services/SsoService";
import ConfirmationPopup from "../components/common/ConfirmationPopup";
import nineBoxLogo from "../assets/images/9boxlogo.png";
import successionnowLogo from "../assets/images/successionnowlogo.png";
import axios from "axios";
import { toast } from "react-toastify";
import {
  NINEBOX_API_URL,
  NINEBOX_BASE_URL,
  SUCCESSION_API_URL,
  SUCCESSION_BASE_URL,
} from "../config/host";
import { useSelector } from "react-redux";

const SsoLoginContext = createContext();

export const SsoLoginProvider = ({ children }) => {
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const userData = useSelector((state) => state?.profile?.data);
  const [selectedWebsite, setSelectedWebsite] = useState({
    _id: 1,
    label: "9BoxNow",
    image: nineBoxLogo,
    code: "nineBoxNow",
    value: "nineBoxNow",
  });
  const [syncingLoader, setSyncingLoader] = useState(false);
  const [message, setMessage] = useState(null);

  const dropdownData = [
    {
      _id: 1,
      name: "9BoxNow",
      image: nineBoxLogo,
      code: "nineBoxNow",
      value: "nineBoxNow",
      color: "blue-500",
    },
    {
      _id: 2,
      name: "SuccessionNow",
      image: successionnowLogo,
      code: "successionNow",
      value: "successionNow",
      color: "teal-500",
    },
  ];

  const companyCreateVerify = useCallback(
    async (url, headersToken, selectedsite) => {
      const data = {};
      try {
        const result = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${headersToken}`,
            "Content-Type": "application/json",
          },
        });
        if (result?.data?.status === 200) {
          setSyncingLoader(false);
          toast.success("Comapny syncing successfull");
          window.open(
            result?.data?.data?.redirectUrl,
            "_blank",
            "noopener,noreferrer"
          );

          setEnabledConfirmationPopup(false);
        } else if (result?.data?.status === 417) {
          setSyncingLoader(false);
          window.open(
            result?.data?.data?.redirectUrl,
            "_blank",
            "noopener,noreferrer"
          );
        } else {
          setSyncingLoader(false);
          toast.error(
            result?.data?.message ||
              "An error occurred while processing the request."
          );
          setEnabledConfirmationPopup(false);
        }
      } catch (err) {
        setSyncingLoader(false);
        toast.error(
          err?.response?.data?.message ||
            "An error occurred while processing the request."
        );
        setEnabledConfirmationPopup(false);
      }
    },
    []
  );

  const getGenarateToken = async (DOMAIN, API_URL = "") => {
    setSyncingLoader(true);
    try {
      const res = await syncGenarateToken({
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "verify/token";
        const headersToken = res?.data;
        companyCreateVerify(url, headersToken, selectedWebsite);
      } else {
        toast.error(res?.data?.message || "Something went wrong");
        setEnabledConfirmationPopup(false);
        setSyncingLoader(false);
      }
    } catch (err) {
      console.log(err);
      setSyncingLoader(false);
      toast.error("Somthing went wrong !!!");
      setEnabledConfirmationPopup(false);
    }
  };

  useEffect(() => {
    setMessage(
      `Hello ${
        userData?.firstName + " " + userData?.lastName
      }, do you want to log in to the ${
        selectedWebsite?.name
      } website? If yes, please click the Confirm button to proceed.`
    );
  }, [selectedWebsite, userData]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "confirm") {
      if (selectedWebsite?.code === "successionNow") {
        getGenarateToken(SUCCESSION_BASE_URL, SUCCESSION_API_URL);
      } else if (selectedWebsite?.code === "nineBoxNow") {
        getGenarateToken(NINEBOX_BASE_URL, NINEBOX_API_URL);
      } else {
        toast.error("Please Select a Website For Login");
      }
    }
  }, [actionValue]);


  return (
    <SsoLoginContext.Provider
      value={{
        enabledConfirmationPopup,
        setEnabledConfirmationPopup,
        dropdownData,
        selectedWebsite,
        setSelectedWebsite,
      }}
    >
      {children}
      {enabledConfirmationPopup && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup}
          title={"Confirmation"}
          message={message}
          confirmButtonLabel={"Confirm"}
          cancelButtonLabel={"Cancel"}
          setIsOpen={(val) => setEnabledConfirmationPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          setLoading={setSyncingLoader}
          loading={syncingLoader}
        />
      )}
    </SsoLoginContext.Provider>
  );
};

export const useSsoLogin = () => {
  return useContext(SsoLoginContext);
};
