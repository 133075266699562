import SampleCSV from "../../../assets/chart_xlsx.xlsx";
import SampleImage from "../../../assets/sample_image.png";
import React, { useState, useRef } from "react";
import Modal from "../../elements/Modal";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import { csvUploadChart } from "../../../services/frontService/ChartService";

export default function CsvUploadChart({
  importModal,
  loadChart = () => {},
  setImportModal = () => {},
}) {
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isDownloadingImage, setIsDownloadingImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [extension, setExtension] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validExtensions = ["csv", "xls", "xlsx"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        alert("Invalid file type. Please select a CSV or Excel file.");
        return;
      }
      setExtension(fileExtension);
      setSelectedFile(file);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = "";
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      const res = await csvUploadChart({ xlsx: selectedFile, type: extension });
      if (res?.status === 200) {
        toast.success(res?.message);
        setImportModal({ isOpen: false });
        setSelectedFile(null);
        loadChart();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || "An error occurred during upload.");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data?.message ||
          "An unexpected error occurred during file upload."
      );
    }
  };

  const handleCSVDownloadClick = (e) => {
    e.preventDefault();
    setIsDownloadingCSV(true);
    setTimeout(() => {
      setIsDownloadingCSV(false);
      const link = document.createElement("a");
      link.href = SampleCSV;
      link.download = "SampleXLSX.xlsx";
      link.click();
    }, 2000);
  };

  const handleImageDownloadClick = (e) => {
    e.preventDefault();
    setIsDownloadingImage(true);
    setTimeout(() => {
      setIsDownloadingImage(false);
      const link = document.createElement("a");
      link.href = SampleImage;
      link.download = "SampleImage.jpg"; 
      link.click();
    }, 2000);
  };

  return (
    <Modal
      title={"Upload CSV"}
      size="lg"
      open={importModal?.isOpen}
      onClose={() => {
        setImportModal((prev) => ({ ...prev, isOpen: false }));
        setSelectedFile(null);
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <div className="w-full p-3 flex flex-col justify-center items-center gap-4">
        {/* Download Sample CSV */}
        <div className="w-full bg-orange-50 p-4 rounded-lg border-orange-200 border flex flex-col items-center justify-center">
          <p className="text-sm text-gray-600 mb-2">
            Download the sample XLSX file to understand the expected format.
          </p>
          <a
            href="#"
            onClick={handleCSVDownloadClick}
            className={`text-orange-500 hover:text-orange-800 text-sm underline flex items-center gap-2 ${
              isDownloadingCSV ? "pointer-events-none text-gray-400" : ""
            }`}
          >
            {isDownloadingCSV ? (
              <i className="fa-duotone fa-spinner-third animate-spin"></i>
            ) : (
              <i className="fa-regular fa-file-arrow-down"></i>
            )}
            <span>{isDownloadingCSV ? "Preparing download..." : "Sample XLSX"}</span>
          </a>
        </div>

      {/* Download Sample Image  */}
        <div className="w-full bg-blue-50 p-4 rounded-lg border-blue-200 border flex flex-col items-center justify-center">
          <p className="text-sm text-gray-600 mb-2">
            Download the sample image to understand the expected format.
          </p>
          <a
            href="#"
            onClick={handleImageDownloadClick}
            className={`text-blue-500 hover:text-blue-800 text-sm underline flex items-center gap-2 ${
              isDownloadingImage ? "pointer-events-none text-gray-400" : ""
            }`}
          >
            {isDownloadingImage ? (
              <i className="fa-duotone fa-spinner-third animate-spin"></i>
            ) : (
              <i className="fa-regular fa-image"></i>
            )}
            <span>{isDownloadingImage ? "Preparing download..." : "Sample Image"}</span>
          </a>
        </div>

        {selectedFile && (
          <div className="w-full bg-gray-100 border p-4 rounded-lg shadow flex justify-between items-center">
            <span className="text-sm font-medium text-gray-700">{selectedFile.name}</span>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={removeFile}
              aria-label="Remove file"
              title="Remove file"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        )}
        {!selectedFile && (
          <label htmlFor="csvFileInput" className="cursor-pointer">
            <Button
              buttonLabel={"Select ( CSV / XLS ) file"}
              buttonIcon={"fa-regular fa-file-excel"}
              buttonIconPosition={"left"}
              buttonClasses={"!z-0 !rounded-full"}
              buttonFunction={() => fileInputRef.current.click()}
            />
          </label>
        )}
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          accept=".csv,.xls,.xlsx"
          onChange={handleFileChange}
        />
        {selectedFile && (
          <Button
            buttonLabel={"Upload File"}
            buttonIconPosition={"left"}
            buttonClasses={"!z-0 !rounded-full"}
            buttonFunction={handleUpload}
            isDisable={loading}
            buttonIcon={
              loading
                ? "fa-duotone fa-spinner-third animate-spin"
                : "fa-regular fa-file-excel"
            }
          />
        )}
      </div>
    </Modal>
  );
}
