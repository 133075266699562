import React, { useEffect, useState } from "react";

const ToggleSwitch = ({ onToggle, isUnassigned }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };

  useEffect(() => {
   if(isToggled && !isUnassigned){
    setIsToggled(false)
   }
  }, [isUnassigned,isToggled])
  
  return (
    <div className="flex items-center">
      {/* <span
        className={`mr-2 text-sm ${
          isToggled ? "text-gray-400" : "text-orange-500"
        }`}
      >
        All Users
      </span> */}
      <div
        onClick={handleToggle}
        className={`relative inline-block w-12 h-6 rounded-full cursor-pointer ${
          isToggled ? "bg-orange-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`absolute top-0 left-0 w-6 h-6 bg-white rounded-full transition-all ${
            isToggled ? "translate-x-6" : "translate-x-0"
          }`}
        ></div>
      </div>
      <span
        className={`ml-2 text-sm ${
          isToggled ? "text-orange-500" : "text-gray-400"
        }`}
      >
        {isToggled ? "Unassigned Users" : "All Users"}
      </span>
    </div>
  );
};

export default ToggleSwitch;
