import {
  deleteDepartment,
  getDepartmentList,
  getDepartmentTree,
} from "../../../../services/frontService/DepartmentService";
import Directory from "../../../../pages/front/directory/Directory";
import DepartmentMange from "../../../modals/front/DepartmentMange";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import { setTitle } from "../../../../helpers/MetaTag";
import DepartmentListItem from "./DepartmentListItem";
import DepartmentTreeItem from "./DepartmentTreeItem";
import DeleteModal from "../../../common/DeleteModal";
import Search from "../../../form/Search";
import Button from "../../../form/Button";
import React, { useCallback, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { companyCount } from "../../../../services/frontService/CompanyService";
import { getCount } from "../../../../redux/slice/dashboardSlice";

export default function DepartmentSection() {
  setTitle("ORG Chart | Departments");
  const [isDepModal, setDepModal] = useState({ data: {}, isOpen: false });
  const [listupdate, setListupdate] = useState(0);
  const [style, setStyle] = useState("list");
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [tree, setTree] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  const [filters, setFilters] = useState({
    limit: 12,
    offset: 0,
    currentPage: 0,
  });

   const loadCount = useCallback(() => {
       companyCount().then((res) => {
         if (res?.status === 200) {
         
            dispatch(getCount(res?.data));
         }
       });
     }, []);
     useEffect(() => {
       loadCount();
     }, [loadCount]);
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    const payload = { keyword, ...filters, sortQuery: "-createdAt" };

    getDepartmentList(payload).then((res) => {
      if (res && res.status === 200) {
        setList({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [keyword, filters]);

  useEffect(() => {
    if (style === "list") {
      loadList();
    }
  }, [loadList, style]);

  const dispatch = useDispatch();

  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteDepartment({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        style === "list"
          ? setList((pre) => {
              const updatedData = pre?.data?.filter((item) => !deleteModal?.ids.includes(item._id));

              return {
                ...pre,
                data: updatedData,
              };
            })
          : setListupdate((pre) => pre + 1);
        dispatch(
          setProfile((pre) => ({
            ...pre,
            departmentCount: pre?.departmentCount - 1,
          }))
        );
        loadCount();
        setDepModal((pre) => ({ ...pre, isOpen: false }));
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        loadList();
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };
  const loadTree = useCallback(() => {
    setTree((pre) => ({ ...pre, data: [], loading: true }));

    getDepartmentTree({ limit: 400, sortQuery: "-createdAt" }).then((res) => {
      if (res && res.status === 200) {
        setTree({
          loading: false,
          data: res?.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setTree((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listupdate]);

  useEffect(() => {
    if (style === "tree") {
      loadTree();
    }
  }, [loadTree, style]);
  return (
    <Directory>
      <div className="!h-full flex flex-col">
        <div className="w-full flex  flex-col lg:flex-row space-y-2 lg:items-center justify-between">
          <p className="text-lg text-gray-800 pl-2">
            Departments
            <span className="text-[#f76310]"> ({list?.totalItem})</span>
          </p>

          <div className="flex gap-2 items-center justify-center ">
            {style === "list" && (
              <Search
                placeholder={"Search departments"}
                search={(val) => setKeyword(val)}
                divClasses={"!rounded-md !h-10 border border-slate-300"}
              />
            )}
            <div className="flex gap-2">
              <div data-tooltip-id="list-view">
                <Button
                  buttonIcon={"fa-regular fa-list text-lg"}
                  buttonIconPosition={"left"}
                  buttonFunction={() => setStyle("list")}
                  buttonClasses={`!border-orange-300 ${style === "list" ? "" : "!bg-white !text-orange-500"}`}
                />
                <Tooltip
                  id="list-view"
                  place="left"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => <div className="text-white">Click to view all departments in a list.</div>}
                />
              </div>
              <div data-tooltip-id="tree-view">
                <Button
                  buttonIcon={"fa-regular fa-list-tree text-lg"}
                  buttonIconPosition={"left"}
                  buttonFunction={() => setStyle("tree")}
                  buttonClasses={`!border-orange-300 ${style === "tree" ? "" : "!bg-white !text-orange-500"}`}
                />
                <Tooltip
                  id="tree-view"
                  place="left"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => <div className="text-white">Click to view all departments in a tree.</div>}
                />
              </div>
            </div>
            <div data-tooltip-id="add-department">
              <Button
                buttonLabel={"New"}
                buttonIcon={"fa-regular fa-plus text-xs"}
                buttonClasses={"!w-20"}
                buttonIconPosition={"left"}
                buttonFunction={() => {
                  setDepModal({ data: {}, isOpen: true });
                }}
              />
              <Tooltip
                id="add-department"
                place="left"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => <div className="text-white">Click to add departments.</div>}
              />
            </div>
          </div>
        </div>

        {style === "list" ? (
          <DepartmentListItem
            list={list}
            setList={setList}
            filters={filters}
            setFilters={setFilters}
            isDepModal={isDepModal}
            setDepModal={setDepModal}
            setDeleteModal={setDeleteModal}
            loadCount={loadCount}
          />
        ) : (
          <DepartmentTreeItem
            tree={tree}
            setTree={setTree}
            isDepModal={isDepModal}
            listupdate={listupdate}
            setDepModal={setDepModal}
            setDeleteModal={setDeleteModal}
          />
        )}

        <DepartmentMange
          tree={tree}
          style={style}
          setTree={setTree}
          setList={setList}
          isDepModal={isDepModal}
          setDepModal={setDepModal}
          setListupdate={setListupdate}
          loadList={loadList}
          loadCount={loadCount}
        />
        <DeleteModal
          open={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onClose={() => {
            setDeleteModal((pre) => ({ ...pre, isOpen: false }));
          }}
          deleteHandler={deletefunction}
          title="Delete Department"
          subTitle="You are trying to delete a department"
        />
      </div>
    </Directory>
  );
}
