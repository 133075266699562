import React, { useState } from "react";
import Button from "../../../components/form/Button";
import logo from "../../../assets/images/logoddd.png";
import Image from "../../../components/elements/Image";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../../components/common/LogoutModal";

const BuyPlan = () => {
  const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
  return (
    <div>
      <section className="relative flex items-center justify-center w-full min-h-screen bg-gradient-to-r from-slate-50 via-slate-100 to-slate-200 py-20">
        <div className="w-full max-w-7xl mx-auto px-6">
          <div onClick={() => navigate("/")} className="block text-center mb-8">
            <div className="h-16 w-full flex justify-center items-center mb-5">
              <div className="w-56 overflow-hidden">
                <Image
                  src={logo}
                  alt="Site Logo"
                />
              </div>
            </div>
          </div>

          <div className="text-center mb-6 space-y-3">
            <h1 className="text-4xl font-extrabold text-violet-700">
              You’re almost ready to start planning!
            </h1>
            <h2 className="text-3xl font-bold text-orange-600">
              Welcome to ORG Chart
            </h2>
          </div>

          <div className="flex flex-wrap justify-center -mx-5">
            <div className="w-full max-w-md px-5 py-2">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="bg-gray-50 px-5 py-2">
                  <div className="text-rose-600 w-16 h-16 mx-auto my-3 text-6xl flex items-center justify-center">
                    <i className="fa-solid fa-info-circle"></i>
                  </div>
                  <h3 className="text-lg font-semibold text-yellow-600 text-center">
                    Your account is currently inactive due to a pending payment.
                    To restore access, please contact the administrator for
                    assistance.
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center pt-6">
            <Button
              buttonClasses="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg shadow-md transition-all"
              buttonType=""
              buttonHasLink={false}
              buttonLabel="Logout"
              buttonLabelClasses=""
                buttonFunction={() => setOpen(true)}
            />
          </div>
        </div>
      </section>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </div>
  );
};

export default BuyPlan;
