import React, { useState } from "react";
import Button from "../../../form/Button";

import DepartmentTreeLoader from "../../../loaders/DepartmentTreeLoader";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const DepartmentTree = ({
  departments,
  setDepModal,
  setDeleteModal = () => {},
}) => {
  const [collapsed, setCollapsed] = useState({});
  const navigate = useNavigate();

  const toggleCollapse = (id) => {
    setCollapsed((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderDepartments = (departments, depth = 0) => (
    <ul className="pl-6 relative">
      {departments?.map((department) => (
        <li key={department?._id} className="relative">
          <div className="flex items-center mb-4">
            {department?.children?.length > 0 && (
              <button
                className="mr-2 w-5 h-5 flex items-center justify-center rounded-full border border-gray-400 bg-white hover:bg-slate-100 absolute -left-8"
                onClick={() => toggleCollapse(department?._id)}
              >
                <i
                  className={`fa-regular text-xs  ${
                    collapsed[department._id]
                      ? "fa-chevron-right"
                      : "fa-chevron-down"
                  } text-gray-500`}
                ></i>
              </button>
            )}
            <div
              key={department?._id}
              className={`relative flex flex-col justify-between p-4 border rounded-lg shadow hover:shadow-md cursor-pointer border-l-4 max-w-9xl`}
              onClick={() =>
                navigate("/employees", {
                  state: { departmentIds: [department?._id] },
                })
              }
              style={{ borderLeftColor: department?.theme?.headerColor }}
            >
              <div className="flex items-center justify-between space-x-36">
                <div className="text-base text-gray-800 font-medium ">
                  {department?.name?.substring(0, 24) +
                    (department?.name?.length > 24 ? "....." : "")}
                </div>
                <div className="flex space-x-2">
                 
                  <div
                    onClick={(e) => e.stopPropagation()}
                    data-tooltip-id={`delete-tree-${department._id}`}
                  >
                    <Button
                      buttonIcon={
                        "fa-fw fa-regular fa-trash-can text-xs font-light"
                      }
                      buttonIconPosition={"left"}
                      buttonClasses={
                        "w-6 h-6 !border !bg-slate-50 !border-red-400 !text-red-600 hover:!bg-red-100 !rounded-lg flex items-center justify-center"
                      }
                      buttonFunction={(e) => {
                        e.preventDefault();
                        setDeleteModal((pre) => ({
                          ...pre,
                          isOpen: true,
                          ids: [department?._id],
                        }));
                      }}
                    />
                    <Tooltip
                      id={`delete-tree-${department._id}`}
                      place="right"
                      className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                      render={() => (
                        <div className="text-white">
                          Click to delete departments.
                        </div>
                      )}
                    />
                  </div>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    data-tooltip-id={`edit-tree-${department._id}`}
                  >
                    <Button
                      buttonIcon={
                        "fa-fw fa-regular fa-pen-to-square text-xs font-light"
                      }
                      buttonIconPosition={"left"}
                      buttonClasses={
                        "w-6 h-6 !border !bg-slate-50 !border-slate-400 !text-black hover:!bg-blue-100 !rounded-lg flex items-center justify-center"
                      }
                      buttonFunction={(e) => {
                        e.preventDefault();
                        setDepModal({ isOpen: true, data: department });
                      }}
                    />
                    <Tooltip
                      id={`edit-tree-${department._id}`}
                      place="right"
                      className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                      render={() => (
                        <div className="text-white">
                          Click to edit departments.
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="flex items-center justify-end gap-1 text-xs"
                  data-tooltip-id={`position-employee-${department._id}`}
                >
                  <i className="fa-regular fa-user " />
                  <p className="font-medium text-slate-500">
                    {department?.departmentEmpCount}
                  </p>
                  <Tooltip
                    id={`position-employee-${department._id}`}
                    place="right"
                    className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                    render={() => {
                      if (department?.departmentEmpList?.length === 0) {
                        return null;
                      }

                      const maxVisible = 2;
                      const employeesToShow =
                        department?.departmentEmpList?.slice(0, maxVisible);
                      const hasMore =
                        department?.departmentEmpList?.length > maxVisible;

                      return (
                        <div>
                          {employeesToShow?.map((emp) => (
                            <div key={emp._id} className="text-white">
                              {emp?.firstName + " " + emp?.lastName}
                            </div>
                          ))}
                          {hasMore && (
                            <div className="text-blue-500 cursor-pointer flex justify-end">
                              <span>
                                {department?.departmentEmpCount - maxVisible}{" "}
                                more...
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: collapsed[department?._id] ? "0" : "500px",
              opacity: collapsed[department?._id] ? "0" : "1",
              transform: collapsed[department?._id]
                ? "scaleY(0.8)"
                : "scaleY(1)",
              transition:
                "max-height 0.8s ease-out, opacity 1s ease-out, transform 1s ease-out",
              overflow: "auto",
            }}
          >
            {!collapsed[department?._id] &&
              department?.children?.length > 0 && (
                <div className="pl-8">
                  {renderDepartments(department?.children, depth + 1)}
                </div>
              )}
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="w-full overflow-auto border-t my-3 p-8">
      {renderDepartments(departments)}
    </div>
  );
};

export default function DepartmentTreeItem({
  tree,
  listupdate,
  setTree = () => {},
  setDepModal = () => {},
  setDeleteModal = () => {},
}) {
  return (
    <div className="w-full">
      {tree.loading ? (
        <DepartmentTreeLoader />
      ) : (
        <DepartmentTree
          departments={tree?.data}
          setDepModal={setDepModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </div>
  );
}
