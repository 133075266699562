export default function DepartmentTreeLoader({ depth = 0, maxDepth = 2 }) {
  if (depth >= maxDepth) {
    return null;
  }

  return (
    <ul className="px-10 py-1 relative">
      {[...Array(3)].map((_, index) => (
        <li key={index} className="relative shimmer-effect mb-2">
          <div className="w-1/3 flex items-center mb-2">
            <div className="w-full relative flex flex-col justify-between p-2 px-3 border rounded-lg shadow hover:shadow-md cursor-pointer border-l-4 shimmer-effect bg-slate-100">
              <div className="w-full flex items-center justify-between space-x-20">
                <div className="w-3/4 h-4 bg-slate-200 rounded"></div>
                <div className="flex space-x-2">
                  <div className="w-6 h-6 bg-slate-200 rounded"></div>
                  <div className="w-6 h-6 bg-slate-200 rounded"></div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-end gap-1 text-xs">
                  <div className="w-4 h-4 bg-slate-200 rounded-full"></div>{" "}
                  <div className="w-12 h-3 bg-slate-200 rounded"></div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="pl-8">
            <DepartmentTreeLoader depth={depth + 1} maxDepth={maxDepth} />
          </div>
        </li>
      ))}
    </ul>
  );
}
