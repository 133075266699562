import React, { useEffect } from "react";
import Modal from "../../elements/Modal";
import Image from "../../elements/Image";
import Button from "../../form/Button";
import remove1 from "../../../assets/images/remove5.png";
import remove2 from "../../../assets/images/remove6.png";

export default function NodeDelete({
  hasChild,
  open = false,
  deleteStatus,
  customMessage,
  loading = false,
  onClose = () => {},
  title = "Remove Employee",
  deleteHandler = () => {},
  setDeleteStatus = () => {},
}) {
  
  useEffect(() => {
    if (open) {
      setDeleteStatus("single");
    }
  }, [open, setDeleteStatus]);
  const handleImageClick = (image) => {
    setDeleteStatus(image);
  };

  return (
    <>
      {hasChild ? (
        <Modal
          open={open}
          onClose={() => {
            onClose();
            setDeleteStatus("single");
          }}
          title={title}
          size="xl"
          modaltitleClasses={"!text-slate-200 text-lg"}
          headerClass={"!bg-black !rounded-t-lg !text-white"}
          childrenClasses={"!rounded-b-lg"}
        >
          <div className="p-3">
            <div className="text-center text-orange-600 font-semibold text">
              {customMessage ? (
                customMessage
              ) : (
                <>
                  This Employee manages others below it. Would you like to
                  proceed?
                </>
              )}
            </div>

            <div className="flex justify-center gap-8 mt-3 border-b border-gray-300 py-6">
              <div
                onClick={() => handleImageClick("single")}
                className={`cursor-pointer border-2 rounded-xl overflow-hidden p-2 transition-all duration-300 transform ${
                  deleteStatus === "single"
                    ? "border-orange-500 shadow-lg scale-105"
                    : "border-gray-300"
                }`}
              >
                <Image
                  src={remove1}
                  alt="Single Delete"
                  className="object-cover w-full h-full rounded-xl"
                />
              </div>
              <div
                onClick={() => handleImageClick("multiple")}
                className={`cursor-pointer border-2 rounded-xl overflow-hidden p-2 transition-all duration-300 transform ${
                  deleteStatus === "multiple"
                    ? "border-orange-500 shadow-lg scale-105"
                    : "border-gray-300"
                }`}
              >
                <Image
                  src={remove2}
                  alt="Multiple Delete"
                  className="object-cover w-full h-full rounded-xl"
                />
              </div>
            </div>

            <div className="flex gap-4 justify-end mt-5">
              <Button
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-close"}
                buttonLabel={"Cancel"}
                buttonFunction={() => {
                  onClose();
                  setDeleteStatus("single");
                }}
                buttonClasses={"bg-slate-50 border-red-500 !text-slate-500"}
              />
              <Button
                buttonFunction={deleteHandler}
                buttonIconPosition={"left"}
                buttonIcon={
                  loading
                    ? "fa-duotone fa-spinner-third animate-spin"
                    : "fa-regular fa-trash-can"
                }
                buttonLabel={loading ? "Removing..." : "Remove"}
                buttonClasses={"bg-red-500 border-red-500 hover:bg-red-600"}
                loading={loading}
              />
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={() => {
            onClose();
            setDeleteStatus("single");
          }}
          title={"Delete Role"}
          size="sm"
          modaltitleClasses={"!text-slate-200 text-lg"}
          headerClass={"!bg-black !rounded-t-lg !text-white"}
          childrenClasses={"!rounded-b-lg"}
        >
          <div className="text-center my-4 text-slate-500 text-sm font-medium">
            {customMessage ? (
              customMessage
            ) : (
              <>
                You are trying to delete the record,
                <br /> click &quot;Delete&quot; below.
              </>
            )}
          </div>
          <div className="flex gap-4 justify-center">
            <Button
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-close"}
              buttonLabel={"Cancel"}
              buttonFunction={() => {
                onClose();
                setDeleteStatus("single");
              }}
              buttonClasses={"bg-slate-50 border-red-500 !text-slate-500"}
            />
            <Button
              buttonFunction={deleteHandler}
              buttonIconPosition={"left"}
              isDisable={loading}
              buttonIcon={
                loading
                  ? "fa-duotone fa-spinner-third animate-spin"
                  : "fa-regular fa-trash-can"
              }
              buttonLabel={loading ? "Deleting..." : "Delete"}
              buttonClasses={"bg-red-500 border-red-500 hover:bg-red-600"}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
