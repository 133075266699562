import React, { useRef, useState } from "react";
import Image from "../elements/Image";
import NoImage from "../../assets/images/no-image.png";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { classNames } from "../../helpers/classNames";
import { toast } from "react-toastify";
import CropModal from "../elements/CropModal";

const ImageUploader = ({
  label = "Upload Image",
  labelClasses = "text-lg font-bold",
  image = null,
  setImage = () => {},
  removeFile = () => {},
  buttonClasses = "",
  buttonIcon = "fa-upload",
  altImage = "",
  inputClasses = "",
  buttonIconPosition = "left",
  buttonLabel = image ? "Change Image" : "Upload Image",
  buttonLabelClasses = "",
  accepts = "image/jpeg, image/png, image/gif",
  isCameraIcon,
  removeIcon,
  imageobjectfit,
  inputbgcolour,
  showImageOnly = false,
  isCrop = false,
}) => {
  const fileInputRef = useRef(null);
  const [showCropper, setShowCropper] = useState(false);

  const [originalImage, setOriginalImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split("/")[1].toLowerCase();

      if (["jpg", "jpeg", "png"].includes(fileType)) {
        setImage(file);
        setOriginalImage(URL.createObjectURL(file));
        setTempImage(URL.createObjectURL(file));
      } else {
        toast.warning("We accept only JPG, JPEG, and PNG files.");
      }
    }
  };
  const imageSource =
    image && (image instanceof File || image.length > 0)
      ? image instanceof File
        ? URL.createObjectURL(image)
        : image
      : altImage
      ? altImage
      : NoImage;

  const handleImageRemove = () => {
    setImage(null);
    removeFile(image);
    fileInputRef.current.value = null;
  };
  return (
    <>
      <div className="flex flex-col gap-4 items-center">
        {label && (
          <div
            className={`text-sm font-medium text-slate-500 mb-1 ${labelClasses}`}
          >
            {label}
          </div>
        )}

        <div className="items-center justify-center gap-4 space-y-3">
          <div className="relative group">
            <div
              className={`overflow-hidden border-4 border-slate-200 rounded-full ${
                inputbgcolour || "bg-white"
              } flex items-center justify-center ${
                inputClasses ? inputClasses : "w-64 h-64"
              }`}
              data-tooltip-id="show-crop-image"
              onClick={
                isCrop && image
                  ? () => {
                      setTempImage(
                        image instanceof File
                          ? URL.createObjectURL(image)
                          : image
                      );
                      setShowCropper(true);
                    }
                  : null
              }
            >
              <Image
                src={imageSource}
                fill
                className={classNames("object-contain", imageobjectfit)}
                alt=""
              />
              {isCrop && image && (
                <Tooltip
                  id="show-crop-image"
                  place="right"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to crop images.</div>
                  )}
                />
              )}
              {image && !showImageOnly && !removeIcon && (
                <div className="absolute rounded-full w-full h-full bg-black/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageRemove();
                    }}
                    type="button"
                    className="absolute flex items-center justify-center w-8 h-8 rounded-full bg-red-500 shadow text-white text-sm "
                  >
                    <i className="fa-regular fa-fw fa-trash"></i>
                  </button>
                </div>
              )}

              {/* Only show the camera button for the profile page */}
              {!showImageOnly && isCameraIcon && (
                <div className="absolute right-2 bottom-2 p-2 bg-white rounded-full">
                  <button className="text-md w-4 h-4 flex items-center justify-center  rounded-full ">
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept={accepts}
                      className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                    />
                    <i className="fa-regular fa-camera text-black"></i>
                  </button>
                </div>
              )}
            </div>
          </div>

          {!showImageOnly && !isCameraIcon && (
            <div
              className={`relative flex justify-center items-center gap-2 border-orange-500 bg-orange-500 text-white rounded-md text-lg h-10 px-3 transition-all duration-200 cursor-pointer ${buttonClasses}`}
              data-tooltip-place="bottom"
              data-tooltip-id="image"
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept={accepts}
                className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
              />
              {buttonIconPosition === "left" && (
                <i className={`fa-regular ${buttonIcon}`}></i>
              )}
              {buttonLabel && (
                <span className={`text-sm font-medium ${buttonLabelClasses}`}>
                  {buttonLabel}
                </span>
              )}
              {buttonIconPosition === "right" && (
                <i className={`fa-fw ${buttonIcon}`}></i>
              )}
              <Tooltip
                id="image"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Click to choose and upload a file.
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </div>
      {showCropper && (
        <CropModal
          image={image}
          setImage={setImage}
          originalImage={originalImage}
          setOriginalImage={setOriginalImage}
          tempImage={tempImage}
          setTempImage={setTempImage}
          showCropper={showCropper}
          setShowCropper={setShowCropper}
        />
      )}
    </>
  );
};

export default ImageUploader;
