import EmployeeManage from "../../../modals/front/EmployeeManage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DeleteModal from "../../../common/DeleteModal";
import Button from "../../../form/Button";
import Search from "../../../form/Search";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteEmployee, getEmployeeList } from "../../../../services/frontService/EmployeeService";
import { toast } from "react-toastify";
import NoDataFound from "../../../common/NodataFound";
import EmployeeLoader from "../../../loaders/EmployeeLoader";
import MultiselectDropdown from "../../../form/MultiselectDropdown";
import { getDepartmentList } from "../../../../services/frontService/DepartmentService";
import { getPositionList } from "../../../../services/frontService/PositionService";
import { createFilter, handleFilterChange } from "../../../../helpers";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import Pagination from "../../../common/Pagination";
import EmployeeFilterLoader from "../../../loaders/EmployeeFilterLoader";
import Directory from "../../../../pages/front/directory/Directory";
import { employeeModal } from "../../../../redux/slice/employeeSlice";
import { Tooltip } from "react-tooltip";
import { setTitle } from "../../../../helpers/MetaTag";
import EmployeeItem from "./EmployeeItem";
import EmployeeFilterSection from "./EmployeeFilterSection";
import { useDebounce } from "../../../../helpers/useDebounce";
import CsvUploadModal from "../../../modals/front/CsvUploadModal";
import EmpAddType from "../../../modals/front/EmpAddType";
import SelectDropdownCheckbox from "../../../form/SelectDropdownCheckbox";
import useForm from "../../../../hooks/useForm";
import { companyCount } from "../../../../services/frontService/CompanyService";
import { getCount } from "../../../../redux/slice/dashboardSlice";
import Table from "../../../elements/table/Table";
import Select from "../../../form/Select";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function EmployeeSection({ children }) {
  setTitle("ORG Chart | Employees");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState([]);
  // const [sort, setSort] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [empAddType, setEmpAddtype] = useState({ isOpen: false });
  const [uploadModal, setUploadModal] = useState({ isOpen: false });
  const [keyword, setKeyword] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    currentPage: 0,
  });
  const [posList, setPosList] = useState({ loading: true, data: [] });
  const [depList, setDepList] = useState({ loading: true, data: [] });
  const empKeyword = useDebounce(keyword, 600);

  const validation = {
    sortName: { required: true, message: "Please enter sort name!" },
  };

  const { values, handleChange } = useForm({}, validation);

  const loadEmpList = useCallback(() => {
    const payload = {
      sortQuery: "+name",
      ...filters,
      keyword: empKeyword,
      ...(selectedDepartments?.length > 0 && createFilter(selectedDepartments, "departmentId")),
      ...(selectedPositions?.length > 0 && createFilter(selectedPositions, "positionId")),
    };
    setList((prev) => ({ ...prev, data: [], loading: true }));

    getEmployeeList(payload).then((res) => {
      if (res?.status === 200) {
        setList({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [filters, empKeyword, selectedDepartments?.join(""), selectedPositions?.join("")]);

  useEffect(() => {
    loadEmpList();
  }, [loadEmpList]);

  const loadCount = useCallback(() => {
    companyCount().then((res) => {
      if (res?.status === 200) {
        dispatch(getCount(res?.data));
      }
    });
  }, []);
  useEffect(() => {
    loadCount();
  }, [loadCount]);

  const loadDeplist = useCallback(() => {
    setDepList({ loading: true, data: [] });

    const payload = {
      limit: 300,
      keyword: "",
    };

    getDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        setDepList({ loading: false, data: res.docs });
        setDepartment(
          res.docs.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: false,
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => loadDeplist(), [loadDeplist]);

  const loadPoslist = useCallback(() => {
    setPosList({ loading: true, data: [] });

    const payload = {
      limit: 300,
      keyword: "",
    };

    getPositionList(payload).then((res) => {
      if (res?.status === 200) {
        setPosList({ loading: false, data: res.docs });
        setPosition(
          res.docs.map(({ _id, title, positionEmpCount }) => ({
            _id,
            name: title,
            value: _id,
            checked: false,
            count: positionEmpCount,
          }))
        );
      } else {
        setPosList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, []);

  useEffect(() => loadPoslist(), [loadPoslist]);

  const deleteFunction = () => {
    setDeleteModal((prev) => ({ ...prev, loading: true }));

    deleteEmployee({ id: deleteModal.ids }).then((res) => {
      setDeleteModal((prev) => ({ ...prev, loading: false }));

      if (res?.status === 200) {
        setList((prev) => ({
          ...prev,
          data: prev.data.filter((item) => !deleteModal.ids.includes(item._id)),
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount: pre?.employeeCount - 1,
          }))
        );
        setDeleteModal((prev) => ({ ...prev, isOpen: false }));
        loadCount();
        loadEmpList();
        toast.success(res?.message);
      } else {
        toast.error(res?.data?.message || res?.error);
      }
    });
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const convertToCsv = (data) => {
    const header = Object.keys(data[0])
      .map((header) => header.toUpperCase())
      .join(",");

    const rows = data.map((row) => {
      return Object.values(row)
        .map((value) => {
          if (typeof value === "string" && value.includes(",")) {
            return `"${value}"`;
          }
          return value;
        })
        .join(",");
    });

    return [header, ...rows].join("\n");
  };
  const downloadFile = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
  };

  const downloadCsv = () => {
    getEmployeeList({ limit: 100000, offset: 0, currentPage: 0 }).then((res) => {
      if (res?.status === 200) {
        const employees = res?.docs;
        const filteredData = employees?.map((employee) => ({
          "LAST NAME": employee.lastName,
          "FIRST NAME": employee.firstName,
          POSITION: employee?.positionDetails ? employee?.positionDetails?.title : "",
          EMAIL: employee.email,
          ROLE: (() => {
            if (employee.roleDetails?.some((role) => role.code === "COMPANY-MANAGER")) {
              return "EVALUATOR";
            }
            if (employee.roleDetails?.some((role) => role.code === "COMPANY-ADMINISTRATOR")) {
              return "ADMINISTRATOR";
            }
            return "EMPLOYEE";
          })(),
          DEPARTMENTS: employee?.departmentDetails
            ? employee?.departmentDetails?.map((department) => department?.name).join(", ")
            : "",
        }));

        const csvData = convertToCsv(filteredData);

        downloadFile(csvData, "employees.csv");
      }
    });
  };

  useEffect(() => {
    if (location?.state?.departmentIds?.length > 0 && depList?.data?.length > 0) {
      setDepartment((prevDeptList) =>
        prevDeptList.map((dept) => ({
          ...dept,
          checked: location?.state?.departmentIds?.includes(dept._id) || false,
        }))
      );
    }
  }, [location?.state?.departmentIds, depList?.data?.length]);

  useEffect(() => {
    if (location?.state?.positionsIds?.length > 0 && posList?.data?.length > 0) {
      setPosition((prevPosList) =>
        prevPosList.map((pos) => ({
          ...pos,
          checked: location?.state?.positionsIds?.includes(pos._id) || false,
        }))
      );
    }
  }, [location?.state?.positionsIds, posList?.data?.length]);

  const exportTableToPdf = () => {
    // Select the table DOM element you want to capture
    const tableElement = document.getElementById("employee-table");

    // Use html2canvas to take a screenshot of the table
    html2canvas(tableElement, {
      useCORS: true, // Allows CORS-enabled resources like images
      logging: false, // Disable console logs for debugging
      scale: 2, // Increase scale to improve image quality
    })
      .then((canvas) => {
        // Convert the canvas to an image
        const imgData = canvas.toDataURL("image/png");

        // Create a new PDF document
        const pdf = new jsPDF("p", "mm", "a4");

        // Add the image (table) to the PDF
        const imgWidth = 180; // Width of the image in the PDF (in mm)
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain the aspect ratio

        pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight); // 10, 10 are the x, y coordinates on the page

        // Save the PDF file
        pdf.save("employee-table.pdf");
      })
      .catch((error) => {
        console.error("Error generating table image for PDF:", error);
      });
  };

  const tableHeadData = [
    {
      _id: 1,
      name: " Name",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 2,
      name: " Role",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 3,
      name: " Email",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 4,
      name: "Job Title",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 5,
      name: "Departments",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 6,
      name: "Action",
      align: "right",
      isFilter: false,
      isSort: false,
    },
  ];

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "user",
        align: "left",
        cellClass: "!min-w-[80px] cursor-pointer",
        onClick: () => {
          navigate(`/employees/${item?._id}`);
        },
        data:
          (item?.firstName + " " + item?.lastName)?.length > 20
            ? (item?.firstName + " " + item?.lastName).substring(0, 20) + "..."
            : item?.firstName + " " + item?.lastName,

        image: item?.image?.url,
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-xs text-white gap-1">
              {item?.firstName && (
                <h4 className="font-normal">
                  <span className="text-white">Name: </span>
                  {item?.firstName + " " + item?.lastName}
                </h4>
              )}
              {item?.email && (
                <h4 className="font-normal">
                  <span className="text-white">Email: </span>
                  {item?.email}
                </h4>
              )}
              {item?.positionDetails && (
                <h4 className="font-normal">
                  <span className="text-white">Job Title: </span>
                  {item?.positionDetails?.title}
                </h4>
              )}
              {item?.departmentDetails && (
                <h4 className="font-normal">
                  <span className="text-white">Departments: </span>
                  {item?.departmentDetails?.map((department) => department?.name).join(", ")}
                </h4>
              )}
            </div>
          </>
        ),
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[50px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                  item?.roleDetails?.find((item) => item?.code === "COMPANY-ADMINISTRATOR")
                    ? "bg-green-100 text-green-600"
                    : item?.roleDetails?.find((item) => item?.code === "COMPANY-MANAGER")
                    ? "bg-blue-100 text-blue-600"
                    : "bg-orange-100 text-orange-600"
                }`}>
                {item?.roleDetails?.find((item) => item?.code === "COMPANY-ADMINISTRATOR")
                  ? "Administrator"
                  : "Employee"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[50px]",
        data: item?.email?.length > 20 ? (item?.email).substring(0, 20) + "..." : item?.email || "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[50px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-blue-100 text-blue-600 `}>
                {item?.positionDetails?.title || "N/A"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 5,
        type: "text",
        align: "left",
        cellClass: "!min-w-[10px]",
        data: (
          <>
            <div className={"flex items-center  justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-slate-200`}
                style={{
                  color: item?.departmentDetails[0]?.theme?.headerColor,
                }}>
                {item?.departmentDetails[0]?.name || "N/A"}
              </div>
              {item?.departmentDetails?.length > 1 && (
                <>
                  <span
                    className="text-xs text-gray-500 ml-2 cursor-pointer"
                    data-tooltip-id={`department-${item?._id}`}>
                    +{item?.departmentDetails?.length - 1} more
                  </span>
                  <Tooltip
                    id={`department-${item?._id}`}
                    place="right"
                    className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100"
                    render={() => {
                      const departmentToShow = item?.departmentDetails;

                      return (
                        <div>
                          {departmentToShow?.map((dep) => (
                            <div key={dep._id} className="text-white">
                              {dep?.name}
                            </div>
                          ))}
                        </div>
                      );
                    }}
                  />
                </>
              )}
            </div>
          </>
        ),
      },

      {
        _id: 6,
        type: "action",
        align: "left",
        cellClass: "w-[10%]",
        actionData: [
          {
            _id: 1,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            standout: true,
            onClick: () => dispatch(employeeModal({ data: item, isOpen: true })),
          },
          {
            _id: 2,
            name: "View Details",
            icon: "fa-regular fa-eye",
            standout: true,
            onClick: () => navigate(`/employees/${item?._id}`),
          },
          {
            _id: 3,
            name: "Delete",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () =>
              setDeleteModal((pre) => ({
                ...pre,
                isOpen: true,
                ids: [item?._id],
              })),
          },
        ],
        // data: (
        //   <div className="flex space-x-2">
        //     <div data-tooltip-id="delete-employee">
        //       <Button
        //         buttonIcon={"fa-regular fa-trash-can text-sm"}
        //         buttonIconPosition={"left"}
        //         buttonClasses={
        //           "w-6 h-6 !border !bg-slate-200 !border-red-600 !text-red-600 hover:!bg-red-100 !rounded-full flex items-center justify-center"
        //         }
        //         buttonFunction={(e) => {
        //           e.preventDefault();
        //           setDeleteModal((pre) => ({
        //             ...pre,
        //             isOpen: true,
        //             ids: [item?._id],
        //           }));
        //         }}
        //       />
        //       <Tooltip
        //         id="delete-employee"
        //         place="bottom"
        //         className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100"
        //         render={() => <div className="text-white">Click to delete employee.</div>}
        //       />
        //     </div>
        //     <div data-tooltip-id="edit-employee">
        //       <Button
        //         buttonIcon={"fa-regular fa-pen-to-square text-sm"}
        //         buttonIconPosition={"left"}
        //         buttonClasses={
        //           "w-6 h-6 !border !bg-slate-50 !border-black !text-black hover:!bg-blue-100 !rounded-full flex items-center justify-center"
        //         }
        //         buttonFunction={(e) => {
        //           e.preventDefault();
        //           dispatch(employeeModal({ data: item, isOpen: true }));
        //         }}
        //       />
        //       <Tooltip
        //         id="edit-employee"
        //         place="bottom"
        //         className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100"
        //         render={() => <div className="text-white">Click to edit employee details.</div>}
        //       />
        //     </div>
        //   </div>
        // ),
      },
    ],
  }));

  return (
    <>
      <Directory>
        <div className="w-full relative grid grid-cols-4 gap-6 mb-4">
          {children ? (
            <>{children}</>
          ) : (
            <>
              <div className="col-span-4 xl:col-span-3 w-full h-full pt-8 pb-12 rounded-2xl bg-white">
                <div className="border-gray-100">
                  <div className="flex justify-between items-start flex-col space-y-4 mb-4 pb-2">
                    <div className="relative flex flex-col items-center w-full">
                      <div className="relative flex flex-col w-full gap-5 pb-8 px-4 lg:pl-10 lg:pr-16 border-b border-b-slate-200">
                        <div className="relative flex flex-col w-full">
                          <div className="relative w-full">
                            <Search
                              placeholder={"Search someone by name , email"}
                              divClasses={"!rounded-full !h-12 border border-[#bfbdbe] px-4 bg-[#fffef9]"}
                              iconClasses={"border-none !text-xl text-[#807f7d]"}
                              search={(e) => {
                                // setKeywords((pre) => ({ ...pre, keyword: e }));
                                setKeyword(e);
                                setFilters((pre) => ({
                                  ...pre,
                                  offset: 0,
                                  currentPage: 0,
                                }));
                              }}
                              inputClasses={"!px-1 placeholder:text-[#ababab]"}
                            />
                          </div>
                          <div className="relative flex items-center space-x-2 mt-8">
                            <div className="relative" data-tooltip-id="departments-dropdown">
                              <MultiselectDropdown
                                buttonPlaceholder={"Departments"}
                                xPlacement={"bottom"}
                                isCheckbox={true}
                                dropdownData={department}
                                setDropdownData={setDepartment}
                                buttonArrowIconClasses={"!text-sm !right-3"}
                                buttonLabelClasses={"!text-sm !font-medium !border-black !text-left"}
                                dropdownContainer={
                                  "!w-52 lg:min-w-[250px] !left-[7px] !mt-[33px] !rounded-none relative before:absolute before:content-[''] before:left-[-15px] before:right-0 before:top-[-15px] before:rounded-[4px] before:w-[30px] before:h-[30px] before:transform before:rotate-45 before:bg-[#fff] before:translate-x-[-50%] before:shadow-[0px_2px_21px_2px_rgba(0,0,0,0.1)] before:z-[-1] before:mx-auto before:my-0 before:text-center"
                                }
                                dropdownButtonClass={
                                  "!rounded-full !bg-slate-50 !pr-14 !bg-[#eeeeee] !text-[#ababab] !px-4 font-medium"
                                }
                                buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                                selectedItems={(val) => setSelectedDepartments(val)}
                              />

                              <Tooltip
                                id="departments-dropdown"
                                place="top"
                                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                                render={() => (
                                  <div className="text-white">
                                    <div>Click to view departments list.</div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="relative" data-tooltip-id="positions-dropdown">
                              <MultiselectDropdown
                                buttonPlaceholder={"Positions"}
                                xPlacement={"bottomLeft"}
                                isCheckbox={true}
                                dropdownData={position}
                                setDropdownData={setPosition}
                                dropdownButtonClass={
                                  "!rounded-full !bg-slate-50 !pr-14 !bg-[#eeeeee] !text-[#ababab] !px-4 font-medium"
                                }
                                buttonArrowIconClasses={"!text-sm right-3"}
                                buttonLabelClasses={"!text-xs md:!text-sm !font-medium"}
                                dropdownContainer={
                                  "!w-52 lg:min-w-[250px] !mt-[33px] !rounded-none relative before:absolute before:content-[''] before:left-[-10px] before:right-0 before:top-[-15px] before:rounded-[4px] before:w-[30px] before:h-[30px] before:transform before:rotate-45 before:bg-[#fff] before:translate-x-[-50%] before:shadow-[0px_2px_21px_2px_rgba(0,0,0,0.1)] before:z-[-1] before:mx-auto before:my-0 before:text-center"
                                }
                                buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                                selectedItems={(val) => setSelectedPositions(val)}
                              />
                              <Tooltip
                                id="positions-dropdown"
                                place="top"
                                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                                render={() => (
                                  <div className="text-white">
                                    <div>Click to view position list.</div>
                                  </div>
                                )}
                              />
                            </div>
                            {/* <div className="relative" data-tooltip-id="sort-dropdown">
                              <SelectDropdownCheckbox
                                placeholder="Default"
                                isSearch={false}
                                xPlacement="bottomLeft"
                                buttonArrowIcon="fa-solid fa-chevron-down"
                                tabTextColor="text-slate-700"
                                selectName="sortName"
                                value={values.sortName || "null"}
                                onChange={handleChange}
                                isValidate={false}
                                inputClasses={"!text-slate-600 !pl-0"}
                                inputBoxClass={
                                  "!w-32 !flex !items-center !space-x-2 !h-10 !px-3 !pr-5 !border !rounded-full transition-all duration-200 !border-slate-200 !bg-slate-50 !pr-14 !bg-[#eeeeee] !text-[#ababab] !px-4 !font-medium"
                                }
                                dropdownClass={
                                  "!w-36 lg:min-w-[40px] !mt-[33px] !rounded-none relative before:absolute before:content-[''] before:left-[-10px] before:right-0 before:top-[-15px] before:rounded-[4px] before:w-[30px] before:h-[30px] before:transform before:rotate-45 before:bg-[#fff] before:translate-x-[-50%] before:shadow-[0px_2px_21px_2px_rgba(0,0,0,0.1)] before:z-[-1] before:mx-auto before:my-0 before:text-center"
                                }
                                dropdownData={[
                                  {
                                    name: "A to Z",
                                    _id: "+name",
                                  },
                                  {
                                    name: "Z to A",
                                    _id: "-name",
                                  },
                                ]}
                              />
                              <Tooltip
                                id="sort-dropdown"
                                place="top"
                                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                                render={() => (
                                  <div className="text-white">
                                    <div>Click to view the employee list in ascending or descending order.</div>
                                  </div>
                                )}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full pt-8 px-4 xl:pl-8 xl:pr-8">
                        {/* <div className=" text-[#ababab] text-base font-medium">
                          <span className="relative">({list?.totalItem || 0})</span>
                          employee
                        </div> */}
                        <div className="w-full sm:w-40">
                          <Select
                            hasPlaceholder={true}
                            placeholder={`All employeess `}
                            selectedValue={filters?.limit}
                            dropdownData={[
                              { name: `All employees`, value: list?.totalItem },
                              { name: "5 employees", value: 5 },
                              { name: "10 employees", value: 10 },
                              { name: "20 employees", value: 20 },
                              { name: "30 employees", value: 30 },
                              { name: "50 employees", value: 50 },
                              { name: "100 employees", value: 100 },
                            ]}
                            setCustomSelecedvalue={(val) => {
                              handleFilterChange("limit", val, setFilters);
                              handleFilterChange("offset", 0, setFilters);
                              handleFilterChange("currentPage", 0, setFilters);
                            }}
                            dropdownButtonClass={
                              "!rounded-full !bg-slate-50 !pr-4 !bg-[#eeeeee] !text-[#ababab] !px-4 font-medium"
                            }
                          />
                        </div>

                        <div className="relative flex md:space-x-2 gap-2 flex-row ml-auto items-center">
                          <div className="relative" data-tooltip-id="employee-add">
                            <Button
                              buttonLabel={"New"}
                              buttonIcon={"fa-regular fa-plus"}
                              buttonIconPosition={"left"}
                              buttonLabelClasses={"!font-bold !text-xs md:!text-sm"}
                              buttonClasses={
                                "!z-0 !rounded-full !text-[10px] md:!text-xs uppercase px-2 md:!px-5 md:!pr-6 h-8 md:!h-10 font-bold"
                              }
                              buttonFunction={() => setEmpAddtype({ isOpen: true })}
                              fontFamily={""}
                            />
                            <Tooltip
                              id="employee-add"
                              place="top"
                              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                              render={() => (
                                <div className="text-white">
                                  <div>Click to add employees.</div>
                                </div>
                              )}
                            />
                          </div>
                          <div className="relative" data-tooltip-id="download-csv">
                            <Button
                              buttonLabel={"Export CSV"}
                              buttonIcon={"fa-solid fa-file-csv"}
                              buttonIconPosition={"left"}
                              buttonLabelClasses={"!font-bold !text-xs md:!text-sm"}
                              buttonClasses={
                                "!z-0 !rounded-full !text-[10px] md:!text-xs uppercase px-2 md:!px-5 md:!pr-6 h-8 md:!h-10 font-bold"
                              }
                              buttonFunction={() => downloadCsv()}
                              fontFamily={""}
                            />
                            <Tooltip
                              id="download-csv"
                              place="top"
                              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                              render={() => (
                                <div className="text-white">
                                  <div>Click to download CSV.</div>
                                </div>
                              )}
                            />
                          </div>
                          {/* <div className="relative"> <Button
                        buttonLabel="Export Table to PDF"
                        buttonClasses="!rounded-full !bg-orange-500 text-white"
                        buttonFunction={exportTableToPdf}
                      /></div> */}
                          <div className="relative ml-auto xl:hidden">
                            <Button
                              buttonHasLink={false}
                              buttonIcon={"fa-regular fa-bars"}
                              buttonIconPosition={"left"}
                              buttonClasses={
                                "!p-0 !aspect-square flex items-center justify-center !rounded-md text-sm md:!text-xl !z-0 h-8 md:!h-10"
                              }
                              buttonFunction={() => setMenuOpen(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-4" id="employee-table">
                    <Table
                      tableHeadData={tableHeadData}
                      tableData={tableData}
                      isLoder={list?.loading}
                      isOrderingDisabled={true}
                      originalData={[...list?.data]}
                      setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
                      containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
                    />
                  </div>
                  {list?.pageCount > 1 && (
                    <div className="relative pt-2 mt-auto">
                      <Pagination
                        paginationClasses={"mt-4 justify-center"}
                        onPageChange={(val) => {
                          setFilters((pre) => ({
                            ...pre,
                            offset: pre?.limit * val?.selected,
                            currentPage: val?.selected,
                          }));
                        }}
                        pageCount={list?.pageCount}
                        currentPage={filters?.currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
              {menuOpen && (
                <div
                  className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
                  onClick={() => {
                    setMenuOpen(false);
                  }}></div>
              )}
            </>
          )}
          {depList?.loading ? (
            <EmployeeFilterLoader />
          ) : (
            <>
              {/* {(isMobile && menuOpen) || !isMobile ? ( */}

              <EmployeeFilterSection
                menuOpen={menuOpen}
                department={department}
                position={position}
                navigate={navigate}
                setMenuOpen={setMenuOpen}
                setPosition={setPosition}
                setDepartment={setDepartment}
                setFilters={setFilters}
              />
              {/* ) : null} */}
            </>
          )}
        </div>
        <EmployeeManage
          setList={setList}
          setEmpAddtype={setEmpAddtype}
          loadEmpList={loadEmpList}
          loadDeplist={loadDeplist}
          loadPoslist={loadPoslist}
          loadCount={loadCount}
        />
        <CsvUploadModal
          loadEmpList={loadEmpList}
          uploadModal={uploadModal}
          setEmpAddtype={setEmpAddtype}
          setUploadModal={setUploadModal}
        />
        <EmpAddType empAddType={empAddType} setEmpAddtype={setEmpAddtype} setUploadModal={setUploadModal} />
        <DeleteModal
          open={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onClose={() => {
            setDeleteModal((pre) => ({ ...pre, isOpen: false }));
          }}
          deleteHandler={deleteFunction}
          title="Delete Employee"
          subTitle="You are trying to delete a employee"
        />
      </Directory>
    </>
  );
}
