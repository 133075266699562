import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";

const PhoneNumberFormat = ({
  isValidate,
  divClasses,
  label,
  labelClasses,
  inputType,
  inputClasses,
  inputPlaceholder,
  inputValue,
  value = "",
  inputName,
  isInputGroup = false,
  inputGroupIcon,
  inputGroupPosition,
  inputGroupIconClass,
  errorType,
  errorText,
  isDisabled,
  focus = false,
  allowClear = false,
  onClear = () => {},
  onChange = () => {},
  onKeyDown = () => {},
  setPhone = () => {},
  charLimit,
  ...props
}) => {
  const [formattedValue, setFormattedValue] = useState(value);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState("");
  
  const handleInputChange = (e) => {
    let rawValue = e.target.value.replace(/[^\d]/g, ""); 
    if (charLimit && rawValue.length > charLimit) {
      setError(`Character limit of ${charLimit} exceeded`);
      return; 
    }
    setPhone(rawValue); 
    let formattedPhone = formatPhoneNumber(rawValue); 
    setError("");  
    setFormattedValue(formattedPhone); 
    onChange(formattedPhone); 
   
  };


  useEffect(() => {
    setFormattedValue(formatPhoneNumber(value));
  }, [value]);

  return (
    <div className={classNames("relative space-y-1 w-full", divClasses)}>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500",
            labelClasses
          )}
        >
          {label}
          {isValidate && (
            <span className="text-[8px] text-red-500 ml-1 absolute top-[-2px]">
              <i className="fa-solid fa-asterisk"></i>
            </span>
          )}
        </div>
      )}
      <div className={classNames("relative flex")}>
        {isInputGroup && inputGroupPosition === "left" && (
          <div
            className={classNames(
              "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-r-none bg-white text-slate-400 text-base transition-all duration-200",
              inputGroupIconClass,
              isDisabled ? "!bg-slate-200" : "",
                focused ? "border-orange-200" : "border-slate-200"
            )}
          >
            <i className={classNames("fa-fw", inputGroupIcon)}></i>
          </div>
        )}
        <div className="w-full">
          <div className="relative flex">
            {isDisabled ? (
              <input
                type={inputType}
                className={classNames(
                  "w-full h-10 rounded-md bg-white border py-0 px-3 text-sm text-slate-800 !ring-0 !outline-0 transition-all duration-200",
                  isDisabled ? "!bg-slate-100" : "",
                  inputClasses,
                  isInputGroup
                    ? inputGroupPosition === "left"
                      ? "!border-l-0 !rounded-l-none pl-2"
                      : ""
                    : ""
                )}
                placeholder={inputPlaceholder}
                name={inputName}
                value={formattedValue}
                autoComplete={"new-" + inputType}
                disabled
              />
            ) : (
              <input
                type={inputType}
                className={classNames(
                  "w-full h-10 rounded-md bg-white border py-0 px-3 text-sm text-slate-800 !ring-0 !outline-0 transition-all duration-200",
                  inputClasses,
                  isInputGroup
                    ? inputGroupPosition === "left"
                      ? "!border-l-0 !rounded-l-none pl-2"
                      : ""
                    : inputGroupPosition === "right"
                    ? "!border-r-0 !rounded-r-none pr-0"
                    : "",
                    focused ? "!border-orange-200" : "!border-slate-200"
                )}
                onChange={handleInputChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onKeyDown={onKeyDown}
                placeholder={inputPlaceholder}
                name={inputName}
                value={formattedValue}
                autoComplete={"new-" + inputType}
              />
            )}
          </div>
         
        </div>
       
      </div>
      {error && (
          <div className="text-xs font-medium text-yellow-600">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {error}
          </div>
        )}
    </div>
  );
};

export default PhoneNumberFormat;
