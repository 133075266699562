NINEBOX_API_URL = "https://api.9boxnow.com/api/v1/";
NINEBOX_BASE_URL = "https://9boxnow.com";

SUCCESSION_API_URL = "https://api.successionnow.iosx.in/api/v1/";
SUCCESSION_BASE_URL = "https://successionnow.iosx.in";


export var NINEBOX_API_URL;
export var SUCCESSION_API_URL;
export var NINEBOX_BASE_URL;
export var SUCCESSION_BASE_URL;