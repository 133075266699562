import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import io from "socket.io-client";
import { SOCKET_URL } from "../components/constant/ApiConstant";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const token_ = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");

  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);
  const [accessToken, setAccesstoken] = useState(null);

  useEffect(() => {
    if (token_) {
      setAccesstoken(token_);
    }
  }, [token_, role]);

  const connectSocket = () => {
    if (accessToken) {
      const newSocket = io(SOCKET_URL, {
        auth: {
          token: accessToken,
        },
      });
      setSocket(newSocket);
      setConnected(true);
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
      setConnected(false);
    }
  };

  const reconnectSocket = () => {
    if (!connected) {
      connectSocket();
    } else {
      disconnectSocket();
      connectSocket();
    }
  };

  const disconnect = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };

  useEffect(() => {
    if (!connected && accessToken) {
      connectSocket();
    }
  }, [connected, accessToken]);

  return (
    <SocketContext.Provider value={{ socket, reconnectSocket, disconnect }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (eventName, callback, dependencies = []) => {
  const { socket } = useContext(SocketContext);

  const memoizedCallback = useCallback(callback, dependencies);

  useEffect(() => {
    if (!socket) return;

    socket.on(eventName, memoizedCallback);

    return () => {
      socket.off(eventName, memoizedCallback);
    };
  }, [socket, eventName, memoizedCallback]);

  return socket;
};

export default SocketContext;
