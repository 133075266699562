import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import SyncDataPopup from "../components/popup/SyncDataPopup";
import { getUserList } from "../services/SsoService";

const SyncDataModalContext = createContext();

export const SyncDataModalProvider = ({ children }) => {
  const [syncDataModalOpen, setSyncDataModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [syncingLoader, setSyncingLoader] = useState(false);
  const [syncingStep, setSyncingStep] = useState({ step: "1", message: null });
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const token_ = localStorage.getItem("auth_token");

  const loadUserList = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        keyword: keyword,
        limit: limit,
        offset: offset,
      };
      if (selectedRole && selectedRole?.value !== "all") {
        payload["type[0]"] = selectedRole?.name;
      }
      if (selectedState && selectedState?.value !== "all") {
        payload["alreadySynced"] = selectedState?.value;
        payload["code"] = syncingStep?.selectedWebsite?.code;
      }
      const result = await getUserList(payload);
      if (result.status === 200) {
        setUserList(result?.docs);
        setTotalDataCount(result?.totalDocs);
        setPageCount(result?.totalPages);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [keyword, limit, offset, selectedRole, selectedState]);

  useEffect(() => {
    if (token_) {
      loadUserList();
    }
  }, [loadUserList, token_]);

  return (
    <SyncDataModalContext.Provider
      value={{
        syncDataModalOpen,
        setSyncDataModalOpen,
        userList,
        setUserList,
        loadUserList,
        loading,
        setLoading,
        syncingLoader,
        setSyncingLoader,
        syncingStep,
        setSyncingStep,
        keyword,
        setKeyword,
        limit,
        setLimit,
        offset,
        setOffset,
        totalDataCount,
        setTotalDataCount,
        startDataCount,
        setStartDataCount,
        endDataCount,
        setEndDataCount,
        noOfPage,
        setNoOfPage,
        currentPage,
        setCurrentPage,
        selectedRole,
        setSelectedRole,
        selectedState,
        setSelectedState,
        pageCount,
        setPageCount,
      }}
    >
      {children}
      {syncDataModalOpen && (
        <>
          <SyncDataPopup isOpen={syncDataModalOpen} />
        </>
      )}
    </SyncDataModalContext.Provider>
  );
};

export const useDataSyncModal = () => {
  return useContext(SyncDataModalContext);
};
