import { apiRequest } from "../../util/apiUtils";


export const subscriptionCheck = async (params) => {
    try {
      const res = await apiRequest("post", "/subscription/check", {
        body: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  
  export const subscriptionAdd = async (params) => {
    try {
      const res = await apiRequest("post", "/subscription/renewal-payment", {
        body: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  

  export const getTransactionList = async (params) => {
    try {
      const res = await apiRequest("get", "/transaction/list", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };

    