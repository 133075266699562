import { invitation_filter, role_filter, status_filter } from "../../../components/constant/constant";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import Search from "../../../components/form/Search";
import Table from "../../../components/elements/table/Table";
import { setTitle } from "../../../helpers/MetaTag";
import { createFilter, handleFilterChange } from "../../../helpers";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  changeStatus,
  deleteEmployee,
  getEmployeeList,
  superAdminDirectLogin,
} from "../../../services/adminService/EmployeeService";
import {
  administratorInviteAgain,
  getCompanyDepartmentList,
  getCompanyList,
  getPositionList,
} from "../../../services/adminService/CompanyService";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";
import { useDebounce } from "../../../helpers/useDebounce";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import MultiselectDropdown from "../../../components/form/MultiselectDropdown";
import { useNavigate } from "react-router-dom";

const EmployeeList = () => {
  const navigate = useNavigate();
  setTitle("ORG Chart Admin | Employees");
  const [selectedItems, setSelectedItems] = useState([]);
  const [companyList, setCompanyList] = useState({
    loading: false,
    data: [],
    totalItem: 0,
  });
  const [company, setCompany] = useState("");
  const [companyKeyword, setCompanyKeyword] = useState();
  const [departmentList, setDepartmentList] = useState({
    loading: false,
    data: [],
  });
  const [department, setDepartment] = useState([]);
  const [departmentKeyword, setDepartmentKeyword] = useState();
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [positionList, setPositionList] = useState({
    loading: false,
    data: [],
  });
  const [position, setPosition] = useState([]);
  const [positionKeyword, setPositionKeyword] = useState();
  const [sort, setSort] = useState("-createdAt");
  const [resetKeyword, setResetKeyword] = useState(false);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    keyword: "",
    currentPage: 0,
    status: null,
    emailVerified: null,
    type: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  // const debounceSearch = useDebounce(keyword, 400);
  const companySearch = useDebounce(companyKeyword, 400);
  const departmentSearch = useDebounce(departmentKeyword, 400);
  const positionSearch = useDebounce(positionKeyword, 400);

  // loading the employee list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      const payload = {
        sortQuery: sort,
        keyword: filters?.keyword,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        ...createFilter(filters?.status, "status"),
        ...createFilter(filters?.emailVerified, "emailVerified"),
        ...(filters?.type?.length > 0
          ? createFilter(filters?.type, "type")
          : { "type[0]": "COMPANY-ADMINISTRATOR", "type[1]": "COMPANY-MANAGER", "type[2]": "COMPANY-EMPLOYEE" }),
        ...(selectedDepartments?.length > 0 ? createFilter(selectedDepartments, "departmentId") : { departmentId: [] }),
        ...(selectedPositions?.length > 0 ? createFilter(selectedPositions, "positionId") : { positionId: [] }),
      };
      if (!Array.isArray(payload.companyId)) {
        payload.companyId = [];
      }
      if (company) {
        payload.companyId = company;
      }
      getEmployeeList(payload).then((res) => {
        if (res && res?.status === 200) {
          setList({
            loading: false,
            data: res?.docs,
            pageCount: res?.totalPages,
            totalItem: res?.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast?.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    sort,
    company,
    selectedDepartments?.length > 0 && selectedDepartments,
    selectedPositions?.length > 0 && selectedPositions,
  ]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  //loading company list && search >>>>>
  useEffect(() => {
    let payload = { type: "COMPANY" };
    if (companySearch) {
      payload.keyword = companySearch;
    }
    setCompanyList((pre) => ({ ...pre, data: [], loading: true }));
    getCompanyList(payload).then((res) => {
      if (res && res?.status === 200) {
        setCompanyList((pre) => ({
          ...pre,
          data: res?.docs || [],
          loading: false,
          totalItem: res?.totalDocs,
        }));
      } else {
        setCompanyList((pre) => ({ ...pre, data: [], loading: true }));
        toast?.error(res?.message || "Failed to fetch Company data");
      }
    });
  }, [companySearch, company]);

  const handleSelectCompany = ({ target }) => {
    const selectedValue = target.value;
    setCompany(selectedValue || "");
    setFilters((pre) => ({
      ...pre,
      keyword: "",
      offset: 0,
      currentPage: 0,
    }));
  };
  const handleCompanySearch = (keyword) => {
    setCompanyKeyword(keyword);
  };

  //loading department list && search >>>>>
  const loadDeplist = useCallback(() => {
    setDepartmentList({ loading: true, data: [] });

    const payload = {
      limit: 999999,
      ...(departmentSearch && departmentSearch),
    };

    getCompanyDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        setDepartmentList({ loading: false, data: res.docs });
        setDepartment(
          res.docs.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: false,
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepartmentList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [departmentSearch]);

  useEffect(() => loadDeplist(), [loadDeplist]);

  const handleDepartmentSearch = (keyword) => {
    setDepartmentKeyword(keyword);
  };

  //loading position list && search >>>>>
  const loadPoslist = useCallback(() => {
    setPositionList({ loading: true, data: [] });

    const payload = {
      limit: 999999,
      ...(positionSearch && positionSearch),
    };

    getPositionList(payload).then((res) => {
      if (res?.status === 200) {
        setPositionList({ loading: false, data: res.docs });
        setPosition(
          res?.docs
            ?.filter(({ title }) => title !== null)
            ?.map(({ _id, title, positionEmpCount }) => ({
              _id,
              name: title,
              value: _id,
              checked: false,
              count: positionEmpCount,
            }))
        );
      } else {
        setPositionList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [positionSearch]);

  useEffect(() => loadPoslist(), [loadPoslist]);

  const handlePositionSearch = (keyword) => {
    setPositionKeyword(keyword);
  };
  //Reset
  const handleReset = () => {
    setResetKeyword(true);
    setSelectedPositions([]);
    setSelectedDepartments([]);
    setCompany("");
    setPositionKeyword("");
    setCompanyKeyword("");
    setFilters((pre) => ({
      ...pre,
      keyword: "",
      offset: 0,
      currentPage: 0,
    }));
  };
  // Status Change >>>>>>>>>>
  const handleStatusChange = async (glossaryItem) => {
    const newStatus = glossaryItem?.status === 1 ? 2 : 1;
    const res = await changeStatus({
      id: [glossaryItem?._id],
      status: newStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) => (item?._id === glossaryItem?._id ? { ...item, status: newStatus } : item)),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };
  // Delete
  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteEmployee({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter((item) => !deleteModal?.ids.includes(item._id));

          const totalItem = pre?.totalItem - (updatedData?.length === 0 && filters?.currentPage > 0 ? 1 : 0);

          return {
            ...pre,
            data: updatedData,
            totalItem,
          };
        });
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        loadList();
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };
  const inviteAgain = (values) => {
    administratorInviteAgain({
      emails: [values?.email],
      type: values?.roleDetails?.find((role) => role?.code === "COMPANY-ADMINISTRATOR")
        ? "COMPANY_ADMINISTRATOR"
        : values?.roleDetails?.some((role) => role?.code === "COMPANY-MANAGER")
        ? "COMPANY_MANAGER"
        : "",
    }).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.message);
      } else {
        toast.info(res?.message);
      }
    });
  };

  const onSubmit = async (values) => {
    const adminToken = localStorage.getItem("auth_token");
    try {
      const res = await superAdminDirectLogin(
        {
          id: values?._id,
          email: values?.email,
          uuid: values?.uuid,
          type: values?.roleDetails?.find((role) => role?.code === "COMPANY-MANAGER")
            ? "evaluatorLogin"
            : "administratorLogin",
        },
        adminToken
      );
      if (res?.status === 201) {
        toast.info(res?.message);
        localStorage.setItem("auth_token", res?.accessToken);
        localStorage.setItem(
          "role",
          res?.data?.roleData?.find((role) => role?.code === "COMPANY-MANAGER")
            ? "COMPANY-MANAGER"
            : "COMPANY-ADMINISTRATOR"
        );
        localStorage.setItem(
          "adminData",
          JSON.stringify({
            _id: res?.data?._id,
            email: res?.data?.email,
            uuid: res?.data?.uuid,
            authToken: adminToken,
            login: "Administrator-login",
          })
        );
        navigate("/");
      } else {
        toast.info(res?.data?.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
    }
  };
  const tableHeadData = [
    {
      _id: 1,
      name: " Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },
    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 3,
      name: "Created Date",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-createdAt");
        } else {
          setSort("createdAt");
        }
      },
    },
    {
      _id: 4,
      name: "Role",
      align: "left",
      isSort: false,
      isFilter: true,
      isFilterSearch: true,
      filterData: role_filter,
      onFilter: (data) => {
        handleFilterChange(
          "type",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0, currentPage: 0 }));
      },
    },
    {
      _id: 5,
      name: "Company Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-companyName");
        } else {
          setSort("companyName");
        }
      },
    },
    {
      _id: 6,
      name: "Invitation Accepted",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: invitation_filter,
      onFilter: (data) => {
        handleFilterChange(
          "emailVerified",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0, currentPage: 0 }));
      },
    },
    {
      _id: 7,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0, currentPage: 0 }));
      },
    },
    {
      _id: 8,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];
  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data:
          (item?.firstName + " " + item?.lastName)?.length > 20
            ? (item?.firstName + " " + item?.lastName).substring(0, 20) + "..."
            : item?.firstName + " " + item?.lastName,

        image: item?.image?.url,
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-white gap-1">
              {item?.firstName && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Name: </span>
                  {(item?.firstName + " " + item?.lastName)?.length > 20
                    ? (item?.firstName + " " + item?.lastName).substring(0, 20) + "..."
                    : item?.firstName + " " + item?.lastName}
                </h4>
              )}
              {item?.email && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Email: </span>
                  {item?.email}
                </h4>
              )}
            </div>
          </>
        ),
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email?.length > 20 ? (item?.email).substring(0, 20) + "..." : item?.email || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt ? moment(item?.createdAt).format("MM-DD-YYYY") : "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                  item?.roleDetails?.find((item) => item?.code === "COMPANY-ADMINISTRATOR")
                    ? "bg-green-100 text-green-600"
                    : item?.roleDetails?.find((item) => item?.code === "COMPANY-MANAGER")
                    ? "bg-blue-100 text-blue-600"
                    : "bg-orange-100 text-orange-600"
                }`}>
                {item?.roleDetails?.find((item) => item?.code === "COMPANY-ADMINISTRATOR")
                  ? "Administrator"
                 
                  : "Employee"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 5,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-blue-100 text-blue-600 `}>
                {item?.companyDetails?.companyName}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 6,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                  item?.roleDetails?.find((role) => role?.code === "COMPANY-ADMINISTRATOR") ||
                  item?.roleDetails?.find((role) => role?.code === "COMPANY-MANAGER")
                    ? item?.emailVerified === 1
                      ? "bg-green-100 text-green-600"
                      : "bg-red-100 text-red-600"
                    : "bg-blue-100 text-blue-600"
                }`}>
                {item?.roleDetails?.find((role) => role?.code === "COMPANY-ADMINISTRATOR") ||
                item?.roleDetails?.find((role) => role?.code === "COMPANY-MANAGER")
                  ? item?.emailVerified === 1
                    ? "Yes"
                    : "No"
                  : "N/A"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 7,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 8,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          ...(item?.roleDetails?.find((role) => role?.code === "COMPANY-ADMINISTRATOR") ||
          item?.roleDetails?.find((role) => role?.code === "COMPANY-MANAGER")
            ? [
                {
                  _id: 1,
                  name: item?.roleDetails?.find((role) => role?.code === "COMPANY-MANAGER")
                    ? "Employee Login"
                    : "Administrator Login",
                  icon: "fa-solid fa-right-to-bracket",
                  standout: false,
                  onClick: () => onSubmit(item),
                },
              ]
            : []),
          ...((item?.emailVerified === 2 &&
            item?.roleDetails?.find((role) => role?.code === "COMPANY-ADMINISTRATOR")) ||
          (item?.emailVerified === 2 && item?.roleDetails?.find((role) => role?.code === "COMPANY-MANAGER"))
            ? [
                {
                  _id: 1,
                  name: "Invite Again",
                  icon: "fa-solid fa-calendar-circle-user",
                  standout: false,
                  onClick: () => inviteAgain(item),
                },
              ]
            : []),
          {
            _id: 2,
            name: "Delete User",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () =>
              setDeleteModal((pre) => ({
                ...pre,
                isOpen: true,
                ids: [item?._id],
              })),
          },
        ],
      },
    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">Employees</div>

        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-3">
          <div className="w-full sm:w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "All Items", value: list?.totalItem },
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) => {
                handleFilterChange("limit", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
          <div className="w-full sm:w-72">
            <Search
              placeholder={"Search here"}
              search={(val) => {
                handleFilterChange("keyword", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
              resetKeyword={resetKeyword}
              setResetKeyword={setResetKeyword}
            />
          </div>
          <div className="relative w-full sm:w-60">
            <SelectDropdownSearch
              placeholder={"All companies"}
              isSearch={true}
              xPlacement={"bottom"}
              buttonArrowIcon={"fa-solid fa-chevron-down"}
              tabTextColor={"text-slate-700"}
              loading={companyList?.loading}
              value={company}
              dropdownButtonClass="!px-3 !border-gray-200 !w-full"
              dropdownData={companyList?.data?.map((item) => ({
                name: item.companyName,
                _id: item._id,
              }))}
              onSearch={handleCompanySearch}
              onChange={handleSelectCompany}
            />
          </div>
          <div className="relative w-full sm:w-60">
            <MultiselectDropdown
              buttonPlaceholder={"All positions"}
              xPlacement={"bottomLeft"}
              isCheckbox={true}
              dropdownData={position}
              setDropdownData={setPosition}
              buttonArrowIconClasses={"!text-xs  !right-2"}
              buttonLabelClasses={"!text-slate-500"}
              dropdownContainer={"!w-full lg:min-w-[200px] "}
              dropdownButtonClass={"!bg-white !border-slate-200"}
              buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
              selectedItems={(val) => setSelectedPositions(val)}
            />
          </div>
          <div className="relative w-full sm:w-60">
            <MultiselectDropdown
              buttonPlaceholder={"All departments"}
              xPlacement={"bottomLeft"}
              isCheckbox={true}
              isSearch={true}
              dropdownData={department}
              setDropdownData={setDepartment}
              buttonArrowIconClasses={"!text-xs  !right-2"}
              buttonLabelClasses={"!text-slate-500"}
              dropdownContainer={"!w-full lg:min-w-[200px] "}
              dropdownButtonClass={"!bg-white !border-slate-200"}
              selectedItems={(val) => setSelectedDepartments(val)}
              onSearch={handleDepartmentSearch}
            />
          </div>
          <div data-tooltip-id="reset-employeeList" data-tooltip-place="top">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-regular fa-rotate-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => handleReset()}
            />
            <Tooltip
              id="reset-employeeList"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => <div className="text-white">Please click to reset.</div>}
            />
          </div>
        </div>
      </div>

      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={deletefunction}
      />
    </div>
  );
};

export default EmployeeList;
