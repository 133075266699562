import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../form/Button';
import Input from '../../../form/Input';
import { subscriptionAdd } from '../../../../services/frontService/PaymentService';
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { toast } from 'react-toastify';

const EditLastPayment = ({
  data,
  isShow,
  setIsShow = () => {},
  loadTransactions = () => {},
}) => {
  const flatPicker = useRef(null);
  const [price, setPrice] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const closeDetails = () => {
    setPrice("");
    setStartDate("");
    setEndDate("");
    setIsShow({ open: false, data: null });
  };

  useEffect(() => {
    if (isShow?.open && isShow?.data?._id) {
      setPrice(isShow?.data?.price);
      setStartDate(
        moment(new Date(isShow?.data?.startDate)).format("YYYY-MM-DD")
      );
      setEndDate(moment(new Date(isShow?.data?.endDate)).format("YYYY-MM-DD"));
    }
  }, [isShow, isShow?.data?._id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await subscriptionAdd({
        startDate: startDate,
        price: price,
        renewalDate: endDate,
        companyId: isShow?.data?.companyId,
        subscriptionId: isShow?.data?.subscriptionId,
        transactionId: isShow?.data?._id,
        type: "edit",
      });
      if (result.status && result.status === 200) {
        toast.success(result.message);
        setIsShow({
          data: null,
          isOpen: false,
        });
        loadTransactions();
        setLoading(false);
      } else {
        toast.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };


  return (
    <div>
      <div className="py-3 px-6 border border-slate-200 rounded relative z-[1] mt-2">
        <Button
          buttonClasses={
            "!p-0 !flex !items-center !justify-center !text-rose-500 border border-slate-200 shadow rounded-full !w-8 !h-8 !text-lg ml-auto mr-0 mt-0 mb-3 !bg-white z-[2]"
          }
          buttonType={"button"}
          buttonIcon={"fa-regular fa-xmark"}
          buttonIconPosition={"left"}
          buttonHasLink={false}
          buttonFunction={() => closeDetails()}
        />
        <div className="items-end gap-4 mb-4">
          <div className="grid sm:grid-cols-2 gap-4 relative">
            <div className="relative w-full">
              <Flatpickr
                ref={flatPicker}
                className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                placeholder={"Start Date"}
                value={startDate}
                options={{
                  dateFormat: "Y-m-d",
                  //minDate: new Date(),
                  // timezone: "America/Denver",
                }}
                onChange={(selectedDates, dateStr, instance) => {
                  setStartDate(dateStr);
                }}
              />
            </div>
            <div className="relative w-full">
              <Flatpickr
                ref={flatPicker}
                className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                placeholder={"End Date"}
                value={endDate}
                options={{
                  dateFormat: "Y-m-d",
                  //minDate: new Date(),
                  // timezone: "America/Denver",
                }}
                onChange={(selectedDates, dateStr, instance) => {
                  setEndDate(dateStr);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex items-start gap-4 relative">
          <div className="relative w-full">
            <Input
              label={"Price"}
              inputType={"number"}
              labelClasses={""}
              inputPlaceholder={"Enter price"}
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              isRequired={true}
              inputName="price"
              errorType={isShow?.data?.price ? "info" : ""}
              errorText={
                isShow?.data?.price
                  ? "Current Price: $" + isShow?.data?.price
                  : ""
              }
            />
          </div>
        </div>
        <div className="relative flex justify-center mb-3 mt-6 gap-4">
          <Button
            buttonClasses="!bg-orange-500 !px-3 !h-8 !text-sm !gap-1"
            buttonLabelClasses=""
            buttonType={"button"}
            buttonIcon={
              loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
            }
            buttonIconPosition={"left"}
            buttonLabel={"Save"}
            buttonFunction={(e) => {
              if (isShow?.open && isShow?.data?._id) {
                onSubmit(e);
              }
            }}
            buttonHasLink={false}
            buttonDisabled={isShow?.open ? loading : true}
            isLoaderDisabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EditLastPayment;