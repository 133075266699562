import { setProfile } from "../../../redux/slice/profileSlice,";
import { formValidate } from "../../../helpers/formValidate";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ColorModal from "../../shared/ColorModal";
import { hexToHsv, hexToRgb } from "../../../helpers";
import { useColor } from "react-color-palette";
import Radio from "../../form/Radio";
import RecursiveSelect from "../../form/RecursiveSelect";
import {
  departMentAdd,
  departMentEdit,
  getHexColor,
} from "../../../services/frontService/DepartmentService";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";

export default function DepartmentMange({
  tree,
  style,
  setList,
  isDepModal,
  loadList = () => {},
  setDepModal = () => {},
  setListupdate = () => {},
  loadDepartmentList = () => {},
  setSelectedDepartments = () => {},
  setDepartment = () => {},
  loadCount = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hexColor, setHexColor] = useState("");
  const [isTree, setIsTree] = useState("department");
  const [parentId, setParentId] = useState(null);

  
  const data = isDepModal?.data;
  const validation = {
    name: { required: true, message: "Please enter department name!" },
    colorId: { required: true, message: "Please select a color!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  useEffect(() => {
    if (data) {
      const color = hexColor?.data?.find(
        (color) =>
          color?.code === data?.theme?.headerColor ||
          color?.code === data?.borderColor
      );

      setFieldsValue({
        name: data?.name,
        colorId: color ? color._id : null,
      });

      setIsTree(data?.parentId ? "sub-department" : "department");
      setParentId(data?.parentId ? data?.parentId : null);
    }
  }, [data, hexColor?.data]);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      let payload = {
        name: values?.name,
        headerColor: hexColor?.data?.find(
          (color) => color?._id === values?.colorId
        )?.code,
      };

      if (isTree === "sub-department") {
        if (!parentId) {
          toast.info("Please select a department !");
          return;
        } else {
          payload.parentId = parentId;
        }
      }

      const response = data?._id
        ? await departMentEdit({
            ...payload,
            id: data?._id,
          })
        : await departMentAdd(payload);

      setLoading(false);

      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        loadList();
        loadDepartmentList();
        setDepModal((pre) => ({ ...pre, isOpen: false }));
        setSelectedDepartments((prevDepartments) => [
          ...prevDepartments,
          response?.data?._id,
        ]);
        setDepartment((prevDepartments) => [
          ...prevDepartments,
          {
            _id: response?.data?._id,
            name: response?.data?.name,
            value: response?.data?._id,
            checked: true,
          },
        ]);
        style === "list"
          ? setList((prev) => ({
              ...prev,
              data:
                response?.status === 200
                  ? prev.data.map((item) =>
                      item?._id === response?.data?._id ? response?.data : item
                    )
                  : [response?.data, ...prev.data],
            }))
          : setListupdate((pre) => pre + 1);

        dispatch(
          setProfile((pre) => ({
            ...pre,
            departmentCount:
              response?.status === 201
                ? pre?.departmentCount + 1
                : pre?.departmentCount,
          }))
        );
      } else {
        toast.error(response?.data?.message || response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const loadColorList = useCallback(() => {
    setHexColor({ loading: true, data: [] });

    getHexColor({ limit: 10000 }).then((res) => {
      if (res?.status === 200) {
        setHexColor({ loading: false, data: res.docs });
      } else {
        setHexColor((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadColorList();
  }, [loadColorList]);

  const radioOptions = [
    { label: "Department", value: "department" },
    { label: "Sub Department", value: "sub-department" },
  ];

  const mapTreeData = (data) => {
    return data?.map((item) => ({
      name: item?.name,
      _id: item?._id,
      children: item?.children ? mapTreeData(item.children) : [],
    }));
  };

  return (
    <>
      <Modal
        title={data?._id ? "Edit Department" : "Add Department"}
        size="lg"
        open={isDepModal?.isOpen}
        onClose={() => {
          setDepModal((pre) => ({ ...pre, isOpen: false }));
        }}
        modaltitleClasses={"!text-slate-200 text-[15px]"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="relative col-span-12">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-building-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"Name"}
              inputPlaceholder={"Enter department name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium text-slate-500"}
              inputType={"text"}
              value={values.name}
              inputName={"name"}
              onChange={handleChange}
              isValidate={true}
              charLimit={200}
              {...formValidate(errors, "name")}
            />
          </div>
          {isTree === "sub-department" && (
            <div className="relative col-span-12 mt-1">
              <RecursiveSelect
                isValidate
                label="Deparment"
                placeholder="Choose a deparment"
                value={parentId}
                onChange={(val) => setParentId(val?._id)}
                dropdownData={mapTreeData(tree?.data)}
              />
            </div>
          )}
          {/* <div className="relative col-span-6 space-y-2 pt-2">
            <p className="text-sm font-medium text-slate-500">
              Selected Color{" "}
            </p>
            <div className="w-full flex items-center border gap-1 rounded-md p-1">
              <div
                style={{ background: color?.hex }}
                className="relative w-10 h-10 flex-shrink-0 rounded-md shadow border border-slate-300 cursor-pointer"
                onClick={() => setColorModal(true)}
              ></div>
              <input
                type="text"
                value={color?.hex || ""}
                onChange={(e) => {
                  const newHex = e.target.value;
                  setColor({
                    hex: newHex,
                    rgb: hexToRgb(newHex),
                    hsv: hexToHsv(newHex),
                  });
                  setIsValid(validateHex(newHex));
                }}
                className={`text-slate-700 font-semibold border-none focus:ring-1 focus:ring-orange-300 rounded w-full ${
                  !isValid ? "border-red-500" : ""
                }`}
                placeholder="Enter color code"
              />
            </div>
            {!isValid && (
              <div className="text-xs font-medium text-orange-500 pl-2">
                <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
                Invalid color code. Please enter a valid hex code.
              </div>
            )}
          </div> */}
          <div>
            <SelectDropdownSearch
              label="Color"
              placeholder="Choose a color"
              isSearch={false}
              xPlacement="bottom"
              buttonArrowIcon="fa-solid fa-chevron-down"
              tabTextColor="text-slate-700"
              selectName="colorId"
              // loading={posList?.loading}
              value={values?.colorId || "null"}
              onChange={handleChange}
              isValidate={true}
              inputClasses={"!text-slate-600"}
              dropdownButtonClass="px-3 py-2 border rounded-md w-full focus:ring focus:ring-blue-200"
              {...formValidate(errors, "colorId")}
              isColor={true}
              dropdownData={[
                ...(Array.isArray(hexColor?.data)
                  ? hexColor?.data?.map(({ _id, name, code }) => ({
                      name: name,
                      _id: _id,
                      color: code,
                    }))
                  : []),
                // {
                //   name: "Other",
                //   _id: "other",
                // },
              ]}
            />
          </div>
          <div
            className={`flex items-center ${
              style == "tree" ? "justify-between" : "justify-end"
            } mt-4`}
          >
            {style == "tree" && (
              <div className="flex gap-3">
                {radioOptions.map((option) => (
                  <Radio
                    key={option?.value}
                    radioLabel={option?.label}
                    radioValue={option?.value}
                    onChange={(e) => setIsTree(e.target.value)}
                    isChecked={isTree === option?.value}
                    radioName="isTree"
                  />
                ))}
              </div>
            )}
            <Button
              loading={loading}
              buttonLabel={
                data?._id
                  ? loading
                    ? "Saving..."
                    : "Save"
                  : loading
                  ? "Adding..."
                  : "Add"
              }
              isDisable={loading ? true : false}
              buttonIcon={
                data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
              }
              buttonIconPosition={"left"}
              buttonType="submit"
              buttonClasses="!py-2 !px-2 rounded-lg text-white !h-auto bg-orange-500 hover:bg-orange-600"
            />
          </div>
        </form>
      </Modal>

      {/* <ColorModal
        color={color}
        isOpen={colorModal}
        setColor={setColor}
        onClose={() => setColorModal(false)}
      /> */}
    </>
  );
}
