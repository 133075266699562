import React from "react";

const TableSkeleton = ({ tableImage = true }) => {
  return (
    <div className="space-y-4 p-1 pt-2">
      {[...Array(7)].map((_, index) => (
        <div
          key={index}
          className="skeleton-loader w-full h-10 bg-slate-200 relative overflow-hidden rounded-md shadow-md">
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-30 animate-shine"></div>
          <div className="flex items-center h-full px-6 space-x-4">
            {tableImage && <div className="w-8 h-8 bg-slate-300 rounded-full flex-shrink-0"></div>}

            <div className="flex-1 space-y-2">
              <div className="h-6 bg-slate-300 rounded w-4/4"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableSkeleton;
