import { useEffect, useRef, useState } from "react";
import { classNames } from "../../../../helpers/classNames";
import Button from "../../../form/Button";

export default function EmployeeFilterSection({
  menuOpen,
  position,
  navigate,
  department,
  setMenuOpen = () => {},
  setPosition = () => {},
  setDepartment = () => {},
  setFilters = () => {},
}) {
  const [height, setHeight] = useState(0);
  const [offset, setOffset] = useState(104); // Default offset
  const myDivRef = useRef(null);
  const anotherDivRef = useRef(null);
  const anotherDivRef2 = useRef(null);

  useEffect(() => {
    // Adjust offset based on device resolution
    const updateOffset = () => {
      if (window.innerWidth < 768) {
        setOffset(50);
      } else if (window.innerWidth < 1024) {
        setOffset(80);
      } else {
        setOffset(104);
      }
    };

    // Initial calculation
    updateOffset();

    // Listen for window resize events
    window.addEventListener("resize", updateOffset);

    // Cleanup event listener
    return () => window.removeEventListener("resize", updateOffset);
  }, []);

  useEffect(() => {
    // Get the height of the myDiv element when the component mounts
    if (myDivRef.current) {
      setHeight(myDivRef.current.clientHeight || 0);
    }
  }, []);

  useEffect(() => {
    // Dynamically adjust height when refs are mounted
    if (anotherDivRef.current && anotherDivRef2.current) {
      const computedHeight = `${height / 2 - offset}px`;
      anotherDivRef.current.style.height = computedHeight;
      anotherDivRef2.current.style.height = computedHeight;
    }
  }, [height, offset, anotherDivRef.current, anotherDivRef2.current]);

  return (
    <div className="relative" ref={myDivRef}>
      <div
        className={classNames(
          "fixed xl:absolute py-0 top-14 xl:top-0 right-0 h-[calc(100vh-56px)] xl:h-full w-96 xl:w-full flex flex-col px-0 col-span-1 xl:space-y-6 xl:rounded-t-3xl flex-shrink-0 overflow-auto xl:overflow-['unset'] bg-white xl:bg-transparent divide-y divide-slate-200 xl:translate-x-0 transition-all duration-300",
          menuOpen ? "translate-x-0" : "translate-x-full"
        )}>
        <div className="bg-white xl:rounded-2xl py-3 px-3 transition-all duration-300 hover:shadow-md w-full flex flex-col flex-shrink flex-grow">
          <h3 className="flex items-center justify-start gap-2 font-semibold text-xl text-gray-800 mb-1 pb-2 pl-2 flex-shrink-0">
            {/* <i className="fa-regular fa-building-user text-orange-500"></i> */}
            Departments
          </h3>
          {department?.length > 0 ? (
            <div
              className="flex flex-col overflow-y-scroll scrollbar pr-4 xl:pr-0 flex-shrink flex-grow"
              ref={anotherDivRef}>
              {department?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      offset: 0,
                      currentPage: 0,
                    }));
                    setDepartment(
                      department?.map((data) =>
                        data?.value === item?.value
                          ? {
                              ...data,
                              checked: !data?.checked,
                            }
                          : data
                      )
                    );
                  }}
                  className={`relative flex justify-between items-center py-2 px-2 2xl:px-0 2xl:pl-2 2xl:pr-3 rounded-md cursor-pointer transition-all duration-200 hover:bg-orange-100 hover:text-orange-600 text-gray-700 ${
                    item?.checked ? "bg-orange-100 text-orange-600" : "hover:bg-gray-50"
                  }`}>
                  <div className="flex gap-2 items-center justify-center  font-normal text-sm uppercase tracking-wide">
                    {item?.checked && <i className="fa-solid fa-check text-[11px]" />}
                    {item?.name?.substring(0, 20) + (item?.name?.length > 20 ? "..." : "")}
                  </div>
                  <div className="font-semibold text-sm text-gray-600">{item?.count}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-500 text-sm">No departments available.</div>
          )}
        </div>
        <div className="bg-white xl:rounded-2xl py-3 px-3 transition-all duration-300 hover:shadow-md w-full flex flex-col flex-shrink flex-grow">
          <h3 className="flex items-center justify-start gap-2 font-semibold text-xl text-gray-800 mb-1 pb-2 pl-2 flex-shrink-0">
            {/* <i className="fa-regular fa-users text-orange-500"></i> */}
            Positions
          </h3>
          {position?.length > 0 ? (
            <div
              className={classNames("flex flex-col scrollbar pr-4 xl:pr-0 overflow-y-scroll flex-grow flex-shrink")}
              ref={anotherDivRef2}>
              {position?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      offset: 0,
                      currentPage: 0,
                    }));
                    setPosition(
                      position?.map((data) =>
                        data?.value === item?.value
                          ? {
                              ...data,
                              checked: !data?.checked,
                            }
                          : data
                      )
                    );
                  }}
                  className={`relative flex justify-between items-center py-2 px-2 2xl:px-0 2xl:pl-2 2xl:pr-3 rounded-md cursor-pointer transition-all duration-200 hover:bg-orange-100 hover:text-orange-600 text-gray-700 ${
                    item?.checked ? "bg-orange-100 text-orange-600" : "hover:bg-gray-50"
                  }`}>
                  {/* <div className="font-normal text-sm uppercase tracking-wide">{item?.name}</div> */}
                  <div className="font-normal text-sm uppercase tracking-wide">
                    {item?.checked && <i className="fa-solid fa-check text-[11px] mr-2" />}
                    {item?.name?.substring(0, 20) + (item?.name?.length > 20 ? "..." : "")}
                  </div>
                  <div className="font-semibold text-sm text-gray-600">{item?.count}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-500 text-sm">No positions available.</div>
          )}
        </div>
        {menuOpen && (
          <div className="absolute top-2 right-6 z-50">
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses={"!p-0 !aspect-square flex items-center justify-center !rounded-full !text-xl !z-30"}
              buttonFunction={() => setMenuOpen(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
