import React, { useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import {
  positionAdd,
  positionEdit,
} from "../../../services/frontService/PositionService";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../redux/slice/profileSlice,";

export default function PositionManage({
  setList,
  isPositionModal,
  loadPosList = () => {},
  setPositionModal = () => {},
  setSelectedItem = () => {},
  setUpdate = () => {},
  loadCount = () => {}
  
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const data = isPositionModal?.data;
  const validation = {
    title: { required: true, message: "Please enter job title !" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        title: data?.name,
        description: data?.desc,
      });
    }
  }, [data]);


  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        title: values?.title,
        description: values?.description,
      };

      const response = data?._id
        ? await positionEdit({
            ...payload,
            id: data?._id,
          })
        : await positionAdd(payload);

      setLoading(false);

      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        setPositionModal((prev) => ({ ...prev, isOpen: false }));
        loadPosList();
        setSelectedItem({
          _id: response?.data?._id,
          name: response?.data?.name,
        });
        dispatch(
          setProfile((pre) => ({
            ...pre,
            positionCountEmpty:
              response?.status === 201
                ? pre?.positionCountEmpty + 1
                : pre?.positionCountEmpty,
          }))
        );
        loadCount();
        setList((prev) => ({
          ...prev,
          data:
            response?.status === 200
              ? prev.data.map((item) =>
                  item?._id === response?.data?._id ? response?.data : item
                )
              : [response?.data, ...prev.data],
        }));
      } else {
        toast.error(response?.data?.message || response.message);
      }
    } catch (error) {
      setLoading(false);
      // toast.error("Something went wrongggg!");
    }
  };

  return (
    <Modal
      title={data?._id ? "Edit Position" : "Add Position"}
      size="lg"
      open={isPositionModal?.isOpen}
      onClose={() => {
        setPositionModal((prev) => ({ ...prev, isOpen: false }));
        setUpdate(true);
      }}
      modaltitleClasses={"!text-slate-200 text-[15px]"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="relative col-span-12">
          <Input
            isInputGroup={true}
            inputGroupIcon={"fa-light fa-users text-slate-400"}
            inputGroupPosition={"left"}
            label={"Job Title"}
            inputPlaceholder={"Enter Job Title"}
            inputClasses={"pl-2"}
            labelClasses={"!text-sm !font-medium"}
            inputType={"title"}
            value={values.title}
            onChange={handleChange}
            inputName={"title"}
            isValidate={true}
            charLimit={200}
            {...formValidate(errors, "title")}
          />
        </div>
        <div className="relative col-span-12 mt-1">
          <TextAreaAutoSize
            label={"Description"}
            inputValue={values?.description}
            inputName={"description"}
            inputPlaceholder="Describe the position"
            onChange={handleChange}
          />
        </div>

        <div className="flex items-center justify-end mt-4">
          <Button
            loading={loading}
            buttonLabel={
              data?._id
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                ? "Adding..."
                : "Add"
            }
            isDisable={loading ? true : false}
            buttonIcon={
              data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
            }
            buttonIconPosition={"left"}
            buttonType="submit"
            buttonClasses={
              "!py-2 !px-2 rounded-lg text-white !h-auto bg-orange-500 hover:bg-orange-600"
            }
          />
        </div>
      </form>
    </Modal>
  );
}
