import { employeeEdit } from "../../../services/frontService/EmployeeService";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import { formValidate } from "../../../helpers/formValidate";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import ImageUploader from "../../form/ImageUploader";
import React, { useCallback, useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import Modal from "../../elements/Modal";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import Input from "../../form/Input";
import Radio from "../../form/Radio";
import { useDebounce } from "../../../helpers/useDebounce";
import { getDepartmentList } from "../../../services/frontService/DepartmentService";
import { getPositionList } from "../../../services/frontService/PositionService";
import MultiselectDropdown from "../../form/MultiselectDropdown";
import PositionManage from "./PositionManage";
import DepartmentMange from "./DepartmentMange";
import PhoneNumberFormat from "../../form/PhoneNumberFormat";
import ConfirmModal from "../../common/ConfirmModal";

export default function EmployeeEdit({
  details,
  editmodalOpen,
  setDetails = () => {},
  setEditModalOpen = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("COMPANY-EMPLOYEE");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [posList, setPosList] = useState({ loading: false, data: [] });
  const [depList, setDepList] = useState({ loading: false, data: [] });
  const [department, setDepartment] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPositionModal, setPositionModal] = useState({
    data: {},
    isOpen: false,
  });
  const [isDepModal, setDepModal] = useState({
    data: {},
    isOpen: false,
  });
  const [keywords, setKeywords] = useState({
    deptKeyword: "",
    posKeyword: "",
  });

  const deptKeyword = useDebounce(keywords.deptKeyword, 400);
  const posKeyword = useDebounce(keywords.posKeyword, 400);

  const data = details?.data && details?.data;

  const validation = {
    firstName: { required: true, message: "Please enter  first name!" },
    lastName: { required: true, message: "Please enter  last name!" },
    email: { required: true, message: "Please enter  email!" },
    positionId: {
      required:
        userType !== "COMPANY-ADMINISTRATOR" && !selectedItem?._id
          ? true
          : false,
      message: "Please choose a job title !",
    },
    departmentId: {
      required:
        userType !== "COMPANY-ADMINISTRATOR" && selectedDepartments?.length <= 0
          ? true
          : false,
      message: "Please choose a department !",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (data?._id) {
      setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
      });
      setSelectedItem(
        {
          _id: data?.positionDetails?._id,
          name: data?.positionDetails?.title,
        } || {}
      );
      setPhone(data?.phone || "");
      setImage(data?.image?.url);
      const rolePriority = [
        "COMPANY-ADMINISTRATOR",
        "COMPANY-MANAGER",
        "COMPANY-EMPLOYEE",
      ];
      const highestPriorityRole = data?.roleDetails?.reduce(
        (prev, curr) => {
          return rolePriority.indexOf(curr.code) <
            rolePriority.indexOf(prev.code)
            ? curr
            : prev;
        },
        { code: "COMPANY-EMPLOYEE" }
      );
      setUserType(highestPriorityRole?.code === "COMPANY-MANAGER" ? "COMPANY-EMPLOYEE" : highestPriorityRole?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectedItems = (val) => {
    setSelectedDepartments(val);
  };

  const loadPosList = useCallback(() => {
    setPosList({ loading: true, data: [] });
    const payload = {
      limit: 10000,
      ...(posKeyword && { keyword: posKeyword }),
    };
    getPositionList(payload).then((res) => {
      if (res?.status === 200) {
        setPosList({ loading: false, data: res.docs });
      } else {
        setPosList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posKeyword, data]);

  useEffect(() => {
    loadPosList();
  }, [loadPosList]);

  const loadDeplist = useCallback(() => {
    setDepList({ loading: true, data: [] });
    const payload = {
      limit: 10000,
      ...(deptKeyword && { keyword: deptKeyword }),
    };
    getDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        const selectedIds =
          data?.departmentDetails?.map((item) => item?._id) || [];

        setDepList({ loading: false, data: res.docs });
        setDepartment(
          res?.docs?.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: selectedIds?.includes(_id),
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [deptKeyword, data]);

  useEffect(() => {
    loadDeplist();
  }, [loadDeplist]);



  const onSubmit = async (values) => {
    setLoading(true);

    const departments = {};

    if (
      selectedDepartments &&
      Array.isArray(selectedDepartments) &&
      selectedDepartments?.length > 0
    ) {
      selectedDepartments?.forEach((item, i) => {
        departments[`departmentId[${i}]`] = item;
      });
    }
    if (phone?.length > 12) {
      toast.error("Phone number cannot exceed 12 characters.");
      setLoading(false);
      return; 
    }
    try {
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        positionId: selectedItem?._id,
        userType,
        phone: phone || "",
        image: image ? image : "null",
        ...departments,
      };

      const res = await employeeEdit({
        ...payload,
        id: data?._id,
      });

      setLoading(false);
      if (res?.status === 201 || res?.status === 200) {
        toast.success(res?.message);
        setEditModalOpen({ isOpen: false });

        setDetails((prev) => ({
          ...prev,
          data: res?.data,
        }));
      } else {
        toast.error(res?.data?.message || res.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const radioOptions = [
    { label: "Administrator", value: "COMPANY-ADMINISTRATOR" },
    // { label: "Evaluator", value: "COMPANY-MANAGER" },
    { label: "Employee", value: "COMPANY-EMPLOYEE" },
  ];


  useEffect(() => {
    if (selectedItem?._id === "other") {
      setPositionModal({ data: {}, isOpen: true });
    }
  }, [selectedItem?._id]);

  useEffect(() => {
    if (selectedItem?._id === "other") {
      if (data?._id) {
        setFieldsValue({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
        });
      }
      setSelectedItem({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?._id]);

  const handleOtherClick = () => {
    setDepModal({ data: {}, isOpen: true });
  };

  const onClose = () => {
    setIsOpen(false);
    setUserType("COMPANY-EMPLOYEE");
  };

  const onSaveHandler = () => {
    setIsOpen(false);
    setUserType("COMPANY-ADMINISTRATOR");
  };


  return (
    <Modal
      title={data?._id ? "Edit Employee" : "Add Employee"}
      size="xl4"
      open={editmodalOpen?.isOpen}
      onClose={() => {
        setEditModalOpen({ isOpen: false });
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-12 gap-4 p-6 lg:pl-0 bg-white rounded-lg shadow-md"
      >
        {/* Image Upload Section */}
        <div className="image bg-white col-span-12 lg:col-span-4 border-0 lg:border-r border-slate-200 h-auto flex flex-col items-center">
          <h2 className="text-lg font-semibold text-slate-700 text-center border-b border-slate-200 mb-2">
            Employee Image
          </h2>
          <ImageUploader
            label=""
            image={image}
            altImage={noEmpImg}
            isMultiple={false}
            setImage={setImage}
            // isCameraIcon={true}
            isCrop={true}
            buttonLabelClasses="text-xs"
            buttonIcon="fa-upload text-xs"
            labelClasses="text-sm font-medium"
            buttonClasses="mx-auto !py-2 !px-2 rounded-lg text-white !h-auto !bg-orange-500 hover:!bg-orange-600 transition-all duration-300 !w-[81%]"
            inputClasses="w-40 h-40 hover:bg-slate-200 rounded-full border-2 border-gray-300 overflow-hidden"
            buttonLabel={data?.image ? "Change Image" : "Upload Image"}
          />
        </div>

        {/* User Details Section */}
        <div className="input bg-white col-span-12 lg:col-span-8 flex flex-col">
          <div className="md:grid lg:grid-cols-2 gap-3 space-y-3 md:space-y-0">
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon="fa-light fa-user text-slate-400"
                inputGroupPosition="left"
                label="First Name"
                inputPlaceholder="Enter first name"
                inputClasses="pl-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
                labelClasses="text-sm font-medium text-slate-600"
                inputType="text"
                value={values.firstName}
                isValidate={true}
                inputName="firstName"
                onChange={handleChange}
                charLimit={100}
                {...formValidate(errors, "firstName")}
              />
            </div>

            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon="fa-light fa-user text-slate-400"
                inputGroupPosition="left"
                label="Last Name"
                inputPlaceholder="Enter last name"
                inputClasses="pl-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
                labelClasses="text-sm font-medium text-slate-600"
                inputType="text"
                value={values.lastName}
                isValidate={true}
                inputName="lastName"
                onChange={handleChange}
                charLimit={100}
                {...formValidate(errors, "lastName")}
              />
            </div>
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon="fa-light fa-envelope text-slate-400"
                inputGroupPosition="left"
                label="Email"
                inputPlaceholder="Enter email"
                inputClasses="pl-3 py-2 border rounded-md focus:ring focus:ring-blue-200"
                labelClasses="text-sm font-medium text-slate-600"
                inputType="email"
                value={values.email}
                inputName="email"
                isValidate={true}
                onChange={handleChange}
                {...formValidate(errors, "email")}
              />
            </div>
            <div className="relative">
                    <PhoneNumberFormat
                      label="Phone"
                      value={phone}
                      isInputGroup={true}
                      inputGroupIcon={"fa-solid fa-phone"}
                      inputGroupPosition={"left"}
                      setPhone={setPhone}
                      inputPlaceholder={"(xxx) xxx-xxxx"}
                      charLimit={12}
                    />
                  </div>

            <div className="relative">
              <SelectDropdownSearch
                label="Job Title"
                placeholder="Choose a Job Title"
                isSearch={true}
                xPlacement="bottom"
                buttonArrowIcon="fa-solid fa-chevron-down"
                tabTextColor="text-slate-700"
                loading={posList?.loading}
                value={selectedItem?._id}
                onChange={handleChange}
                isValidate={true}
                inputClasses={"!text-slate-600"}
                dropdownButtonClass="px-3 py-2 border rounded-md w-full focus:ring focus:ring-blue-200"
                dropdownData={[
                  ...(Array.isArray(posList?.data)
                    ? posList?.data?.map(({ _id, title }) => ({
                        name: title,
                        _id: _id,
                      }))
                    : []),
                ]}
                isOther={true}
                {...formValidate(errors, "positionId")}
                onSearch={(val) => {
                  setKeywords((pre) => ({ ...pre, posKeyword: val }));
                }}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </div>

            <div className="relative">
              <MultiselectDropdown
                label={"Departments"}
                buttonLabel={"Select Departments"}
                xPlacement={"bottom"}
                isCheckbox={true}
                isSearch={true}
                dropdownData={department}
                setDropdownData={setDepartment}
                value={selectedDepartments}
                buttonArrowIconClasses={"!text-sm !text-slate-500 !right-3"}
                buttonLabelClasses={"!font-medium !text-gray-600"}
                buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                selectedItems={handleSelectedItems}
                isValidate={true}
                {...formValidate(errors, "departmentId")}
                onSearch={(e) =>
                  setKeywords((pre) => ({
                    ...pre,
                    deptKeyword: e,
                  }))
                }
                isOther={true}
                handleOtherClick={handleOtherClick}
              />
            </div>

            <div className="relative">
              <h2 className="text-sm font-medium text-slate-600">User Role</h2>
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                {radioOptions.map((option) => (
                  <Radio
                    key={option?.value}
                    radioLabel={option?.label}
                    radioValue={option?.value}
                    onChange={(e) => {
                    const newValue = e.target.value;
                    setUserType(newValue);
                    if (newValue === "COMPANY-ADMINISTRATOR") {
                      setIsOpen(true);
                    }
                  }}
                    isChecked={userType === option?.value}
                    radioName="userRole"
                    className="text-sm text-slate-600"
                    divClass="!overflow-visible"
                  />
                ))}
              </div>
            </div>

            <div className="relative flex justify-end items-end">
              <Button
                loading={loading}
                buttonLabel={
                  data?._id
                    ? loading
                      ? "Saving..."
                      : "Save"
                    : loading
                    ? "Adding..."
                    : "Add"
                }
                isDisable={loading}
                buttonIcon={
                  data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
                }
                buttonIconPosition="left"
                buttonType="submit"
                buttonClasses="!py-2 !px-2 rounded-lg text-white !h-auto !bg-orange-500 hover:!bg-orange-600"
              />
            </div>
          </div>
        </div>
      </form>
      <PositionManage
        loadPosList={loadPosList}
        isPositionModal={isPositionModal}
        setPositionModal={setPositionModal}
        setSelectedItem={setSelectedItem}
      />
      <DepartmentMange
        isDepModal={isDepModal}
        setDepModal={setDepModal}
        setSelectedDepartments={setSelectedDepartments}
        setDepartment={setDepartment}
      />
       <ConfirmModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        onClose={onClose}
        onSaveHandler={onSaveHandler}
        modalBodyData={
          "Administrators have permission to make edits and changes to the OrgChart. Are you sure you want to make this user an administrator?"
        }
      />
    </Modal>
  );
}
