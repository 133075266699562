import React, { useState } from "react";
import Td from "./Td";
import { Draggable, Droppable } from "react-beautiful-dnd";

const Tbody = ({ tableData = [], isOrdering = true, tableActionDropdownContainer }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Droppable droppableId="table-list" isDropDisabled={loading || isOrdering}>
        {(provided) => (
          <tbody className="text-slate-700 text-sm font-medium" {...provided.droppableProps} ref={provided.innerRef}>
            {tableData.map((item, index) => (
              <Draggable
                key={index}
                draggableId={`${item._id}${index}`}
                // key={index.toString()}
                // draggableId={index.toString()}
                index={index}
                isDragDisabled={loading || isOrdering}>
                {(provided) => (
                  <tr
                    className="border-b border-slate-300"
                    key={item._id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {item.rowData.map((itemData) => (
                      <Td
                        key={itemData._id}
                        data={itemData}
                        actionData={itemData.actionData}
                        functions={itemData?.functions}
                        onClick={itemData?.onClick}
                        checkboxChecked={itemData.checked || false}
                        tableActionDropdownContainer={tableActionDropdownContainer}
                        onCheckboxChange={() =>
                          itemData.onCheckboxChange ? itemData.onCheckboxChange(itemData.itemDetails || {}) : () => {}
                        }
                      />
                    ))}
                  </tr>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </>
  );
};

export default Tbody;
