import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../form/Button";
import Input from "../../../form/Input";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import {
  getTransactionList,
  subscriptionAdd,
} from "../../../../services/frontService/PaymentService";
import { toast } from "react-toastify";
import PaymentList from "./PaymentList";
import EditLastPayment from "./EditLastPayment";

const ManagePayment = ({ data, setManagePaymentPopup }) => {
  const flatPicker = useRef(null);
  const [price, setPrice] = useState("");
  const [startDate, setStartDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState({ data: null, open: false });
  const [transactions, setTransactions] = useState([]);
  const [processing, setProcessing] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await subscriptionAdd({
        startDate: startDate,
        price: price,
        companyId: data?._id,
        subscriptionId: null,
        transactionId: null,
        type: "add",
      });
      if (result.status && result.status === 200) {
        toast.success(result?.message || result?.data?.message);
        setManagePaymentPopup({
          data: null,
          isOpen: false,
        });
        setLoading(false);
      } else {
        toast.error(result?.message || result?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const closeModal = () => {
    setManagePaymentPopup({ isOpen: false, data: null });
  };

  //Transction List Api Call
  const loadTransactions = useCallback(async () => {
    setProcessing(true);
    try {
      const result = await getTransactionList({
        companyId: data?._id,
      });
      if (result.data) {
        setTransactions(result.data);
      }
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      console.log(err.message);
    }
  }, [data]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);
  return (
    <div className="relative bg-white">
      <div className="relative">
        <PaymentList
          isShow={isShow}
          setIsShow={setIsShow}
          transactions={transactions}
          setTransactions={setTransactions}
          processing={processing}
          setProcessing={setProcessing}
        />
        {isShow?.open && (
          <EditLastPayment
            isShow={isShow}
            setIsShow={setIsShow}
            loadTransactions={loadTransactions}
          />
        )}
      </div>
      <div className="w-full h-0.5 bg-slate-200 block relative text-center  my-4 z-[3]">
        <div className="p-2 bg-white  text-black absolute -top-[14px] left-[46%] right-0 z-[2] w-12 flex items-center justify-center text-xs">
          OR
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col">
          <div className="space-y-4">
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="start-date-picker"
                  className="text-sm font-medium text-gray-500"
                >
                  Start Date
                  <sup className="text-red-500"> *</sup>
                </label>
                <Flatpickr
                  id="start-date-picker"
                  ref={flatPicker}
                  className="w-full h-10 rounded-md bg-white border border-gray-300 px-3 text-sm text-gray-700 placeholder-gray-400 focus:ring-2 focus:ring-orange-200 focus:border-orange-200 transition-all duration-200"
                  placeholder="Start Date"
                  value={startDate}
                  options={{
                    dateFormat: "Y-m-d",
                    // position: "below",
                  }}
                  onChange={(selectedDates, dateStr, instance) => {
                    setStartDate(dateStr);
                  }}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={"Price"}
                  inputType={"number"}
                  labelClasses={""}
                  inputPlaceholder={"Enter price"}
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                  isRequired={true}
                  inputName="price"
                  errorType={data?.price ? "info" : ""}
                  errorText={
                    data?.price ? "Current Price: $" + data?.price : ""
                  }
                />
              </div>
            </div>
            {/* <div className="grid sm:grid-cols-1 gap-4">
            <div className="relative w-full">
              <Input
                label={"Price"}
                inputType={"number"}
                labelClasses={""}
                inputPlaceholder={"Enter price"}
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                isRequired={true}
                inputName="price"
                errorType={data?.price ? "info" : ""}
                errorText={
                  data?.price ? "Current Price: $" + data?.price : ""
                }
              />
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <div className="relative flex justify-center mb-3 mt-6 gap-4">
        <Button
          buttonType={"button"}
          buttonIcon={"fa-light fa-times"}
          buttonIconPosition={"left"}
          buttonLabel={"Cancel"}
          buttonClasses={
            "bg-white border border-orange-500 !text-orange-500 ml-2"
          }
          buttonLabelClasses="text-orange-500"
          buttonFunction={closeModal}
          buttonHasLink={false}
        />
        <Button
          buttonClasses={"!bg-orange-500"}
          buttonLabelClasses=""
          buttonType={"button"}
          buttonIcon={
            loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
          }
          buttonIconPosition={"left"}
          buttonLabel={"Accept"}
          buttonFunction={onSubmit}
          buttonHasLink={false}
          buttonDisabled={loading}
          isLoaderDisabled={true}
        />
      </div>
    </div>
  );
};

export default ManagePayment;
