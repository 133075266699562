import React, { useCallback, useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { Outlet, useNavigate } from "react-router-dom";
import { getVerifyTokan, profileDetails } from "../../services/ProfileService";
import Header from "../shared/Header";
import Sidebar from "../shared/Sidebar";
import { setProfile } from "../../redux/slice/profileSlice,";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/form/Button";

const AuthLayout = () => {
  const authToken = localStorage.getItem("auth_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(true);
  const profile = useSelector((state) => state?.profile?.data);
  const settings = useSelector((state) => state?.settings?.data);

  const loadVerifyToken = useCallback(async () => {
    try {
      const res = await getVerifyTokan();
      if (res?.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/signin");
      } else {
        const userRole = res?.data?.data?.role?.find((item) => item?.code === "COMPANY-ADMINISTRATOR")
          ? "COMPANY-ADMINISTRATOR"
          : res?.data?.data?.role?.find((item) => item?.code === "COMPANY-MANAGER")
          ? "COMPANY-MANAGER"
          : res?.data?.data?.role?.find((item) => item?.code === "ADMIN")
          ? "ADMIN"
          : "";
        // console.log(userRole, "userRoleuserRole");
        localStorage.setItem("role", userRole);
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("auth_token");
        navigate("/signin");
      } else {
        console.error("Unexpected error:", error);
        localStorage.removeItem("auth_token");
        navigate("/signin");
      }
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      loadVerifyToken();
    }
  }, [loadVerifyToken, authToken]);

  const fetchProfile = useCallback(() => {
    profileDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(setProfile(res?.data));
      }
    });
  }, [authToken]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  function favIconChange() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings?.favicon?.url;
  }
  favIconChange();

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="fixed top-0 left-0 w-full h-14 flex z-40">
          <Header profile={profile} isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div className="relative flex-shrink flex-grow flex pt-14  h-full">
          <div
            className={classNames(
              "fixed h-screen z-30 top-14 xl:top-0 left-0 flex shadow-[2px_2px_4px_0px_rgba(32,81,109,0.08)] bg-white transition-all duration-200 ease-in-out pt-14",
              !isMenuOpen ? "w-20" : "w-60"
            )}>
            <Sidebar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
            <div className="absolute flex gap-2 top-11 z-10 right-[0px]" style={{ marginTop: "29px" }}>
              <Button
                buttonHasLink={false}
                buttonIcon={!isMenuOpen ? "fa-regular fa-chevron-right" : "fa-regular fa-chevron-left"}
                buttonIconPosition="left"
                buttonClasses={
                  "!absolute !rounded-full right-[-14px] top-[-10px] !z-[60] px-0 w-10 !bg-orange-500 !text-black-600 hover:!bg-orange-600  !h-[30px] !w-[30px] !flex !items-center !justify-center !border-none"
                }
                buttonFunction={() => setMenuOpen(!isMenuOpen)}
              />
            </div>
          </div>
          <div
            className={classNames(
              "relative z-10 flex w-full transition-all duration-200 ease-in-out h-full bg-slate-100",
              !isMenuOpen ? "pl-20" : "pl-0 xl:pl-60"
            )}>
            <div className="w-full bg-slate-100 h-[calc(100vh-3.5rem)] overflow-auto scrollbar">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
