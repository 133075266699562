import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  getPositionList,
  syncGenarateToken,
  syncRecord,
} from "../../../../services/SsoService";
import SyncListSkeleton from "../../../loaders/SyncListSkeleton";
import Select from "../../../form/Select";
import Button from "../../../form/Button";
import { Tooltip } from "react-tooltip";
import Loader from "../../../common/Loader";
import SyncingLoader from "../../../loaders/SyncingLoader";
import Pagination from "../../../common/Pagination";
import {
  NINEBOX_API_URL,
  NINEBOX_BASE_URL,
  SUCCESSION_API_URL,
  SUCCESSION_BASE_URL,
} from "../../../../config/host";
import { toast } from "react-toastify";
import Checkbox from "../../../form/Checkbox";
import moment from "moment";

const PositionList = ({
  syncingStep,
  syncFailed,
  syncSuccess,
  selectedPosition,
  setSelectedPosition = () => {},
  arePositionAllSelected,
  setArePositionAllSelected = () => {},
  syncDataModalOpen,
}) => {
  const [positionList, setPositionList] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [selectedState, setSelectedState] = useState("");

  const loadList = useCallback(async () => {
    setLoadingData(true);
    try {
      const payload = {
        keyword: keyword,
        limit: limit,
        offset: offset,
      };

      if (selectedState && selectedState?.value !== "all") {
        payload["alreadySynced"] = selectedState?.value;
        payload["code"] = syncingStep?.selectedWebsite?.code;
      }
      const result = await getPositionList(payload);
      if (result.status === 200) {
        setPositionList(result?.docs);
      }
      setTotalDataCount(result?.totalDocs);
      setPageCount(result?.totalPages);
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      console.log(err.message);
    } finally {
      setLoadingData(false);
    }
  }, [keyword, limit, offset,selectedState]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handleSelectedPosition = (item) => {
    setSelectedPosition((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (member) => member?._id === item?._id
      );

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers =
      positionList &&
      Array.isArray(positionList) &&
      positionList?.length > 0 &&
      positionList?.filter((member) => member._id);

    if (selectedPosition.length === filteredMembers.length) {
      setSelectedPosition([]);
    } else {
      setSelectedPosition(filteredMembers);
    }
  };

  useEffect(() => {
    if (syncDataModalOpen) {
      const filteredMembers =
        positionList &&
        Array.isArray(positionList) &&
        positionList?.length > 0 &&
        positionList?.filter((member) => member?._id);
      const isAllSelected =
        selectedPosition.length === filteredMembers.length &&
        filteredMembers.length !== 0;

      if (isAllSelected) {
        setArePositionAllSelected(true);
      } else {
        setArePositionAllSelected(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    arePositionAllSelected,
    positionList,
    selectedPosition,
    syncDataModalOpen,
  ]);
  const getGenarateSyncRecord = async (syncRecordData) => {
    try {
      const res = await syncRecord({
        type: "position",
        positionId: syncRecordData?.positionId?.data,
        id: syncRecordData?.id,
        code: syncRecordData?.code,
      });
      if (res?.status === 200) {
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Somthing went wrong !!!");
    }
  };
  const syncPosition = async (url, headersToken) => {
    const updatedPositionList =
      positionList &&
      Array.isArray(positionList) &&
      positionList?.length > 0 &&
      positionList.map((item) => {
        const isSelected = selectedPosition?.some(
          (member) => member._id === item?._id
        );
        if (isSelected) {
          return { ...item, syncingStatus: "processing" };
        }
        return item;
      });
    setPositionList(updatedPositionList);
    try {
      while (selectedPosition?.length > 0) {
        const currentPosition = selectedPosition[0];

        // Validate currentPosition fields
        if (!currentPosition || !currentPosition?.title) {
          toast.error("Invalid position data encountered.");
          selectedPosition.shift();
          continue;
        }

        const path = url;
        const payload = {
          companyEmail:
            currentPosition.companyDetails?.companyAdministrator?.email,
          position_details: {
            name: currentPosition?.title,
            short_name: currentPosition?.short_name,
          },
          code: "orgChart",
        };

        try {
          const res = await axios.post(path, payload, {
            headers: {
              Authorization: `Bearer ${headersToken}`,
              "Content-Type": "application/json",
            },
          });

          if (res?.data?.status === 200) {
            // toastr.success(`Successfully synced: ${currentEmployee.name}`);
            setPositionList((prevList) =>
              prevList.map((item) =>
                item._id === currentPosition._id
                  ? { ...item, syncingStatus: "success" }
                  : item
              )
            );

            const syncRecordData = {
              positionId: res?.data?.data,
              id: currentPosition?._id,
              code: syncingStep?.selectedWebsite?.code,
            };
            getGenarateSyncRecord(syncRecordData);
            selectedPosition.shift();
          } else {
            setPositionList((prevList) =>
              prevList.map((item) =>
                item._id === currentPosition._id
                  ? { ...item, syncingStatus: "failed" }
                  : item
              )
            );
            selectedPosition.shift();
            console.log(`Failed to sync: ${currentPosition.name}`);
            // break; // Stop processing further employees
          }
        } catch (err) {
          console.error(`Error syncing employee: ${currentPosition.name}`, err);
          setPositionList((prevList) =>
            prevList.map((item) =>
              item._id === currentPosition._id
                ? { ...item, syncingStatus: "failed" }
                : item
            )
          );
          if (err.response) {
            console.error("Server error:", err.response.data);
            toast.error(
              `Error: ${err.response.data.message || "Server error occurred."}`
            );
          } else if (err.request) {
            // Request was made but no response received
            console.error("No response received:", err.request);
            toast.error("Network error. Please try again later.");
          } else {
            console.error("Unexpected error:", err.message);
            toast.error(`Unexpected error: ${err.message}`);
          }

          selectedPosition.shift();
          // break;
        }
      }

      if (selectedPosition.length === 0) {
        console.log("All employees have been successfully synced!");
      } else {
        console.log(
          "Sync process stopped. Remaining employees:",
          selectedPosition
        );
      }
    } catch (err) {
      console.error("Critical error during the sync process:", err);
      toast.error("A critical error occurred. Please try again later.");
    }
  };

  const getGenarateTokenForPosition = async (DOMAIN, API_URL = "") => {
    try {
      const res = await syncGenarateToken({
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "sync-position";
        const headersToken = res?.data;
        syncPosition(url, headersToken);
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      {loadingData ? (
        <SyncListSkeleton type={"position"} count={10} />
      ) : (
        <>
          {" "}
          <div className=" bg-orange-50 p-4 rounded-md ">
            <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2">
              <div className="w-full sm:w-36">
                <Select
                  selectedValue={limit}
                  dropdownData={[
                    {
                      name: "All Items",
                      value: totalDataCount,
                    },
                    { name: "5 Items", value: 5 },
                    { name: "10 Items", value: 10 },
                    { name: "20 Items", value: 20 },
                    { name: "30 Items", value: 30 },
                    { name: "50 Items", value: 50 },
                    { name: "100 Items", value: 100 },
                  ]}
                  getSelectedValue={(e) => {
                    setLimit(e.value);
                    setOffset(0);
                    setCurrentPage(0);
                    setSelectedPosition([]);
                  }}
                />
              </div>
              <div className="relative w-48">
                <Select
                  dropdownButtonClass={"!bg-white"}
                  dropdownClass={"w-auto"}
                  selectedValue={selectedState?.value || "all"}
                  dropdownData={[
                    { name: "All Data", value: "all" },
                    {
                      name: "Already Synced Data",
                      value: "yes",
                    },
                    { name: "Not Synced Data", value: "no" },
                  ]}
                  getSelectedValue={(e) => {
                    setSelectedState(e);
                    setOffset(0);
                    setCurrentPage(0);
                    setSelectedPosition([]);
                  }}
                />
              </div>
              {positionList &&
                Array.isArray(positionList) &&
                positionList?.length > 0 &&
                positionList?.filter((member) => member?._id).length > 0 && (
                  <div
                    className="ml-auto"
                    data-tooltip-id={`selectAllButton${1434}`}
                    data-tooltip-place="bottom"
                  >
                    <Button
                      buttonType={"button"}
                      buttonClasses={
                        "bg-transparent border border-orange-500 !text-orange-500 ml-2"
                      }
                      buttonLabelClasses="text-orange-500"
                      buttonIcon={
                        arePositionAllSelected
                          ? "fa-solid fa-square-check"
                          : "fa-regular fa-square"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={
                        arePositionAllSelected
                          ? "Unselect All Position"
                          : "Select All Position"
                      }
                      buttonHasLink={false}
                      buttonFunction={() => handleSelectAll()}
                    />
                    <Tooltip
                      id={`selectAllButton${1434}`}
                      className="!text-xs !bg-slate-950 !text-white z-50 rounded py-3 px-6"
                      render={() => (
                        <div className="gap-x-1">
                          <div className="text-[13px] font-normal leading-none flex items-center">
                            <div className="font-semibold">
                              {arePositionAllSelected
                                ? "Click to deselect all positions"
                                : "Click to select all positions"}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                )}

              {selectedPosition?.length > 0 && (
                <div className="flex items-center">
                  <Button
                    buttonLabel={`Sync ${selectedPosition.length} Position${
                      selectedPosition.length > 1 ? "s" : ""
                    }`}
                    buttonIcon={"fa-solid fa-rotate"}
                    buttonIconPosition={"left"}
                    buttonClasses={"!text-white"}
                    buttonLabelClasses={"!text-white"}
                    buttonFunction={() => {
                      if (
                        syncingStep?.selectedWebsite?.code === "successionNow"
                      ) {
                        getGenarateTokenForPosition(
                          SUCCESSION_BASE_URL,
                          SUCCESSION_API_URL
                        );
                      } else if (
                        syncingStep?.selectedWebsite?.code === "nineBoxNow"
                      ) {
                        getGenarateTokenForPosition(
                          NINEBOX_BASE_URL,
                          NINEBOX_API_URL
                        );
                      } else {
                        toast.error("Please Select a Website For Syncing");
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-250px)] -mx-4 px-4">
              {positionList &&
                Array.isArray(positionList) &&
                positionList?.length > 0 &&
                positionList?.map((user, index) => {
                  const alreadySyncingData =
                    Array?.isArray(user?.websiteInfo) &&
                    user?.websiteInfo?.length > 0
                      ? user?.websiteInfo?.filter(
                          (info) =>
                            info.code === syncingStep?.selectedWebsite?.code
                        )[0]
                      : {};
                  return (
                    <>
                      <div className="bg-white rounded-md border border-slate-200 py-4 px-4 flex items-center gap-2 w-full">
                        <div className="flex justify-start flex-shrink-0">
                          <div
                            className="flex items-center justify-between py-2"
                            key={index}
                          >
                            <Checkbox
                              isChecked={selectedPosition.some(
                                (member) => member?._id === user?._id
                              )}
                              checkboxClass={"!border-orange-500"}
                              onChange={() => handleSelectedPosition(user)}
                            />
                            {/* <input
                            type="checkbox"
                            id={`${user?._id}`}
                            checked={selectedPosition.some(
                              (member) => member?._id === user?._id
                            )}
                            onChange={() => handleSelectedPosition(user)}
                            className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-dark-orange-700   checked:hover:bg-dark-orange-700 checked:bg-dark-orange-700 "
                          /> */}
                          </div>
                        </div>
                        <div className="flex-shrink ">
                          <h3 className="flex items-center gap-1 text-sm font-semibold text-orange-500 capitalize break-all">
                            {user?.title || "N/A"}
                          </h3>
                        </div>
                        {user?.syncingStatus === "processing" && (
                          <div className="flex flex-col items-center ml-auto flex-shrink-0">
                            <Loader className={"scale-50"} />
                            <SyncingLoader className={"!text-xs"} />
                          </div>
                        )}
                        {user?.syncingStatus === "success" && (
                          <div className="flex flex-col items-center ml-auto flex-shrink-0">
                            <div className="w-8 h-8">
                              <img src={syncSuccess} alt="syncSuccess" />
                            </div>
                            <div className="flex text-center justify-center items-center text-xs font-semibold text-green-600">
                              {"Sync Finished"}
                            </div>
                          </div>
                        )}
                        {user?.syncingStatus === "failed" && (
                          <div className="flex flex-col items-center ml-auto flex-shrink-0">
                            <div className="w-8 h-8">
                              <img src={syncFailed} alt="syncFailed" />
                            </div>
                            <div className="flex text-center justify-center items-center text-xs font-semibold text-rose-600">
                              {"Sync Failed"}
                            </div>
                          </div>
                        )}
                        {!user?.syncingStatus &&
                          alreadySyncingData?.code &&
                          alreadySyncingData?.positionId && (
                            <div className="flex flex-col items-end ml-auto flex-shrink-0">
                              <div className="w-8 h-8">
                                <i className="far fa-sync"></i>
                              </div>
                              <div className="flex text-right flex-col justify-center items-end text-[11px] font-semibold text-slate-400 !leading-none">
                                Last Sync
                                <span className="text-slate-500 font-bold">
                                  {moment(alreadySyncingData?.updatedAt).format(
                                    "ll"
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <Pagination
            paginationClasses={"mt-5 justify-end"}
            onPageChange={(val) => {
              setOffset(limit * val?.selected);
              setCurrentPage(val?.selected);
              setSelectedPosition([]);
            }}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  );
};

export default PositionList;
