import React, { useState } from "react";
import Button from "../form/Button";
import Modal from "./Modal";
import { Cropper } from "react-cropper";
import { Tooltip } from "react-tooltip";

const CropModal = ({
  setImage = () => {},
  showCropper,
  setShowCropper = () => {},
  setTempImage = () => {},
  originalImage,
  tempImage,
}) => {
  const [cropperInstance, setCropperInstance] = useState(null);

  const handleSaveCroppedImage = () => {
    if (cropperInstance) {
      cropperInstance.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], "cropped-image.jpg", {
            type: "image/jpeg",
          });

          setImage(croppedFile);
          setShowCropper(false);
        }
      }, "image/jpeg");
    }
  };

  const handleResetImage = () => {
    setTempImage(originalImage);
    if (cropperInstance) {
      cropperInstance.reset();
    }
  };
  const handleZoomIn = () => {
    if (cropperInstance) {
      cropperInstance.zoom(0.1);
    }
  };

  const handleZoomOut = () => {
    if (cropperInstance) {
      cropperInstance.zoom(-0.1);
    }
  };

  return (
    <>
      <Modal
        title="Add new employee"
        size="xl8"
        open={showCropper}
        onClose={() => {}}
        header={false}
        childrenClasses={"!rounded-b-lg !bg-transparent !py-0"}
      >
        <div className="bg-black bg-opacity-90 flex justify-center items-center relative">
          <div className="relative w-full h-full flex justify-center items-center">
            <Cropper
              src={tempImage}
              style={{
                width: "100%",
                height: "90vh",
              }}
              guides={false}
              cropBoxResizable={true}
              cropBoxMovable={true}
              viewMode={1}
              onInitialized={(instance) => setCropperInstance(instance)}
            />
          </div>

          <div className="absolute top-4 right-4 flex  z-10">
            <div data-tooltip-id="image-zoom-in">
              <Button
                buttonClasses="!bg-transparent !border-none !text-2xl"
                buttonFunction={handleZoomIn}
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-search-plus"}
              />
              <Tooltip
                id="image-zoom-in"
                place="bottom"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to zoom in image.</div>
                )}
              />
            </div>
            <div data-tooltip-id="image-zoom-out">
              <Button
                buttonClasses="!bg-transparent !border-none !text-2xl"
                buttonFunction={handleZoomOut}
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-search-minus"}
              />
              <Tooltip
                id="image-zoom-out"
                place="bottom"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to zoom out image.</div>
                )}
              />
            </div>
            <div data-tooltip-id="image-save">
              <Button
                buttonClasses="!bg-transparent !border-none !text-2xl "
                buttonFunction={handleSaveCroppedImage}
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-floppy-disk"}
              />
              <Tooltip
                id="image-save"
                place="bottom"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to save cropped image.</div>
                )}
              />
            </div>
            <div data-tooltip-id="image-reset">
              <Button
                buttonClasses="!bg-transparent !border-none !text-2xl "
                buttonFunction={handleResetImage}
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-undo"}
              />
              <Tooltip
                id="image-reset"
                place="bottom"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to reset image.</div>
                )}
              />
            </div>
            <div data-tooltip-id="close-modal">
              <Button
                buttonClasses="!bg-transparent !border-none !text-2xl "
                buttonFunction={() => setShowCropper(false)}
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-xmark"}
              />
              <Tooltip
                id="close-modal"
                place="bottom"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to close modal.</div>
                )}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CropModal;
