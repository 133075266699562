import { csvUpEmployee } from "../../../services/frontService/EmployeeService";
import SampleCSV from "../../../assets/sample_csv.xlsx";
import React, { useState, useRef } from "react";
import Modal from "../../elements/Modal";
import Button from "../../form/Button";
import { toast } from "react-toastify";

export default function CsvUploadModal({
  uploadModal,
  loadEmpList = () => {},
  setUploadModal = () => {},
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
   const [extension, setExtension] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validExtensions = ["csv", "xls", "xlsx"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        alert("Invalid file type. Please select a CSV or Excel file.");
        return;
      }
      setExtension(fileExtension);
      setSelectedFile(file);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = "";
  };
  const handleUpload = async () => {
    try {
      setLoading(true);
      const res = await csvUpEmployee({ xlsx: selectedFile, type: extension });
      if (res?.status === 200) {
        toast.success("Employees Uploaded Successfully");
        setUploadModal({ isOpen: false });
        setSelectedFile(null);
        loadEmpList();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || "An error occurred during upload.");
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        error?.data?.message ||
          "An unexpected error occurred during file upload."
      );
    }
  };

  const handleDownloadClick = (e) => {
    e.preventDefault();

    setIsDownloading(true);

    setTimeout(() => {
      setIsDownloading(false);
      const link = document.createElement("a");
      link.href = SampleCSV;
      link.download = SampleCSV;
      link.click();
    }, 2000);
  };

  return (
    <Modal
      title={"Upload CSV"}
      size="lg"
      open={uploadModal?.isOpen}
      onClose={() => {
        setUploadModal((prev) => ({ ...prev, isOpen: false }));
        setSelectedFile(null);
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <div className="w-full p-3 flex flex-col justify-center items-center gap-4">
        <div className="w-full bg-orange-50 p-4 rounded-lg border-orange-200 border flex flex-col items-center justify-center">
          <p className="text-sm text-gray-600 mb-2">
            Download the sample CSV file to understand the expected format.
          </p>
          <a
            href="#"
            onClick={handleDownloadClick}
            className={`text-orange-500 hover:text-orange-800 text-sm underline flex items-center gap-2 ${
              isDownloading ? "pointer-events-none text-gray-400" : ""
            }`}
          >
            {isDownloading ? (
              <i className="fa-duotone fa-spinner-third animate-spin"></i>
            ) : (
              <i className="fa-regular fa-file-arrow-down"></i>
            )}
            <span>
              {isDownloading ? "Preparing download..." : "Sample CSV"}
            </span>
          </a>
        </div>

        {selectedFile && (
          <div className="w-full bg-gray-100 border p-4 rounded-lg shadow flex justify-between items-center">
            <span className="text-sm font-medium text-gray-700">
              {selectedFile.name}
            </span>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={removeFile}
              aria-label="Remove file"
              title="Remove file"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        )}
        {!selectedFile && (
          <label htmlFor="csvFileInput" className="cursor-pointer">
            <Button
              buttonLabel={"Select ( CSV / XLS ) file"}
              buttonIcon={"fa-regular fa-file-excel"}
              buttonIconPosition={"left"}
              buttonClasses={"!z-0 !rounded-full"}
              buttonFunction={() => fileInputRef.current.click()}
            />
          </label>
        )}
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          accept=".csv,.xls,.xlsx"
          onChange={handleFileChange}
        />
        {selectedFile && (
          <Button
            buttonLabel={"Upload File"}
            buttonIconPosition={"left"}
            buttonClasses={"!z-0 !rounded-full"}
            buttonFunction={handleUpload}
            isDisable={loading}
            buttonIcon={
              loading
                ? "fa-duotone fa-spinner-third animate-spin"
                : "fa-regular fa-file-excel"
            }
          />
        )}
      </div>
    </Modal>
  );
}
