import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";

const SidebarMenuItem = ({ menuLink, menuIconColor, menuIcon, menuLabel, isMenuOpen }) => {
  const location = useLocation();
  const isExternal = menuLink.startsWith("http");

  if (isExternal) {
    return (
      <a
        href={menuLink}
        target="_blank"
        rel="noopener noreferrer"
        className={
          "group flex items-center gap-1 hover:bg-orange-500 rounded overflow-hidden p-1 transition-all duration-200 " +
          (location.pathname.includes(menuLink) ? " !bg-orange-500" : "")
        }>
        <div
          className={
            "w-8 h-8 flex justify-center items-center group-hover:text-white text-lg transition-all duration-200 " +
            menuIconColor +
            (location.pathname.includes(menuLink) ? " !text-white" : "")
          }>
          <i className={"fa-light fa-fw " + menuIcon}></i>
        </div>
        {!isMenuOpen && <div className="text-white text-sm">{menuLabel}</div>}
      </a>
    );
  }

  return (
    <NavLink
      to={menuLink}
      className={({ isActive }) =>
        "group flex items-center gap-1 hover:bg-orange-500 rounded overflow-hidden p-1 transition-all duration-200 " +
        (location.pathname.includes(menuLink) ? " !bg-orange-500" : "")
      }>
      {({ isActive }) => (
        <>
          <div
            className={
              "w-8 h-8 flex justify-center items-center group-hover:text-white text-lg transition-all duration-200 " +
              menuIconColor +
              (location.pathname.includes(menuLink) ? " !text-white" : "")
            }>
            <i className={"fa-light fa-fw " + menuIcon}></i>
          </div>
          {!isMenuOpen && <div className="text-white text-sm">{menuLabel}</div>}
        </>
      )}
    </NavLink>
  );
};

export default SidebarMenuItem;
