import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import { useDataSyncModal } from "../../Context/SyncDataModalContext";
import Indentification from "../elements/Indentification";
import { initialsValue } from "../../helpers";
import Loader from "../common/Loader";
import syncFailed from "../../assets/images/syncFaild.png";
import syncSuccess from "../../assets/images/syncSucess.png";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import {
  NINEBOX_API_URL,
  NINEBOX_BASE_URL,
  SUCCESSION_API_URL,
  SUCCESSION_BASE_URL,
} from "../../config/host";
import SyncingLoader from "../loaders/SyncingLoader";
import Pagination from "../common/Pagination";
// import PositionList from "../section/syncSection/PositionList";
// import SyncListSkeleton from "../loader/SyncListSkeleton";
import Select from "../form/Select";
import { toast } from "react-toastify";
import SyncListSkeleton from "../loaders/SyncListSkeleton";
import Checkbox from "../form/Checkbox";
import PositionList from "../sections/front/syncSection/PositionList";
import SelectDropdownSearch from "../form/SelectDropdownSearch";
import {
  getRoleList,
  syncGenarateToken,
  syncRecord,
} from "../../services/SsoService";
import { classNames } from "../../helpers/classNames";
import moment from "moment/moment";

const SyncDataPopup = ({ isOpen }) => {
  const {
    syncDataModalOpen,
    setSyncDataModalOpen,
    userList,
    setUserList,
    loadUserList,
    loading,
    syncingLoader,
    syncingStep,
    keyword,
    setKeyword,
    limit,
    setLimit,
    offset,
    setOffset,
    totalDataCount,
    setTotalDataCount,
    startDataCount,
    setStartDataCount,
    endDataCount,
    setEndDataCount,
    noOfPage,
    setNoOfPage,
    currentPage,
    setCurrentPage,
    selectedRole,
    setSelectedRole,
    selectedState,
    setSelectedState,
    pageCount,
    setPageCount,
  } = useDataSyncModal();

  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [arePositionAllSelected, setArePositionAllSelected] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [tabType, setTabType] = useState("employees");
  const [roleList, setRoleList] = useState({
    loading: false,
    data: [],
    totalItem: 0,
  });
  useEffect(() => {
    if (
      syncDataModalOpen &&
      syncingStep?.step === "2" &&
      tabType === "employees"
    ) {
      console.log("first")
      loadUserList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncDataModalOpen, syncingStep, tabType]);

  //   if (!syncDataModalOpen) return null;

  const handleSelectedMember = (item) => {
    setSelectedMember((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (member) => member?._id === item?._id
      );

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers =
      userList &&
      Array.isArray(userList) &&
      userList?.length > 0 &&
      userList?.filter((member) => member._id);

    if (selectedMember?.length === filteredMembers?.length) {
      setSelectedMember([]);
    } else {
      setSelectedMember(filteredMembers);
    }
  };

  useEffect(() => {
    if (syncDataModalOpen) {
      const filteredMembers =
        userList &&
        Array.isArray(userList) &&
        userList?.length > 0 &&
        userList?.filter((member) => member?._id);
      const isAllSelected =
        selectedMember.length === filteredMembers.length &&
        filteredMembers.length !== 0;

      if (isAllSelected) {
        setAreAllSelected(true);
      } else {
        setAreAllSelected(false);
      }
    }
  }, [selectedMember, userList, areAllSelected, syncDataModalOpen]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const getGenarateSyncRecord = async (syncRecordData) => {
    try {
      const res = await syncRecord({
        type: "user",
        userId: syncRecordData?.userId?.data,
        id: syncRecordData?.id,
        code: syncRecordData?.code,
      });
      if (res?.status === 200) {
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  const syncEmployee = async (url, headersToken) => {
    const updatedUserList = userList?.map((user) => {
      const isSelected = selectedMember?.some(
        (member) => member._id === user._id
      );
      if (isSelected) {
        return { ...user, syncingStatus: "processing" };
      }
      return user;
    });
    setUserList(updatedUserList);
    try {
      while (selectedMember?.length > 0) {
        const currentEmployee = selectedMember[0];
        if (
          !currentEmployee ||
          !currentEmployee.companyDetails?.companyAdministrator?.email ||
          !currentEmployee.firstName ||
          !currentEmployee.lastName ||
          !currentEmployee.email
        ) {
          console.error("Invalid employee data:", currentEmployee);
          toast.error("Invalid employee data encountered.");
          selectedMember.shift();
          continue;
        }
        const path = url;
        const payload = {
          companyEmail:
            currentEmployee.companyDetails?.companyAdministrator?.email,
          name: currentEmployee?.firstName + " " + currentEmployee?.lastName,
          email: currentEmployee?.email,
          userId: currentEmployee?._id,
          ...(currentEmployee?.positionDetails?.title
            ? { position_details: currentEmployee?.positionDetails }
            : {}),
          role_details:
            Array.isArray(currentEmployee?.roleDetails) &&
            currentEmployee?.roleDetails?.length > 0
              ? currentEmployee?.roleDetails
              : [],
          code: "orgChart",
          image: currentEmployee?.image?.url,
          passkey: currentEmployee?.passkey || null,
          status: currentEmployee?.status,
          emailVerified: currentEmployee?.emailVerified,
        };

        try {
          const res = await axios.post(path, payload, {
            headers: {
              Authorization: `Bearer ${headersToken}`,
              "Content-Type": "application/json",
            },
          });

          if (res?.data?.status === 200) {
            setUserList((prevList) =>
              prevList.map((user) =>
                user._id === currentEmployee._id
                  ? { ...user, syncingStatus: "success" }
                  : user
              )
            );
            const syncRecordData = {
              userId: res?.data?.data,
              id: currentEmployee?._id,
              code: syncingStep?.selectedWebsite?.code,
            };
            getGenarateSyncRecord(syncRecordData);
            selectedMember.shift();
          } else {
            setUserList((prevList) =>
              prevList.map((user) =>
                user._id === currentEmployee._id
                  ? { ...user, syncingStatus: "failed" }
                  : user
              )
            );
            selectedMember.shift();
            console.log(`Failed to sync: ${currentEmployee.name}`);
          }
        } catch (err) {
          console.error(`Error syncing employee: ${currentEmployee.name}`, err);
          setUserList((prevList) =>
            prevList.map((user) =>
              user._id === currentEmployee._id
                ? { ...user, syncingStatus: "failed" }
                : user
            )
          );
          if (err.response) {
            console.error("Server error:", err.response.data);
            toast.error(
              `Error: ${err.response.data.message || "Server error occurred."}`
            );
          } else if (err.request) {
            console.error("No response received:", err.request);
            toast.error("Network error. Please try again later.");
          } else {
            console.error("Unexpected error:", err.message);
            toast.error(`Unexpected error: ${err.message}`);
          }

          selectedMember.shift();
          // break;
        }
      }

      if (selectedMember.length === 0) {
        console.log("All employees have been successfully synced!");
      } else {
        console.log(
          "Sync process stopped. Remaining employees:",
          selectedMember
        );
      }
    } catch (err) {
      console.error("Critical error during the sync process:", err);
      toast.error("A critical error occurred. Please try again later.");
    }
  };

  const getGenarateToken = async (DOMAIN, API_URL = "") => {
    const updatedUserList = userList?.map((user) => {
      const isSelected = selectedMember.some(
        (member) => member._id === user._id
      );
      if (isSelected) {
        return { ...user, syncingStatus: "processing" };
      }
      return user;
    });
    setUserList(updatedUserList);
    try {
      const res = await syncGenarateToken({
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "sync-employees";
        const headersToken = res?.data;
        syncEmployee(url, headersToken);
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  useEffect(() => {
    setRoleList((pre) => ({ ...pre, data: [], loading: true }));

    getRoleList({
      limit: 100,
    }).then((res) => {
      if (res && res?.status === 200) {
        const filteredRoles = res?.docs.filter(
          (role) => role.code !== "COMPANY-MANAGER"
        );
        setRoleList((pre) => ({
          ...pre,
          data: filteredRoles || [],
          loading: false,
          totalItem: res?.totalDocs,
        }));
      } else {
        setRoleList((pre) => ({ ...pre, data: [], loading: false }));
      }
    });
  }, []);
  return (
    <>
      <div className="relative z-50 overflow-hidden w-full">
        <div
          className={classNames(
            "fixed inset-0 bg-black bg-opacity-25 transition-all duration-300",
            isOpen ? "opacity-100 visible" : "opacity-0 invisible"
          )}
        />
        <div
          className={classNames(
            "fixed inset-0 overflow-y-auto transition-all duration-300",
            isOpen ? "opacity-100 visible" : "opacity-0 invisible"
          )}
        >
          <div className="flex h-screen justify-end w-full overflow-hidden">
            <div
              className={classNames(
                "w-full flex flex-col max-w-3xl rounded-l-2xl p-4 bg-white text-left align-middle shadow-xl transition-all duration-300",
                isOpen ? "translate-x-0" : "translate-x-full"
              )}
            >
              <div className="relative flex-shrink-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900 text-center mb-4">
                  {syncingStep?.step === "1"
                    ? "Company Syncing"
                    : `Company Sync Dashboard (${
                        syncingStep?.selectedWebsite?.label
                          ? syncingStep?.selectedWebsite?.label
                          : syncingStep?.selectedWebsite?.name || "N/A"
                      })`}
                </h3>
                <div className="absolute -top-4 -right-4 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0 !border-transparent"
                    }
                    buttonFunction={() => {
                      setSyncDataModalOpen(false);
                      setSelectedRole("");
                    }}
                  />
                </div>
              </div>
              <div className="relative mt-2 flex-shrink flex-grow flex flex-col">
                {syncingStep?.step === "1" && (
                  <>
                    <div className="flex justify-center items-center min-h-[260px] flex-col my-auto">
                      {syncingLoader && (
                        <>
                          <div className="">
                            <Loader />
                          </div>
                          <div className="flex text-center justify-center items-center text-xl font-bold text-orange-500">
                            {"Syncing Company..."}
                          </div>
                        </>
                      )}
                      {!syncingLoader && (
                        <>
                          {syncingStep?.status === 400 ? (
                            <>
                              <div className="w-16 h-16">
                                <img src={syncFailed} alt="syncFailed" />
                              </div>
                              <div className="flex text-center justify-center items-center text-xl font-bold text-rose-600">
                                {syncingStep?.message || ""}
                              </div>
                            </>
                          ) : syncingStep?.status === 200 ? (
                            <>
                              <div className="w-16 h-16">
                                <img src={syncSuccess} alt="syncSuccess" />
                              </div>
                              <div className="flex text-center justify-center items-center text-xl font-bold text-green-600">
                                {"Company Syncing Successful"}
                              </div>
                            </>
                          ) : syncingStep?.status === 417 ? (
                            <>
                              <div className="w-16 h-16">
                                <img src={syncSuccess} alt="syncSuccess" />
                              </div>
                              <div className="flex text-center justify-center items-center text-xl font-bold text-orange-600">
                                {syncingStep?.message ||
                                  "Comapany Already Exists"}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="w-16 h-16">
                                <img src={syncFailed} alt="syncFailed" />
                              </div>
                              <div className="flex text-center justify-center items-center text-xl font-bold text-rose-600">
                                {"Company Syncing Failed. Please Try Again."}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}

                {syncingStep?.step === "2" && (
                  <>
                    <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5 flex-shrink-0">
                      {[
                        "employees",

                        "positions",
                        // adminRole?.code === "COMPANY-ADMINISTRATOR" &&
                        //   "department",
                      ]
                        .filter(Boolean)
                        .map((type) => (
                          <button
                            key={type}
                            type="button"
                            className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform ${
                              tabType === type
                                ? "text-orange-500 border-orange-500 font-semibold"
                                : "text-slate-500 border-transparent hover:text-orange-500 hover:border-orange-500"
                            }`}
                            onClick={() => setTabType(type)}
                          >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                          </button>
                        ))}
                    </div>

                    {tabType === "employees" && (
                      <>
                        {!loading && (
                          <>
                            <div className=" bg-orange-50 p-4 rounded-md ">
                              <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2">
                                <div className="relative">
                                  <Select
                                    selectedValue={limit}
                                    dropdownData={[
                                      {
                                        name: "All Items",
                                        value: totalDataCount,
                                      },
                                      { name: "5 Items", value: 5 },
                                      { name: "10 Items", value: 10 },
                                      { name: "20 Items", value: 20 },
                                      { name: "30 Items", value: 30 },
                                      { name: "50 Items", value: 50 },
                                      { name: "100 Items", value: 100 },
                                    ]}
                                    getSelectedValue={(e) => {
                                      setLimit(e.value);
                                      setOffset(0);
                                      setCurrentPage(0);
                                      setSelectedMember([]);
                                    }}
                                  />
                                </div>
                                <div className="relative w-48">
                                  <Select
                                    dropdownButtonClass={"!bg-white"}
                                    dropdownClass={"w-auto"}
                                    selectedValue={selectedRole?.value || "all"}
                                    dropdownData={[
                                      { name: "All Items", value: "all" },
                                      ...roleList?.data?.map((item) => ({
                                        name: item.code,
                                        value: item._id,
                                      })),
                                    ]}
                                    getSelectedValue={(e) => {
                                      setSelectedRole(e);
                                      setOffset(0);
                                      setCurrentPage(0);
                                      setSelectedMember([]);
                                    }}
                                  />
                                </div>
                                <div className="relative w-48">
                                  <Select
                                    dropdownButtonClass={"!bg-white"}
                                    dropdownClass={"w-auto"}
                                    selectedValue={
                                      selectedState?.value || "all"
                                    }
                                    dropdownData={[
                                      { name: "All Data", value: "all" },
                                      {
                                        name: "Already Synced Data",
                                        value: "yes",
                                      },
                                      { name: "Not Synced Data", value: "no" },
                                    ]}
                                    getSelectedValue={(e) => {
                                      setSelectedState(e);
                                      setOffset(0);
                                      setCurrentPage(0);
                                      setSelectedMember([]);
                                    }}
                                  />
                                </div>
                                {userList &&
                                  Array.isArray(userList) &&
                                  userList?.length > 0 &&
                                  userList?.filter((member) => member?._id)
                                    .length > 0 && (
                                    <div
                                      className="ml-auto"
                                      data-tooltip-id={`selectAllButton${1434}`}
                                      data-tooltip-place="bottom"
                                    >
                                      <Button
                                        buttonType={"button"}
                                        buttonClasses={
                                          "bg-transparent !text-orange-500 !px-3"
                                        }
                                        buttonLabelClasses="text-orange-500"
                                        buttonIcon={
                                          areAllSelected
                                            ? "fa-solid fa-square-check"
                                            : "fa-light fa-square"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonLabel={
                                          areAllSelected
                                            ? "Unselect All Employees"
                                            : "Select All Employees"
                                        }
                                        buttonHasLink={false}
                                        buttonFunction={() => handleSelectAll()}
                                      />
                                      <Tooltip
                                        id={`selectAllButton${1434}`}
                                        className="!text-xs !bg-slate-950 !text-white z-50  py-2 px-2 !opacity-100"
                                        render={() => (
                                          <div className="gap-x-1">
                                            <div className="text-[13px] font-normal leading-none flex items-center">
                                              <div className="font-semibold">
                                                {areAllSelected
                                                  ? "Click to deselect all employees"
                                                  : "Click to select all employees"}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      />
                                    </div>
                                  )}

                                {selectedMember?.length > 0 && (
                                  <div className="flex items-center">
                                    <Button
                                      buttonLabel={`Sync ${
                                        selectedMember.length
                                      } Employee${
                                        selectedMember.length > 1 ? "s" : ""
                                      }`}
                                      buttonIcon={"fa-solid fa-rotate"}
                                      buttonIconPosition={"left"}
                                      buttonClasses={
                                        "!bg-orange-500 !text-white"
                                      }
                                      buttonLabelClasses={"!text-white"}
                                      buttonFunction={() => {
                                        if (
                                          syncingStep?.selectedWebsite?.code ===
                                          "successionNow"
                                        ) {
                                          getGenarateToken(
                                            SUCCESSION_BASE_URL,
                                            SUCCESSION_API_URL
                                          );
                                        } else if (
                                          syncingStep?.selectedWebsite?.code ===
                                          "nineBoxNow"
                                        ) {
                                          getGenarateToken(
                                            NINEBOX_BASE_URL,
                                            NINEBOX_API_URL
                                          );
                                        } else {
                                          getGenarateToken(
                                            NINEBOX_BASE_URL,
                                            NINEBOX_API_URL
                                          );
                                          toast.error(
                                            "Please Select a Website For Syncing"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-250px)] -mx-4 px-4">
                                {userList &&
                                  Array.isArray(userList) &&
                                  userList?.length > 0 &&
                                  userList?.map((user, index) => {
                                    const alreadySyncingData =
                                      Array?.isArray(user?.websiteInfo) &&
                                      user?.websiteInfo?.length > 0
                                        ? user?.websiteInfo?.filter(
                                            (info) =>
                                              info.code ===
                                              syncingStep?.selectedWebsite?.code
                                          )[0]
                                        : {};
                                    return (
                                      <>
                                        <div className="bg-white rounded-md border border-slate-200 py-4 px-4 flex  gap-2 w-full">
                                          {/* {!alreadySyncingData?.code &&
                                      !alreadySyncingData?.userId && ( */}
                                          <div className="flex justify-start items-start py-2 flex-shrink-0">
                                            <div
                                              className="flex items-center justify-between"
                                              key={index}
                                            >
                                              {/* <input
                                               type="checkbox"
                                               id={`${user?._id}`}
                                               checked={selectedMember.some(
                                                 (member) =>
                                                   member?._id === user?._id
                                               )}
                                               onChange={() =>
                                                 handleSelectedMember(user)
                                               }
                                               className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-orange-500 checked:hover:bg-orange-500 checked:bg-orange-500 "
                                             /> */}
                                              <Checkbox
                                                checked={selectedMember.some(
                                                  (member) =>
                                                    member?._id === user?._id
                                                )}
                                                checkboxClass={
                                                  "!border-orange-500"
                                                }
                                                onChange={() =>
                                                  handleSelectedMember(user)
                                                }
                                              />
                                            </div>
                                          </div>
                                          {/* )} */}

                                          <Indentification
                                            initial={initialsValue(
                                              user?.firstName +
                                                " " +
                                                user?.lastName
                                            )}
                                            image={user?.image?.url}
                                            alt={user?.firstName}
                                            fill={true}
                                            className={""}
                                            size={"lg"}
                                            initialClass={" !text-sm"}
                                          />
                                          <div className="space-y-px flex-shrink">
                                            <h3 className="text-sm font-semibold text-orange-500 capitalize !leading-none">
                                              <span>
                                                {user?.firstName +
                                                  " " +
                                                  user?.lastName || "N/A"}
                                              </span>
                                              <small className="text-slate-600 capitalize text-xs font-medium block">
                                                {/* {user?.roleDetails &&
                                          Array.isArray(
                                            user?.roleDetails
                                          ) &&
                                          user?.roleDetails.length > 0
                                            ? user?.roleDetails
                                                .map((role) => role?.name)
                                                .join(", ")
                                            : "N/A"} */}
                                                {user?.roleDetails &&
                                                Array.isArray(
                                                  user?.roleDetails
                                                ) &&
                                                user?.roleDetails?.find(
                                                  (item) =>
                                                    item?.code ===
                                                    "COMPANY-ADMINISTRATOR"
                                                )
                                                  ? "Administrator"
                                                  : "Employee"}
                                              </small>
                                            </h3>
                                            <div className="text-xs text-gray-500 flex items-center gap-2 break-all">
                                              <span>
                                                {user?.email || "N/A"}
                                              </span>
                                            </div>
                                          </div>
                                          {user?.syncingStatus ===
                                            "processing" && (
                                            <div className="flex flex-col items-center ml-auto flex-shrink-0">
                                              <Loader className={"scale-50"} />
                                              <SyncingLoader
                                                className={"!text-xs"}
                                              />
                                            </div>
                                          )}
                                          {user?.syncingStatus ===
                                            "success" && (
                                            <div className="flex flex-col items-center ml-auto flex-shrink-0">
                                              <div className="w-8 h-8">
                                                <img
                                                  src={syncSuccess}
                                                  alt="syncSuccess"
                                                />
                                              </div>
                                              <div className="flex text-center justify-center items-center text-xs font-semibold text-green-600">
                                                {"Sync Finished"}
                                              </div>
                                            </div>
                                          )}
                                          {user?.syncingStatus === "failed" && (
                                            <div className="flex flex-col items-center ml-auto flex-shrink-0">
                                              <div className="w-8 h-8">
                                                <img
                                                  src={syncFailed}
                                                  alt="syncFailed"
                                                />
                                              </div>
                                              <div className="flex text-center justify-center items-center text-xs font-semibold text-rose-600">
                                                {"Sync Failed"}
                                              </div>
                                            </div>
                                          )}

                                          {!user?.syncingStatus &&
                                            alreadySyncingData?.code &&
                                            alreadySyncingData?.userId && (
                                              <div className="flex flex-col items-end ml-auto flex-shrink-0">
                                                <div className="w-8 h-8">
                                                  <i className="far fa-sync"></i>
                                                </div>
                                                <div className="flex text-right flex-col justify-center items-end text-[11px] font-semibold text-slate-400 !leading-none">
                                                  Last Sync
                                                  <span className="text-slate-500 font-bold">
                                                    {moment(
                                                      alreadySyncingData?.updatedAt
                                                    ).format("ll")}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="flex-shrink-0 mt-auto">
                              <Pagination
                                paginationClasses={"mt-5 justify-end"}
                                onPageChange={(val) => {
                                  setOffset(limit * val?.selected);
                                  setCurrentPage(val?.selected);
                                  setSelectedMember([]);
                                }}
                                pageCount={pageCount}
                                currentPage={currentPage}
                              />
                            </div>
                          </>
                        )}
                        {loading && <SyncListSkeleton type={"employee"} />}
                      </>
                    )}
                    {tabType === "positions" && (
                      <PositionList
                        syncingStep={syncingStep}
                        syncFailed={syncFailed}
                        syncSuccess={syncSuccess}
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                        arePositionAllSelected={arePositionAllSelected}
                        setArePositionAllSelected={setArePositionAllSelected}
                        syncDataModalOpen={syncDataModalOpen}
                      />
                    )}
                    {/* {tabType === "department" && (
                <DepartmentList
                  syncingStep={syncingStep}
                  syncFailed={syncFailed}
                  syncSuccess={syncSuccess}
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                  areDepartmentAllSelected={areDepartmentAllSelected}
                  setAreDepartmentAllSelected={setAreDepartmentAllSelected}
                  syncDataModalOpen={syncDataModalOpen}
                  userData={userData}
                />
              )} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SyncDataPopup;
