import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import Tree from "react-d3-tree";
import NodeEditPanel from "../../../components/shared/NodeEditPannel";
import { dfs, verticalStepPathFunc } from "../../../components/constant/Dfs";
import {
  chartAdd,
  chartEdit,
  chartMidLevelAdd,
  deleteChart,
  getChart,
  getshareLink,
  unAssignUser,
} from "../../../services/frontService/ChartService";
import { toast } from "react-toastify";
import NodeDelete from "../../../components/modals/front/NodeDelete";
import AssignModal from "../../../components/common/DeleteModal";
import { useDisableZoom } from "../../../helpers";
import RenderRectSvgNode from "../../../components/constant/SvgNode";
import { useDebounce } from "../../../helpers/useDebounce";
import { getEmployeeList } from "../../../services/frontService/EmployeeService";
import SearchWithDropdown from "../../../components/form/SearchWithDropdown";
import Button from "../../../components/form/Button";
import CsvUploadChart from "../../../components/modals/front/CsvUploadChart";
import useWindowDimensions from "./../../../helpers/useWindowDimensions";
import { Tooltip } from "react-tooltip";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { useSelector } from "react-redux";
import ExportDropdown from "../../../layouts/shared/ExportDropdown";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CopyUrl from "../../../components/modals/front/CopyUrl";

export default function Chart() {
  useDisableZoom(true);
  const { width, height } = useWindowDimensions();
  const profile = useSelector((state) => state?.profile?.data);
  const userRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-MANAGER"
  );
  const treeRef = useRef(null);
  const treeContainerRef = useRef(null);
  const [tree, setTree] = useState({});
  const [keyword, setKeyword] = useState("");
  const debKeyword = useDebounce(keyword, 300);
  const [isLoading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState("single");
  const [empDetails, setEmpDetails] = useState({ loading: false, data: {} });
  const [nodePositions, setNodePositions] = useState({});
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0.7);
  const [isYOverflow, setIsYOverflow] = useState(false);
  const [isXOverflow, setIsXOverflow] = useState(false);
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [selectUser, setSelectUser] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [importModal, setImportModal] = useState({ isOpen: false });
  const [svgHeight, setSvgHeight] = useState("");
  const [svgWidth, setSvgWidth] = useState("");
  const [isSidepanelOpen, setSidePanelOpen] = useState({
    isOpen: false,
  });
  const [copyUrlModal, setCopyUrlModal] = useState({ isOpen: false, data: {} });
  const [assignModal, setAssignModal] = useState({
    isOpen: false,
  });
  const [isDeleteModal, setDeleteModal] = useState({
    isOpen: false,
    data: {},
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUnassigned, setIsUnassigned] = useState(false);
  const [partialTree, setPartialTree] = useState(false);
  const loadChart = useCallback(() => {
    setLoading(true);
    getChart().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setTree(res?.docs?.length > 0 && res?.docs[0]);
        setPartialTree(false);
      }
    });
  }, []);
  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: debKeyword, limit: 40, chart: true })
      .then((res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      })
      .catch(() => {
        setEmpList({ loading: false, data: [] });
      });
  }, [debKeyword]);

  useEffect(() => {
    setTranslate({ x: (width - 240) / 2, y: 140 });
  }, [width, height]);

  useEffect(() => loadempList(), [loadempList]);

  useEffect(() => loadChart(), [loadChart]);

  useEffect(() => {
    if (
      isSidepanelOpen?.isOpen ||
      assignModal?.isOpen ||
      isDeleteModal?.isOpen
    ) {
      findUnassigntrueNodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidepanelOpen?.isOpen, assignModal?.isOpen, isDeleteModal?.isOpen]);

  const handleAddSibling = (nodeDatum, direction) => {
    const newSibling = {
      name: "New Sibling",
      attributes: {
        id: v4(),
      },
      isRoot: true,
      theme: "teal",
      children: [],
    };

    const newTree = dfs(
      nodeDatum?.attributes?.id,
      tree,
      newSibling,
      "addSibling",
      direction
    );

    if (newTree) {
      setTree(newTree);
    }
  };
  const addNode = (nodeDatum) => {
    setLoading(true);
    chartAdd({
      parentId: nodeDatum?._id,
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success("Chart added successfully");
        const newNode = { ...res?.data, children: [] };
        const newTree = dfs({
          id: nodeDatum?._id,
          tree,
          node: newNode,
          action: "add",
        });
        setSidePanelOpen((pre) => ({
          ...pre,
          isOpen: true,
          _id: res?.data?._id,
        }));
        setSelectUser(null);
        setSelectedPosition(null);
        if (newTree) {
          setTree(newTree);
        }
      } else {
        toast.error(
          userRole?.code === "COMPANY-MANAGER"
            ? "Only administrators have permission to add nodes to the org chart."
            : "Something went wrong!"
        );
      }
    });
  };

  const addAboveNode = (nodeDatum) => {
    setLoading(true);
    chartMidLevelAdd({
      referenceId: nodeDatum?._id,
      direction: "up",
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success("Chart added successfully");
        loadChart();
        setSidePanelOpen((pre) => ({
          ...pre,
          isOpen: true,
          _id: res?.data?._id,
        }));
        setSelectUser(null);
        setSelectedPosition(null);
      } else {
        toast.error(
          userRole?.code === "COMPANY-MANAGER"
            ? "Only administrators have permission to add nodes to the org chart."
            : "Something went wrong!"
        );
      }
    });
  };

  const deleteNode = () => {
    const nodeDatum = isDeleteModal?.data;
    const nodeId = nodeDatum?._id;
    if (!nodeId) {
      toast.error("Node ID not found");
      return;
    }
    setIsDeleting(true);
    deleteChart({
      id: nodeId,
      payload: { deletionStatus: deleteStatus },
    }).then((res) => {
      setIsDeleting(false);
      if (res?.status === 200) {
        toast.success("Chart deleted successfully");
        setSidePanelOpen((pre) => ({ ...pre, isOpen: false }));
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        loadChart();
        // const newTree = dfs({
        //   id: nodeId,
        //   tree,
        //   node: nodeDatum,
        //   action: "delete",
        //   isMultiple: deleteStatus === "multiple" ? true : false,
        // });

        // if (newTree) {
        //   setTree(newTree);
        // }
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
      }
    });
  };

  const editNode = (nodeDatum, mode = "edit") => {
    return chartEdit({
      id: isSidepanelOpen?._id,
      payload: nodeDatum,
    }).then((res) => {
      if (res?.status === 200) {
        toast.success("Chart edited successfully");
        loadChart();
        const updatedNode = { ...res?.data };
        const newTree = dfs({
          id: isSidepanelOpen?._id,
          tree,
          node: updatedNode,
          action: mode === "replace" ? "replace" : "edit",
        });
        if (newTree) {
          setTree(newTree);
        }
        return res;
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
        setSidePanelOpen((pre) => ({ isOpen: false }));
        return res;
      }
    });
  };
  const usAssignNode = () => {
    setAssignModal((pre) => ({ ...pre, loading: true }));
    unAssignUser({
      id: assignModal?._id,
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success("Unassigned successfully");
        setSidePanelOpen((pre) => ({ ...pre, isOpen: false }));
        setEmpDetails((pre) => ({ ...pre, data: {} }));
        setAssignModal((pre) => ({ ...pre, loading: false, isOpen: false }));
        setSelectUser(null);
        setSelectedPosition(null);
        loadChart();
        // const newTree = dfs({
        //   tree,
        //   id: assignModal?._id,
        //   action: "unassign",
        // });
        // if (newTree) {
        //   setTree(newTree);
        // }
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
      }
    });
  };

  const onSearch = (chart) => {
    let targetNode = null;

    const findAndUpdateNode = (node) => {
      if (node?.userDetails?._id) {
        if (node?.userDetails?._id === chart?._id) {
          node.isSearched = true;
          targetNode = node;
        } else {
          delete node.isSearched;
        }
      }

      if (node?.children && node?.children?.length > 0) {
        node.children.forEach(findAndUpdateNode);
      }
    };
    const newTree = JSON.parse(JSON.stringify(tree));

    findAndUpdateNode(newTree);

    setTree(newTree);

    if (targetNode && nodePositions[targetNode?._id]) {
      const { x, y } = nodePositions[targetNode?._id];

      const nodeWidth = 100;
      const nodeHeight = 100;
      const zoomFactor = 1.5;

      const centerX = (width - nodeWidth) / 2;
      const centerY = (height - nodeHeight) / 2;

      setZoom(zoomFactor);

      setTranslate({
        x: centerX - x * zoomFactor,
        y: centerY - y * zoomFactor,
      });
    } else {
      setTranslate({ x: (width - 240) / 2, y: 140 });
      setZoom(0.7);
    }
  };
  const adjustTranslateForZoom = (newZoom) => {
    const scaleFactor = newZoom / zoom;

    setTranslate((prevTranslate) => {
      const centerX = (width - 240) / 2;
      const centerY = 140;

      return {
        x: prevTranslate.x * scaleFactor + centerX * (1 - scaleFactor),
        y: prevTranslate.y * scaleFactor + centerY * (1 - scaleFactor),
      };
    });
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.min(prevZoom + 0.1, 5);
      adjustTranslateForZoom(newZoom); // Adjust translate for zoom-in
      return newZoom;
    });
  };
  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.1, 0.1);
      adjustTranslateForZoom(newZoom);
      return newZoom;
    });
  };
  const handleReset = () => {
    setZoom(0.7);
    setTranslate({ x: (width - 240) / 2, y: 140 });
  };
  const markUnassignedNodes = () => {
    setSidePanelOpen({ isOpen: false });

    const checkForUnassigned = (node) => {
      if (node.unassign) return true;
      if (node?.children && node?.children.length > 0) {
        return node.children.some(checkForUnassigned);
      }
      return false;
    };

    const markNode = (node) => {
      if (node?.userDetails) {
        delete node.unassign;
      } else {
        node.unassign = true;
      }

      if (node?.children && node?.children.length > 0) {
        node.children.forEach(markNode);
      }
    };

    const newTree = JSON.parse(JSON.stringify(tree));

    markNode(newTree);

    const hasUnassignedNodes = checkForUnassigned(newTree);

    if (!hasUnassignedNodes) {
      toast?.info("There are no unassigned user !");
    } else {
      setTree(newTree);
    }
  };

  const findUnassigntrueNodes = () => {
    const marktrueNode = (node) => {
      if (node.unassign === true) {
        node.unassign = false;
      }
      if (node?.children && node?.children.length > 0) {
        node.children.forEach(marktrueNode);
      }
    };
    setIsUnassigned(false);
    const newTree = JSON.parse(JSON.stringify(tree));
    marktrueNode(newTree);
    setTree(newTree);
  };

  const handleToggleChange = (newState) => {
    setIsUnassigned(newState);
    if (newState) {
      markUnassignedNodes();
    } else {
      findUnassigntrueNodes();
    }
  };

  function areImagesLoaded(svgElement) {
    const images = svgElement.querySelectorAll("image");
    let loadedCount = 0;

    return new Promise((resolve, reject) => {
      images.forEach((img, index) => {
        const imgUrl = img.getAttribute("href");
        const image = new Image();
        image.src = imgUrl;

        image.onload = function () {
          loadedCount++;
          if (loadedCount === images.length) {
            resolve(true);
          }
        };

        image.onerror = function () {
          reject("Error loading image at index: " + index);
        };
      });
    });
  }

  function convertImageToBase64(imageUrl, callback) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL("image/png");
      callback(dataUrl);
    };
    img.src = imageUrl;
  }

  function embedBase64ImagesInSVG(svgElement) {
    const images = svgElement.querySelectorAll("image");

    images.forEach((image) => {
      const imgUrl = image.getAttribute("href");
      convertImageToBase64(imgUrl, (base64Data) => {
        image.setAttribute("href", base64Data);
      });
    });
  }
  const charttopdf = () => {
    const svgElement = document.getElementById("chart-table");
    setIsProcessing(true);

    const originalZoom = zoom;
    const originalTranslate = translate;

    let calculatedZoom = 0.6;

    if (isYOverflow) {
      const overflowAmountY = Math.abs(svgHeight - height);
      calculatedZoom = Math.max(0.4, 0.6 - overflowAmountY * 0.1);
    }

    if (isXOverflow) {
      const overflowAmountX = Math.abs(svgWidth - (width - 240 / 2));
      calculatedZoom = Math.max(calculatedZoom, 0.6 - overflowAmountX * 0.1);
    }
    setZoom(calculatedZoom);

    setTranslate({ x: (width - 240) / 2, y: 120 });

    setTimeout(async () => {
      embedBase64ImagesInSVG(svgElement);

      try {
        await areImagesLoaded(svgElement);

        html2canvas(svgElement, {
          useCORS: true,
          logging: false,
          scale: 2,
          x: 0,
          y: 0,
        })
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");

            const pdf = new jsPDF("l", "mm", "a4");

            const imgWidth = 350;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const xPosition = (pdf.internal.pageSize.width - imgWidth) / 2;
            const yPosition = 5;

            pdf.addImage(
              imgData,
              "PNG",
              xPosition,
              yPosition,
              imgWidth,
              imgHeight
            );
            pdf.save("org_chart.pdf");
          })

          .catch((error) => {
            console.error("Error generating table image for PDF:", error);
          })
          .finally(() => {
            setZoom(originalZoom);
            setTranslate(originalTranslate);
            setIsProcessing(false);
          });
      } catch (error) {
        console.error("Error while waiting for images to load:", error);
        setZoom(originalZoom);
        setTranslate(originalTranslate);
        setIsProcessing(false);
      }
    }, 1000);
  };
  const resetChart = () => {
    loadChart();
  };

  const exportPartialTree = () => {
    if (isSidepanelOpen?._id === empDetails?.data?._id) {
      setLoading(true);
      getChart({ specificCardId: isSidepanelOpen?._id }).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setTree(res?.docs?.length > 0 && res?.docs[0]);
          setPartialTree(true);
        }
      });
    }
  };

  const shareChart = () => {
    setCopyUrlModal({ isOpen: true });
    getshareLink().then((res) => {
      if (res?.status === 200) {
        setCopyUrlModal((pre) => ({ ...pre, data: res?.data }));
      }
    });
  };

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();

      let newZoom;
      if (event.deltaY < 0) {
        newZoom = Math.min(zoom * 1.1, 5); // Zoom in
      } else {
        newZoom = Math.max(zoom * 0.9, 0.02); // Zoom out
      }

      adjustTranslateForZoom(newZoom); // Adjust translate for zooming with wheel

      setZoom(newZoom); // Set new zoom value
    };

    if (treeContainerRef.current) {
      treeContainerRef.current.addEventListener("wheel", handleWheel, {
        passive: false,
      });
    }

    // Cleanup event listener on unmount
    return () => {
      if (treeContainerRef.current) {
        treeContainerRef.current.removeEventListener("wheel", handleWheel);
      }
    };
  }, [zoom]);

  useEffect(() => {
    let tree = document.querySelector("#chart-table svg > g");
    const bbox = tree.getBBox();
    const heightest = bbox.height * 0.7;
    const widthest = bbox.width * 0.7;
    const newHeight = heightest - translate?.y;
    const newWidth = widthest;

    setSvgWidth(newWidth);
    setSvgHeight(newHeight);
    if (newHeight > height - translate?.y) {
      setIsYOverflow(true);
    } else {
      setIsYOverflow(false);
    }

    if (svgWidth > width - 240 / 2) {
      setIsXOverflow(true);
    } else {
      setIsXOverflow(false);
    }
  }, [tree]);

  return (
    <div className="flex flex-row  w-full h-full relative">
      <div className="absolute top-0 left-0 right-0 z-20 p-4 flex items-center justify-between">
        <div className="flex items-center">
          <SearchWithDropdown
            placeholder={`Search org chart`}
            loading={empList?.loading}
            keyword={keyword}
            setKeyword={setKeyword}
            onChange={onSearch}
            divClasses={"!rounded-xl"}
            dropdownClass={"!rounded-xl"}
            inputClasses={"!pl-0"}
            dropdownData={empList?.data?.map((item) => ({
              name: item?.firstName + " " + item?.lastName,
              subItem: item?.positionDetails?.title || "No position",
              image: item?.image?.url,
              _id: item?._id,
              type: "user",
            }))}
          />
          <div className="ml-2" data-tooltip-id="toggle-option">
            <ToggleSwitch
              onToggle={handleToggleChange}
              isUnassigned={isUnassigned}
            />
            <Tooltip
              id="toggle-option"
              place="bottom"
              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">
                  {!isUnassigned
                    ? "Click to find unassigned users."
                    : "Click to show all users."}
                </div>
              )}
            />
          </div>
          <div className="ml-2 text-orange-500">
            {isProcessing && "Downloading Pdf..."}
          </div>
        </div>
        <div className="flex items-center ml-auto">
          {partialTree && (
            <>
              <div data-tooltip-id="download-pdf">
                <Button
                  buttonFunction={charttopdf}
                  buttonLabel={"Download"}
                  buttonClasses={
                    "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
                  }
                  buttonIcon={"fa-regular fa-download"}
                  buttonIconPosition={"left"}
                />
                <Tooltip
                  id="download-pdf"
                  place="bottom"
                  className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                  render={() => (
                    <div className="text-white">Click to download PDF.</div>
                  )}
                />
              </div>
              <div data-tooltip-id="Reset-chart">
                <Button
                  buttonFunction={resetChart}
                  buttonLabel={"Reset Chart"}
                  buttonClasses={
                    "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
                  }
                  buttonIcon={"fa-regular fa-undo"}
                  buttonIconPosition={"left"}
                />
                <Tooltip
                  id="Reset-chart"
                  place="bottom"
                  className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                  render={() => (
                    <div className="text-white">Click to reset chart.</div>
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex items-center ml-auto">
          {!partialTree && (
            <div className="relative">
              <ExportDropdown
                data={{
                  icon: "fa-regular fa-download",
                  name: "Export",
                }}
                _id="1"
                xPlacement="bottomLeft"
                dropdownContainer="!w-36"
                downloadTreeAsPDF={charttopdf}
              />
            </div>
          )}

          {userRole?.code !== "COMPANY-MANAGER" && (
            <div data-tooltip-id="import-csv">
              <Button
                buttonFunction={() => setImportModal({ isOpen: true })}
                buttonLabel={"Import"}
                buttonClasses={
                  "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
                }
                buttonIcon={"fa-regular fa-file-import"}
                buttonIconPosition={"left"}
              />
              <Tooltip
                id="import-csv"
                place="bottom"
                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                render={() => (
                  <div className="text-white">Click to upload CSV & XLSX.</div>
                )}
              />
            </div>
          )}
          <div data-tooltip-id="zoom-in">
            <Button
              buttonFunction={handleZoomIn}
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonClasses={
                "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
              }
            />
            <Tooltip
              id="zoom-in"
              place="bottom"
              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
              render={() => (
                <div className="text-white">Click to zoom in the chart.</div>
              )}
            />
          </div>
          <div data-tooltip-id="reset">
            <Button
              buttonFunction={handleReset}
              buttonIcon={"fa-sharp fa-solid fa-rotate-left"}
              buttonIconPosition={"left"}
              buttonClasses={
                "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
              }
            />
            <Tooltip
              id="reset"
              place="bottom"
              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
              render={() => (
                <div className="text-white">Click to reset the chart.</div>
              )}
            />
          </div>
          <div data-tooltip-id="zoom-out">
            <Button
              buttonFunction={handleZoomOut}
              buttonIcon={"fa-regular fa-minus"}
              buttonIconPosition={"left"}
              buttonClasses={
                "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
              }
            />
            <Tooltip
              id="zoom-out"
              place="bottom"
              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
              render={() => (
                <div className="text-white">Click to zoom out the chart.</div>
              )}
            />
          </div>
          <div data-tooltip-id="share">
            <Button
              buttonFunction={shareChart}
              buttonIcon={"fa-regular fa-share"}
              buttonIconPosition={"left"}
              buttonClasses={
                "ml-2 !bg-white !border-orange-500 border !text-orange-500 "
              }
            />
            <Tooltip
              id="share"
              place="bottom"
              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
              render={() => (
                <div className="text-white">Click to share chart.</div>
              )}
            />
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 z-10">
          <div className="container">
            <div className="level-1 rectangle animate-pulse"></div>
            <ol className="level-2-wrapper">
              <li>
                <div className="level-2 rectangle animate-pulse"></div>
              </li>
              <li>
                <div className="level-2 rectangle animate-pulse"></div>
              </li>
            </ol>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          if (isSidepanelOpen?.isOpen) {
            setSidePanelOpen({ isOpen: false });
          }
          findUnassigntrueNodes();
          if (partialTree && isSidepanelOpen?.isOpen) {
            loadChart();
          }
        }}
        id="chart-table"
        className={`w-full h-full`}
        ref={treeRef}
      >
        <div
          ref={treeContainerRef}
          style={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            position: "relative",
          }}
        >
          <Tree
            ref={treeRef}
            data={tree}
            zoomable={false}
            orientation="vertical"
            pathFunc={verticalStepPathFunc}
            zoom={zoom}
            scaleExtent={{ min: 0, max: 5 }}
            translate={translate}
            nodeSize={{ x: 300, y: 180 }}
            // enableLegacyTransitions={true}
            transitionDuration={1000}
            centeringTransitionDuration={1000}
            separation={{ siblings: 1, nonSiblings: 1.2 }}
            onUpdate={(updatedTreeData) => {
              const newTranslate = updatedTreeData?.translate;
              if (
                newTranslate &&
                (newTranslate.x !== translate.x ||
                  newTranslate.y !== translate.y)
              ) {
                setTranslate(newTranslate); 
              }
            }}
            renderCustomNodeElement={(nodeInfo) => (
              <RenderRectSvgNode
                nodeInfo={nodeInfo}
                addNode={addNode}
                addAboveNode={addAboveNode}
                setAssignModal={setAssignModal}
                setDeleteModal={setDeleteModal}
                isSidepanelOpen={isSidepanelOpen}
                setSidePanelOpen={setSidePanelOpen}
                handleAddSibling={handleAddSibling}
                setNodePositions={setNodePositions}
              />
            )}
          />
        </div>
      </div>
      <NodeEditPanel
        editNode={editNode}
        empDetails={empDetails}
        setEmpDetails={setEmpDetails}
        isSidepanelOpen={isSidepanelOpen}
        setSidePanelOpen={setSidePanelOpen}
        exportPartialTree={exportPartialTree}
        setSelectUser={setSelectUser}
        selectUser={selectUser}
        setSelectedPosition={setSelectedPosition}
        selectedPosition={selectedPosition}
      />
      <NodeDelete
        open={isDeleteModal?.isOpen}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        loading={isDeleting}
        deleteHandler={deleteNode}
        deleteStatus={deleteStatus}
        setDeleteStatus={setDeleteStatus}
        hasChild={isDeleteModal?.data?.children?.length > 0 ? true : false}
      />
      <AssignModal
        open={assignModal?.isOpen}
        loading={assignModal?.loading}
        onClose={() => setAssignModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={usAssignNode}
        title={
          <>
            Confirm removal of{" "}
            <strong>
              {/* {assignModal?.name?.substring(0, 10) +
                (`${assignModal?.name}`.length > 10 ? "..." : "")} */}
              {assignModal?.name}
            </strong>
          </>
        }
        customMessage={
          <>
            Are you sure you want to remove{" "}
            <strong>
              {assignModal?.name?.substring(0, 25) +
                (`${assignModal?.name}`.length > 25 ? "..." : "")}
            </strong>{" "}
            from the organization chart?
          </>
        }
        deleteButtonIcon={"fa-regular fa-user-xmark"}
        deleteButtonLabel={"Remove"}
      />
      <CsvUploadChart
        importModal={importModal}
        setImportModal={setImportModal}
        loadChart={loadChart}
      />
      <CopyUrl setCopyUrlModal={setCopyUrlModal} copyUrlModal={copyUrlModal} />
    </div>
  );
}
