import { apiRequest } from "../util/apiUtils";

export const getUserList = async (params) => {
  try {
    const res = await apiRequest("get", "/user/users-list-all", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getPositionList = async (params) => {
    try {
      const res = await apiRequest("get", "/position", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  export const getRoleList = async (params) => {
    try {
      const res = await apiRequest("get", "/roles", {
        queries: params,
      });
      return res?.data;
    } catch (error) {
      return error;
    }
  };
  

export const syncGenarateToken = async (params) => {
  try {
    const res = await apiRequest("post", "generate/token", {
      body: params,
    });

    return res?.data;
  } catch (error) {
    return error;
  }
};
export const syncRecord = async (params) => {
  try {
    const res = await apiRequest("post", "/get-sync-record", {
      body: params,
    });

    return res?.data;
  } catch (error) {
    return error;
  }
};