import PositionManage from "../../../modals/front/PositionManage";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../form/Button";
import DeleteModal from "../../../common/DeleteModal";
import {
  deletePosition,
  getPositionList,
} from "../../../../services/frontService/PositionService";
import { toast } from "react-toastify";
import PositionLoader from "../../../loaders/PositionLoader";
import Search from "../../../form/Search";
import NoDataFound from "../../../common/NodataFound";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import Pagination from "../../../common/Pagination";
import Directory from "../../../../pages/front/directory/Directory";
import { Tooltip } from "react-tooltip";
import { setTitle } from "../../../../helpers/MetaTag";
import useForm from "../../../../hooks/useForm";
import SelectDropdownCheckbox from "../../../form/SelectDropdownCheckbox";
import { companyCount } from "../../../../services/frontService/CompanyService";
import { getCount } from "../../../../redux/slice/dashboardSlice";

export default function PositionSection() {
  setTitle("ORG Chart | Positions");
  const dispatch = useDispatch();

  const validation = {
    sortName: { required: true, message: "Please enter sort name!" },
  };

  const { values, handleChange } = useForm({}, validation);
  const [isPositionModal, setPositionModal] = useState({
    data: {},
    isOpen: false,
  });
  const [keyword, setKeyword] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [filters, setFilters] = useState({
    limit: 9,
    offset: 0,
    currentPage: 0,
  });

  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    const payload = {
      keyword,
      ...filters,
      sortQuery: "-createdAt",
      countFilter: 0,
    };

    getPositionList(payload).then((res) => {
      if (res && res.status === 200) {
        setList({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [keyword, filters, values?.sortName]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadCount = useCallback(() => {
    companyCount().then((res) => {
      if (res?.status === 200) {
        dispatch(getCount(res?.data));
      }
    });
  }, []);
  useEffect(() => {
    loadCount();
  }, [loadCount]);
  const positionList = list?.data?.map((pos) => ({
    _id: pos?._id,
    empCount: pos?.positionEmpCount,
    name: pos?.title,
    desc: pos?.description,
    positionEmpList: pos?.positionEmpList,
  }));

  // Delete
  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deletePosition({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter(
            (item) => !deleteModal?.ids.includes(item._id)
          );

          const totalItem =
            pre?.totalItem -
            (updatedData?.length === 0 && filters?.currentPage > 0 ? 1 : 0);

          return {
            ...pre,
            data: updatedData,
            totalItem,
          };
        });
        dispatch(
          setProfile((pre) => ({
            ...pre,
            positionCountEmpty: pre?.positionCountEmpty - 1,
          }))
        );
        loadCount();
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        setPositionModal((pre) => ({ ...pre, isOpen: false }));
        loadList();
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  const borderColors = [
    "border-l-blue-500",
    "border-l-green-500",
    "border-l-purple-500",
    "border-l-red-500",
    "border-l-yellow-500",
    "border-l-indigo-500",
  ];
  const convertToCsv = (data) => {
    const header = Object.keys(data[0])
      .map((header) => header.toUpperCase())
      .join(",");

    const rows = data.map((row) => {
      return Object.values(row)
        .map((value) => {
          if (typeof value === "string" && value.includes(",")) {
            return `"${value}"`;
          }
          return value;
        })
        .join(",");
    });

    return [header, ...rows].join("\n");
  };
  const downloadFile = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
  };
  const downloadCsv = () => {
    getPositionList({
      limit: 100000,
      offset: 0,
      currentPage: 0,
      sortQuery: "-createdAt",
      countFilter: 0,
    }).then((res) => {
      if (res?.status === 200) {
        const filteredData = res?.docs?.map((position) => ({
          Title: position.title,
          Description: position.description,
        }));

        const csvData = convertToCsv(filteredData);

        downloadFile(csvData, "vacant_positions.csv");
      }
    });
  };
  return (
    <Directory>
      <div className="relative flex flex-col">
        <div className="w-full flex space-y-2 lg:items-center flex-col lg:flex-row justify-between mb-4">
          <div className="text-lg text-gray-800">
            Positions{" "}
            <span className="text-[#f76310]">({list?.totalItem})</span>
          </div>
          <div className="flex gap-2 items-center justify-between">
            <Search
              placeholder={"Search positions"}
              search={(val) => setKeyword(val)}
              divClasses={"!rounded-md !h-10 border border-slate-300 "}
            />
            <div className="flex gap-2 items-center">
              <div data-tooltip-id="add-position">
                <Button
                  buttonLabel={"New"}
                  buttonIcon={"fa-regular fa-plus"}
                  buttonClasses={"!w-20"}
                  buttonIconPosition={"left"}
                  buttonFunction={() => {
                    setPositionModal({ data: {}, isOpen: true });
                  }}
                />
                <Tooltip
                  id="add-position"
                  place="left"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to add position.</div>
                  )}
                />
              </div>
              <div data-tooltip-id="download-csv">
                <Button
                  buttonLabel={"Export CSV"}
                  buttonIcon={"fa-solid fa-file-csv"}
                  buttonIconPosition={"left"}
                  buttonLabelClasses={"!font-bold !text-xs md:!text-sm"}
                  buttonClasses={
                    "!text-[10px] uppercase !w-32 !pl-3 md:!h-10 font-bold"
                  }
                  buttonFunction={() => downloadCsv()}
                  fontFamily={""}
                />
                <Tooltip
                  id="download-csv"
                  place="top"
                  className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                  render={() => (
                    <div className="text-white">
                      <div>Click to download CSV.</div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
          {list?.loading ? (
            <PositionLoader count={9} />
          ) : (
            <>
              {list?.data &&
              Array.isArray(list?.data) &&
              list.data.length === 0 ? (
                <div className="col-span-4">
                  <NoDataFound title="No positions found" />
                </div>
              ) : (
                positionList?.map((item, index) => (
                  <div
                    key={item._id}
                    className={`cursor-pointer p-4 border rounded-lg shadow-md hover:shadow-md transition-shadow duration-300 bg-gray-50 
            ${borderColors[index % borderColors.length]} border-l-4`}
                  >
                    <div className="flex flex-col md:flex-row items-start justify-between mb-4 space-x-2">
                      <div>
                        <h3 className="font-semibold text-xs xl:text-base 2xl:text-lg text-gray-700 uppercase">
                          {item?.name?.substring(0, 20) +
                            (item?.name?.length > 20 ? "....." : "")}
                        </h3>
                        <div
                          className="flex items-center text-gray-500 text-sm mt-1 w-fit"
                          data-tooltip-id={`position-employee-${item._id}`}
                        >
                          <i className="fa-regular fa-user mr-1" />
                          <span>{item?.empCount || 0} Employees</span>
                          <Tooltip
                            id={`position-employee-${item._id}`}
                            place="right"
                            className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                            render={() => {
                              if (item?.positionEmpList?.length === 0) {
                                return null;
                              }

                              const maxVisible = 5;
                              const employeesToShow =
                                item?.positionEmpList?.slice(0, maxVisible);
                              const hasMore =
                                item?.positionEmpList?.length > maxVisible;

                              return (
                                <div>
                                  {employeesToShow?.map((emp) => (
                                    <div key={emp._id} className="text-white">
                                      {emp?.firstName + " " + emp?.lastName}
                                    </div>
                                  ))}
                                  {hasMore && (
                                    <div className="text-blue-500 cursor-pointer flex justify-end">
                                      <span>
                                        {item?.empCount - maxVisible} more...
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex space-x-0 md:space-x-2 mt-3 md:mt-0 !ml-0 md:ml-[0.5rem] gap-1">
                        <div data-tooltip-id="delete-position">
                          <Button
                            buttonIcon={
                              "fa-fw fa-regular fa-trash-can text-xs font-light"
                            }
                            buttonIconPosition={"left"}
                            buttonClasses={
                              "w-6 h-6 !border !bg-slate-50 !border-red-600 !text-red-600 hover:!bg-red-100 !rounded-full flex items-center justify-center"
                            }
                            buttonFunction={() => {
                              setDeleteModal((pre) => ({
                                ...pre,
                                isOpen: true,
                                ids: [item?._id],
                              }));
                            }}
                          />
                          <Tooltip
                            id="delete-position"
                            place="bottom"
                            className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                            render={() => (
                              <div className="text-white">
                                Click to delete positions.
                              </div>
                            )}
                          />
                        </div>
                        <div data-tooltip-id="edit-position">
                          <Button
                            buttonIcon={
                              "fa-regular fa-pen-to-square text-sm font-medium"
                            }
                            buttonIconPosition={"left"}
                            buttonClasses={
                              "w-6 h-6 !border !bg-slate-50 !border-black !text-black hover:!bg-blue-100 !rounded-full flex items-center justify-center"
                            }
                            buttonFunction={() => {
                              setPositionModal({ data: item, isOpen: true });
                            }}
                          />
                          <Tooltip
                            id="edit-position"
                            place="bottom"
                            className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                            render={() => (
                              <div className="text-white">
                                Click to edit position.
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {item?.desc && (
                      <div className="text-gray-500 text-sm">
                        <p>
                          {item?.desc?.substring(0, 55) +
                            (item?.desc?.length > 55 ? "....." : "")}
                        </p>
                      </div>
                    )}
                  </div>
                ))
              )}
            </>
          )}
        </div>
        {list?.pageCount > 1 && (
          <div className="realtive mt-auto">
            <Pagination
              paginationClasses={"mt-4 justify-center"}
              onPageChange={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  offset: pre?.limit * val?.selected,
                  currentPage: val?.selected,
                }));
              }}
              pageCount={list?.pageCount}
              currentPage={filters?.currentPage}
            />
          </div>
        )}
        <PositionManage
          setList={setList}
          isPositionModal={isPositionModal}
          setPositionModal={setPositionModal}
          loadCount={loadCount}
        />
        <DeleteModal
          open={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onClose={() => {
            setDeleteModal((pre) => ({ ...pre, isOpen: false }));
          }}
          deleteHandler={deletefunction}
          title="Delete Position"
          subTitle="You are trying to delete a position"
        />
      </div>
    </Directory>
  );
}
