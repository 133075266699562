import React from "react";
import moment from "moment";
import Button from "../../../form/Button";
import Loader from "../../../common/Loader";

const PaymentList = ({
  isShow,
  setIsShow = () => {},
  transactions,
  setTransactions = () => {},
  processing,
  setProcessing = () => {},
}) => {
  return (
    <div>
      <div className="relative overflow-x-auto mt-4">
        <table className="w-full text-sm text-left">
          <thead className="text-xs text-gray-800 uppercase bg-blue-100">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-slate-800 font-bold text-xs whitespace-nowrap rounded-tl-md"
              >
                Price
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-slate-800 font-bold text-xs whitespace-nowrap"
              >
                Start Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-slate-800 font-bold text-xs whitespace-nowrap"
              >
                End Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-slate-800 font-bold text-xs whitespace-nowrap"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-slate-800 font-bold text-xs whitespace-nowrap"
              >
                Payment Via
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-slate-800 font-bold text-xs whitespace-nowrap rounded-tr-md"
              >
                Action
              </th>
            </tr>
          </thead>
          {transactions &&
          Array?.isArray(transactions) &&
          transactions?.length > 0 ? (
            <tbody>
              {transactions?.map((item, index) => {
                return (
                  <tr className="bg-white border-b" key={index}>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {"$" + item?.price.toFixed(2)}
                    </th>
                    <td className="px-6 py-4">
                      {item && item?.startDate
                        ? moment(new Date(item?.startDate)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A"}
                    </td>
                    <td className="px-6 py-4">
                      {item && item?.endDate
                        ? moment(new Date(item?.endDate)).format("MMM Do YYYY")
                        : "N/A"}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        {item?.status && item?.status === 1 ? (
                          <div
                            className={
                              "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600"
                            }
                          >
                            Active
                          </div>
                        ) : (
                          <div
                            className={
                              "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-amber-100 text-amber-600"
                            }
                          >
                            Expire
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        {item?.subscriptionType &&
                        item?.subscriptionType === "manual" ? (
                          <div
                            className={
                              "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-orange-100 text-orange-600"
                            }
                          >
                            Manual
                          </div>
                        ) : (
                          <div
                            className={
                              "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600"
                            }
                          >
                            Stripe
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 flex justify-end">
                      {
                        //(item?.subscription_details?.subscription_type && item?.subscription_details?.subscription_type === 'manual') &&
                        <Button
                          buttonClasses={
                            "!p-0 !flex !border !border-transparent !items-center !justify-center !text-[12px] !bg-transparent !text-slate-400 !w-4 !h-4"
                          }
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-pencil"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          buttonFunction={() =>
                            setIsShow({
                              open: true,
                              data: item,
                            })
                          }
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr className="bg-white border-b">
                <td className="px-6 py-4" colspan={6}>
                  {processing ? (
                    <Loader className="mx-auto" />
                  ) : (
                    <div className="realtive text-lg text-orange-300 font-semibold text-center">
                      No Payment Record Available
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default PaymentList;
