export const formatPhoneNumber = (phone) => {
  let formattedPhone = phone;

  if (typeof formattedPhone === "number") {
    formattedPhone = formattedPhone.toString();
  }

  if (formattedPhone.length === 8) {
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{2})/,
      "($1) $2-$3"
    );
  } else if (formattedPhone.length === 9) {
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{3})/,
      "($1) $2-$3"
    );
  } else if (formattedPhone.length === 10) {
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
  } else if (formattedPhone.length === 11) {
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{5})/,
      "($1) $2-$3"
    );
  } else if (formattedPhone.length === 12) {
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{4})(\d{2})/,
      "($1) $2-$3$4"
    );
  }

  return formattedPhone;
};
